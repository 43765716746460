<template>
  <div class="d-flex col-12">
     <label>{{ pergunta.texto_pergunta }}</label>
     <v-text-field
        class="col-10"
        v-if="pergunta.obrigatorio"
        v-model="resposta.resposta"
        :rules="regras"
        @blur="respondido"
      ></v-text-field>
     <v-text-field
        class="col-10"
        v-else
        v-model="resposta.resposta"
        @blur="respondido"
      ></v-text-field>
  </div>
</template>
<script>
import Mixin from "@/mixins/vuex_snackbar_mixin.js";

export default {
  mixins: [Mixin],
  props:{
    pergunta:Object,
  },
  beforeMount(){
    this.resposta = {
      id_pergunta:this.pergunta.id,
      resposta:""
    }
  },
  methods:{
    respondido(){
        if (this.resposta.resposta != "") {
          this.$emit("resposta", this.resposta)
        }
    }
  },
  data: () => ({
    resposta:{},
    regras: [
        value => !!value || 'Obrigatório.',
    ]
  }),
};
</script>
<style scoped>
.v-progress-circular {
  margin: 1rem;
}
</style>