<template>
  <div id="app">
    <v-app>
      <div v-if="estaLogado">
        <v-navigation-drawer class="bg-verde" v-model="drawer" app>
          <menu-principal />
        </v-navigation-drawer>
        <v-app-bar app>
          <header-pagina @retratil="drawer = !drawer" />
        </v-app-bar>
        <v-main>
          <div class="page-content">
            <div div class="card">
              <div class="card-body">
                <router-view />
              </div>
            </div>
          </div>
        </v-main>
      </div>
      <div v-else>
        <home
          v-if="$_GETTER_perfil.description === 'home'"
          @login="setPerfil($event)"
        ></home>
        <div v-else>
          <router-view />
        </div>
      </div>
      <snackbar />
    </v-app>
  </div>
</template>


<script>
// import MenuGeral from "@/components/templates/MenuGeral.vue";
import MenuPrincipal from "@/components/templates/MenuLateral.vue";
import HeaderPagina from "@/components/templates/Header.vue";

import Home from "@/views/home";
import Snackbar from "@/components/widgets/snackbar.vue";
import Mixin from "@/mixins/vuex_mixin.js";

export default {
  components: { MenuPrincipal, HeaderPagina, Home, Snackbar },
  mixins: [Mixin],
  mounted() {
    if (!this.estaLogado && this.$router.history.current.path !== "/home") {
      this.$router.replace("home");
    }
  },
  data: () => ({
    drawer: null,
  }),
  computed: {
    estaLogado() {
      return this.$_GETTERS_token.length > 0;
    },
    perfilAtual() {
      return this.$_GETTER_perfil.description;
    },
  },
  methods: {
    setPerfil(obj) {
      this.$_ACTIONS_perfil(obj);
    },
  },
};
</script>

<style>
/* App css */
@import "assets/css/bootstrap.min.css";
@import "assets/css/icons.min.css";
@import "assets/css/app.min.css";

/*Plugins css */
@import "assets/libs/bootstrap-select/bootstrap-select.min.css";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.bg-verde {
  background-color: #3e682a !important;
}

.bg-cinza {
  background-color: rgb(239, 239, 239);
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
.page-content {
  /* margin-left: 240px; */
  padding: 0 15px;
  /* margin-top: 120px; */
}
.card {
  border: none;
  box-shadow: 0 0.75rem 6rem rgb(56 65 74 / 3%);
  margin-bottom: 30px;
  position: relative;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  min-width: 0;
  background-color: #fff;
  background-clip: border-box;
  border-radius: 0.25rem;
}
</style>

