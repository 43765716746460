<template>
  <div>
    <v-card min-width="400px" style="width: 100%">
      <v-card-title>
        <span class="headline text-center">Reagendamento de consulta</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <div class="row">
            <div class="col-lg-12">
              <div v-if="detalhesAgenda">
                <div class="row">
                  <div class="col-md-6">
                    <label>Data de agendamento</label>
                    <v-text-field
                      v-model="dataAgendamento"
                      clear-icon="clear"
                      :type="'date'"
                      item-text="dataitem"
                      item-value="id"
                      :outlined="false"
                      @keyup="search_horario()"
                      @blur="search_horario()"
                      solo
                    />
                  </div>

                  <div class="col-md-6">
                    <label>Horário Disponível</label>
                    <v-select
                      v-model="horario"
                      item-text="horariotext"
                      item-value="horariovalue"
                      :items="horarios"
                      placeholder=" "
                      label="Horário disponível"
                      color="black"
                      solo
                    >
                    </v-select>
                  </div>
                </div>
              </div>
              <div v-else>
                <v-progress-circular
                  v-if="loading"
                  indeterminate
                  color="green"
                ></v-progress-circular>
                <v-alert v-if="error" type="error" dark>
                  Erro no carregamento dos dados. Tente novamente dentro de
                  alguns minutos. Se o serviço continuar sem funcionar, contate
                  o administrador do sistema.
                </v-alert>
              </div>
            </div>
          </div>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('closeRemarcar')"> Fechar </v-btn>

        <!-- BOTÂO PARA REAGENDAMENTO -->
        <v-btn
          color="error"
          text
          :loading="loadingRemarcar"
          @click="remarcarConsulta()"
          style="background-color: #ffa500; color: #fff !important"
        >
          <span v-if="!loadingRemarcar"> Remarcar </span>
          <v-progress-circular
            v-else
            indeterminate
            color="white"
          ></v-progress-circular>
        </v-btn>
      </v-card-actions>
    </v-card>

    <confirmation-dialog
      v-if="confirmDesmarcar"
      :enabled="confirmDesmarcar"
      :dialogColor="'error'"
      :title="'Desmarcar consulta'"
      :confirmationMessage="'Você tem certeza que deseja desmarcar essa consulta?'"
      :confirmationButtonMessage="'Confirmar'"
      :abortButtonMessage="'Cancelar'"
      :confirmationButtonColor="'error'"
      @abort="confirmDesmarcar = false"
      @confirm="desmarcarConsulta"
    ></confirmation-dialog>
  </div>
</template>

<script>
import ConfirmationDialog from "@/components/dialogs/confirmation";
import Mixin from "@/mixins/vuex_snackbar_mixin.js";
import AgendamentoService from "@/services/agendamento_service";
import AgendaService from "@/services/agenda_service";

export default {
  mixins: [Mixin],
  components: { ConfirmationDialog },
  props: {
    detalhesAgenda: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    paciente: null,
    loading: false,
    loadingRemarcar: false,
    error: false,
    confirmRemarcar: false,
    confirmDesmarcar: false,
    dataAgendamento: "",
    params: [],
    horarios: [],
    horario: "",
  }),
  watch: {},
  mounted() {},
  computed: {},
  methods: {
    remarcarConsulta() {
      this.confirmDesmarcar = false;
      const { idAgendamento } = this.detalhesAgenda;

      const request = {
        id_agendamento: idAgendamento,
        id_agenda: this.horario,
      };

      const agendamentoService = new AgendamentoService();
      this.loadingRemarcar = true;
      agendamentoService.reagendamento(
        {
          onSucess: (status) => (body) => {
            console.log(body.message);
            this.$_ACTIONS_showSnackbarMessage({
              message:
                body.message || this.$global.messages.internalServerError,
              color: status === 200 ? "sucess" : "error",
            });
            if (status === 200) {
              this.$emit("closeRemarcaAtualiza");
            }
          },
          onError: (error) => {
            this.$emit("remarcar", { error });
          },
          onEnd: () => {
            this.loadingRemarcar = false;
          },
        },
        request
      );
    },
    gerar_horario_atual() {
      var d = new Date(),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    search_horario() {
      if (
        !this.dataAgendamento ||
        this.dataAgendamento.length < 7 ||
        this.loading ||
        this.dataAgendamento < this.gerar_horario_atual()
      ) {
        this.horarios = [];
        return;
      }

      console.log("passou do if");

      this.params["id_tipo_procedimento"] =
        this.detalhesAgenda.idTipoProcedimento;
      this.params["data_agenda"] = this.dataAgendamento;

      const agendaService = new AgendaService();
      agendaService.getHorariosDisponiveisFromDate(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              body.data.agendas.forEach((form) => {
                let horario = [];
                horario["horariovalue"] = form.id;
                horario["horariotext"] =
                  form.hora_inicio + " - " + form.hora_final;
                this.horarios.push(horario);
              });
            }
          },
          onError: (error) => {
            console.error(error);
            this.pacientes = [];
          },
          onEnd: () => {
            this.loading = false;
          },
        },
        this.params
      );
    },
  },
};
</script>