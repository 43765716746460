import HTTPService from "@/services/http_service.js";

export default class AgendamentoService extends HTTPService {
  resource = "agendamento";

  desmarcarAgendamento(responseFunctions, idAgendamento) {
    this.request(
      responseFunctions,
      "POST",
      `${this.resource}/${idAgendamento}/desmarcar`
    );
  }

  reagendamento(responseFunctions, request) {
    this.request(responseFunctions, "POST", `reagendamento`, request);
  }

  informacaoAdicionalAgendamento(responseFunctions, idAgendamento, request) {
    this.request(
      responseFunctions,
      "POST",
      `${this.resource}/informacao/adicional/${idAgendamento}`,
      request
    );
  }

  getProcedimentoAdicionalAgendamento(responseFunctions, idAgendamento) {
    console.log(idAgendamento);
    this.request(
      responseFunctions,
      "GET",
      `${this.resource}/procedimento-adicional/${idAgendamento}`,
      {}
    );
  }
  alteraStatusAgendamento(responseFunctions, idAgendamento, status) {
    this.request(
      responseFunctions,
      "POST",
      `${this.resource}/altera/status/${status}/${idAgendamento}`,
      {}
    );
  }
}
