<template>
    <div>
      <div class="row">
        <!-- CARD CIRURGIAS -->
        <div class="col-md-6">
            <card :lista="cirurgias" :titulo="'Cirurgias'" id="consulta" :cor="'#692A66'"/>        
        </div>

        <!-- CARD CONSULTAS -->
        <div class="col-md-6">
            <card :lista="consultas" :titulo="'Consultas'" id="cirurgia" :cor="'#1186D9'"/>        
        </div>

        <!-- CARD COMPROMISSOS PESSOAIS -->
        <div class="col-md-6">
            <card :lista="compromissosPessoais" :titulo="'Compromissos pessoais'" id="compromisso-pessoal" :cor="' #bbcb3a'"/>        
        </div>

        <!-- LISTA DE PACIENTES DO DIA -->
        <div class="col-md-6">
          <card-table :lista="agenda" />
        </div>

      </div>            
    </div>    
</template>

<script>
import Card from '@/components/dashboards/dashboard_assistente/card.vue'
import CardTable from '@/components/dashboards/dashboard_assistente/card_table.vue'
import DashboardService from "@/services/dashboard_service.js";
import Mixin from "@/mixins/vuex_mixin.js";

export default {
  mixins:[Mixin],
  components: {
    Card,
    CardTable,
  },
  beforeMount(){
    this.atualizarCompromissos()
  },

  data: () => ({
    //CIRURGIAS
    cirurgias: [
    ],

    //CONSULTAS
    consultas: [
    ],

    //COMPROMISSOS PESSOAIS DO MÉDICO
    compromissosPessoais: [
    ],
    agenda:[],

  }),
   methods: {
     atualizarCompromissos () {
      this.loading = true
      const dashboardService = new DashboardService ()
      dashboardService.getDashboarMedico({
        onSucess: status => body => {
          if (status === 200) {
            this.agenda = body.data.map(agenda => (
              {
                idAgendamento: agenda.id_agendamento,
                idConsulta: agenda.id_consulta,
                emAtraso: !!agenda.atrasado,
                data: agenda.data,
                horarioInicio: agenda.hora_inicio,
                horarioFinal: agenda.hora_final,
                nomePaciente: agenda.nome,
                procedimento: agenda.procedimento,
                tipoProcedimento: agenda.tipo_procedimento,
                idTipoProcedimento: agenda.id_tipo_procedimento,
                telefone: agenda.telefone_1
              }
          ))
            this.filtrarCirurgia(body.data)
            this.filtrarConsultas(body.data)
            this.filtrarCompromissos(body.data)
          }
          
        },
        onError: error => {
          console.error(error)
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: 'error'
          })
        },
        onEnd: () => {
          this.loading = false
          
        }
      }, 1)
    },
    filtrarCirurgia(lista){
      this.cirurgias = lista.filter( item => {
        if(item.id_tipo_procedimento === this.$global.tiposProcedimentos.cirurgia){
          return item
        }
      })
    },
    filtrarConsultas(lista){
      this.consultas = lista.filter( item=>{
        if(item.id_tipo_procedimento === this.$global.tiposProcedimentos.consulta){
          return item;
        }
      })

    },
    filtrarCompromissos(lista){
      this.compromissosPessoais = lista.filter(item=>{
        if(item.id_tipo_procedimento === this.$global.tiposProcedimentos.compromissoPessoal){
          return item;
        }
      })
    },
  }
}
</script>