import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index.js'
import routes from '@/router/routes.js'
import authorizations from '@/router/authorizations'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: routes.map(route => {
        const authArray = []
        Object.keys(authorizations).forEach(profile => {
            const auth = authorizations[profile]
            if (auth[route.name]) {
                authArray.push({
                    profile: profile,
                    authorization: auth[route.name]
                })
            }
        })
        route.auth = authArray
        return route
    })
})

router.beforeEach((to, from, next) => {
    const { usuario } = store.state;
    const { perfil } = store.state;
    const usuarioEstaLogado = Object.keys(usuario).length > 0 && usuario.id
    const rotasPermitidas = ['/login', '/cadastro', '/home', '/novo-cadastro', '/esqueceu-senha']
    if (rotasPermitidas.includes(to.path) || (from.path === '/login' && to.path === '/principal')) {
        next();
        return;
    } else {
        const userHasPermission = usuarioEstaLogado && Object.keys(authorizations[perfil.id]).includes(to.name) || to.name === 'dadoscadastrais';
        if (userHasPermission) {
            next();
        } else {
            next(from.name);
        }
    }
})

export default router