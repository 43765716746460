<template>
  <div>
    <v-card min-height="100vh">
      <v-toolbar style="background-color: #3e682a; color: #fff">
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          <span class="headline">Termos</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <div>
            <v-select
                @change="atualizarProcedimentos"
                v-model="tipo"
                :items="tipos_termos"
                item-value="cod"
                item-text="descricao"
                label="Tipo de termo"
                class="col-10"
              ></v-select>
          </div>
          <div v-if="tipo != 'termo_dois'">
            <v-select
                v-model="procedimento"
                :items="procedimentos"
                item-value="descricao"
                item-text="descricao"
                label="Procedimento"
                class="col-10"
                :loading="loading"
              ></v-select>
          </div>
        </v-container>
         <div class="row">
          <div class="col-md-12 text-right d-flex justify-lg-space-between">
            <button
              class="btn btn-cancelar btn-padding font-weight-bold text-gray"
               @click="$emit('close')"
            >
              Cancelar
            </button>
            <button
              class="btn btn-app-primary btn-padding font-weight-bold"
              @click="gerarPdf()"
            >
              <span> Gerar PDF </span>
            </button>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ProcedimentoService from '@/services/procedimento_service.js'

export default {
  beforeMount(){
    this.atualizarProcedimentos()
  },

  mounted() {
    this.atualizarProcedimentos()
  },

  data: () => ({
    tipo:"",
    tipos_termos:[
      {cod:"termo_um" , descricao:"Responsabilidade expresso"},
      {cod:"termo_dois" , descricao:"Consentimento informado expresso"},
      {cod:"termo_tres" , descricao:"Consentimento livre e esclarecido"},
    ],
    procedimentos:[
      {descricao:"Cirurgia teste"}
    ],
    procedimento:""

  }),
  methods:{
    atualizarProcedimentos() {
      const procedimentoService = new ProcedimentoService()
      this.loading = true;
      this.procedimentos=[];
      procedimentoService.getList({
        onSucess:(status) => (body) => {
          if (status === 200) {
          this.procedimentos = body.data.procedimentos
          } else {
            console.log(body);
          }
        },
        onError: (error) => {
          console.log(error);
        },
        onEnd:() => {
          this.loading = false;
        }
      })
    },
    gerarPdf(){
      if (this.tipo =="") {
        console.log("Escolher um  tipo de termo")
        return
      }
      this.$emit("gerarPDF", {
        doc: "termo",
        termo_tipo: this.tipo,
        procedimento: this.procedimento,
      })
      this.tipo =""
      this.procedimento =""
      this.procedimentos =[]
    }
  }
};
</script>