<template>
  <div>
    <v-card min-width="400px" style="width: 100%">
      <v-card-title>
        <span class="headline">Consultar procedimentos por Paciente</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <div class="row">
            <div class="col-lg-9">
              <v-autocomplete
                v-model="pacienteID"
                :items="pacientes"
                :item-disabled="verificaAgendamento"
                :loading="loading"
                color="#3E682A"
                hide-no-data
                hide-details
                :search-input.sync="search"
                label="Buscar por nome"
                item-text="nome"
                item-value="paciente"
              >
                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title
                      v-html="data.item.nome"
                    ></v-list-item-title>
                    <v-list-item-subtitle
                      v-html="
                        data.item.cpf +
                          ' | ' +
                          data.item.telefone_1 +
                          ' | Agendamentos ' +
                          data.item.agendamento
                      "
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </div>

            <div class="col-lg-3">
              <v-btn
                color="green"
                style="color:#ffffff;"
                x-large
                x-small
                @click="buscarAgendamento"
                >PESQUISAR</v-btn
              >
            </div>
          </div>
          <v-divider></v-divider>
          <div class="row">
            <div class="col-lg-3">
              <v-select
                v-model="procedimento"
                :items="procedimentos"
                :loading="loading_procedimento"
                background-color="white"
                label="Procedimento"
                :item-text="descricaoProcedimento"
                item-value="id_procedimento"
                color="black"
              >
              </v-select>
            </div>
            <div class="col-lg-3">
              <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="date1"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="data1Formatada"
                    label="Data Inicial"
                    prepend-icon="mdi-calendar"
                    readonly
                    @blur="date = parseDate(dateFormatted)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon
                      v-if="date1 != ''"
                      slot="append"
                      color="grey"
                      @click="date1 = ''"
                    >
                      mdi-delete
                    </v-icon>
                  </v-text-field>
                </template>
                <v-date-picker v-model="date1" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.dialog.save(date1)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </div>

            <!-- Segundo Date Picker -->
            <div class="col-lg-3">
              <v-dialog
                ref="dialog2"
                v-model="modal2"
                :return-value.sync="date2"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="data2Formatada"
                    label="Data Final"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon
                      v-if="date2 != ''"
                      slot="append"
                      color="grey"
                      @click="date2 = ''"
                    >
                      mdi-delete
                    </v-icon>
                  </v-text-field>
                </template>
                <v-date-picker v-model="date2" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal2 = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog2.save(date2)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </div>
            <div class="col-lg-3">
              <v-btn
                left
                color="grey"
                style="color:#ffffff;"
                @click="(date1 = ''), (date2 = ''), (procedimento = '')"
                >Limpar</v-btn
              >
            </div>
          </div>
        </v-container>
      </v-card-text>
    </v-card>
    <template>
      <v-data-table
        :headers="headers"
        :items="agendamentoFiltrado"
        :items-per-page="5"
        class="elevation-1"
      >
        <template v-slot:item.confirmado_st="{ item }">
          <td>
            <template v-if="item.id_consulta >= 1">
              <v-chip small color="green" dark>ATENDIDO</v-chip>
            </template>
            <template v-else-if="item.confirmado_st != 0">
              <v-chip small color="purple" dark>CONFIRMADO</v-chip>
            </template>
            <template v-else>
              <v-chip small color="yellow" dark>PENDENTE</v-chip>
            </template>
          </td>
        </template>
        <template v-slot:item.data="{ item }">
          <td>
            {{ formatDate(item.data) }}
          </td>
        </template>
      </v-data-table>
    </template>
  </div>
</template>

<script>
import PacienteService from "@/services/paciente_service.js";
import ProcedimentoService from "@/services/procedimento_service.js";
import Mixin from "@/mixins/vuex_mixin.js";

export default {
  mixins: [Mixin],
  props: {},
  beforeMount() {
    this.tipo_procedimento = this.detalhesAgenda.idTipoProcedimento;
  },
  data: () => ({
    colorSnackbar: "",
    dialogCadastroPaciente: false,
    loading: false,
    loadingAgendamento: false,
    pacientes: [],
    pacienteID: null,
    procedimento: "",
    procedimentos: [],
    tipo_procedimento: "",
    search_procedimentos: null,
    loading_procedimento: false,
    search: null,
    defaultFotos: {
      F:
        "https://thumbs.dreamstime.com/z/female-default-avatar-profile-icon-woman-face-silhouette-person-placeholder-vector-illustration-female-default-avatar-profile-icon-189495134.jpg",
      M:
        "https://us.123rf.com/450wm/thesomeday123/thesomeday1231709/thesomeday123170900021/85622928-default-avatar-profile-icon-grey-photo-placeholder-illustrations-vectors.jpg?ver=6",
    },
    listaTeste: [],
    date1: "",
    modal: false,
    date2: "",
    modal2: false, // Segunda modalidade
    selectedItem: null,
    items: [],
    pacienteSelected: "",
    headers: [
      { text: "Nome", value: "nome" },
      { text: "Status", value: "confirmado_st" },
      { text: "Telefone", value: "telefone_1" },
      { text: "Tipo de procedimento", value: "tipo_procedimento" },
      { text: "Procedimento", value: "procedimento" },
      { text: "Data", value: "data" },
      { text: "Hora inicio", value: "hora_inicio" },
      { text: "Hora final", value: "hora_final" },
    ],
    agendamentoFiltrado: [],
    data1Formatada: "",
    data2Formatada: "",
  }),
  mounted() {
    this.getLista();
  },
  methods: {
    formatadate(item) {
      if (item == 1) {
        if (this.date1 != "") {
          var dateArr = this.date1.split("-");

          let day = dateArr[2];
          let month = dateArr[1];
          let year = dateArr[0];

          this.data1Formatada = `${day}/${month}/${year}`;
        } else {
          this.data1Formatada = "";
        }
      } else {
        if (this.date2 != "") {
          var dateArr2 = this.date2.split("-");

          let day2 = dateArr2[2];
          let month2 = dateArr2[1];
          let year2 = dateArr2[0];

          this.data2Formatada = `${day2}/${month2}/${year2}`;
        } else this.data2Formatada = "";
      }
    },
    cleardate(date) {
      if (date == 1) {
        this.date1 = "";
        this.modal = false;
      } else if (date == 2) {
        this.date2 = "";
        this.modal2 = false;
      }
    },
    async getLista() {
      const procedimentoService = new ProcedimentoService();
      this.listaTeste = await procedimentoService.getListAsync();
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      date.setDate(date.getDate() + 1); // Adicionando 1 dia
      const day = date
        .getDate()
        .toString()
        .padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear().toString();
      return `${day}/${month}/${year}`;
    },

    isDisabled(item) {
      return item.disabled;
    },
    verificaAgendamento(paciente) {
      if (paciente.agendamento == "sim") {
        return false;
      } else {
        return true;
      }
    },
    buscarAgendamento() {
      if (this.pacienteID) {
        const procedimentoService = new ProcedimentoService();
        this.procedimentos = [];
        this.agendamentos = [];
        procedimentoService.getProcedimentoPorCliente(
          {
            onSucess: (status) => (body) => {
              if (status === 200) {
                this.procedimentos = body.data.procedimentos;
                this.procedimentos.unshift({
                  procedimento: "Todos",
                  id_procedimento: "",
                });
                this.agendamentos = body.data.agendamentos;
                this.filterWords();
              } else {
                this.procedimentos = [];
              }
            },
            onError: (error) => {
              console.error(error);
              this.procedimentos = [];
            },
            onEnd: () => {
              this.loading = false;
            },
          },
          this.pacienteID
        );
      }
    },
    // Adicione essa função para formatar a data
    descricaoProcedimento(item) {
      if (item.id_procedimento == "") {
        return item.procedimento;
      } else {
        return `${item.procedimento} | ${item.descricao}`;
      }
    },
    filterWords() {
      let filteredArray = this.agendamentos;

      if (this.procedimento != "") {
        filteredArray = filteredArray.filter((agendamento) => {
          return agendamento.id_procedimento == this.procedimento;
        });
      }

      if (this.date1 != "") {
        filteredArray = filteredArray.filter((agendamento) => {
          return new Date(agendamento.data) >= new Date(this.date1);
        });
      }
      if (this.date2 != "") {
        filteredArray = filteredArray.filter((agendamento) => {
          return new Date(agendamento.data) <= new Date(this.date2);
        });
      }

      this.agendamentoFiltrado = filteredArray;
    },
  },
  watch: {
    search_procedimentos(descricao) {
      const procedimentoService = new ProcedimentoService();
      procedimentoService.searchByDescricao(this)(descricao);
    },
    search(nome) {
      if (!nome) {
        this.pacientes = [];
      }
      if (!nome || !(nome = nome.trim()) || nome.length < 3 || this.loading) {
        return;
      }
      this.loading = true;
      this.pacientes = [];
      const pacienteService = new PacienteService();
      pacienteService.getPacienteCheckAgendamento(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.pacientes = body.data;
            } else {
              this.pacientes = [];
            }
          },
          onError: (error) => {
            console.error(error);
            this.pacientes = [];
          },
          onEnd: () => {
            this.loading = false;
          },
        },
        nome
      );
    },
    pacienteID() {
      this.pacienteSelected = "";
      if (this.pacienteID) {
        this.pacienteSelected = this.pacientes.find(
          (ele) => ele.paciente == this.pacienteID
        );
      }
    },
    date1() {
      this.formatadate(1);
      this.filterWords();
    },
    date2() {
      this.formatadate(2);
      this.filterWords();
    },
    procedimento() {
      this.filterWords();
    },
  },
  computed: {
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
  },
};
</script>
