import HTTPService from '@/services/http_service.js'

export default class AuthService extends HTTPService {
    resource = 'auth'
    login (responseFunctions, usuario, perfil) {
        const urlRequest = this.resource + '/'+perfil
        this.request(responseFunctions, 'POST', urlRequest, usuario)
    }

    register () {

    }
} 