<template>
  <div>
    <v-card min-width="400px" style="width: 100%">
      <v-toolbar color="#3E682A">
        <v-toolbar-title class="white--text">
          Relatório de Mapa Cirúrgico
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <div class="row">
            <div class="col-lg-12">
              <div class="row">
                <div class="col-md-6">
                  <v-text-field
                    v-model="startDate"
                    label="Data Inicial"
                    outlined
                    prepend-icon="mdi-calendar"
                    type="date"
                  ></v-text-field>
                </div>
                <div class="col-md-6">
                  <v-text-field
                    v-model="endDate"
                    label="Data Final"
                    outlined
                    prepend-icon="mdi-calendar"
                    type="date"
                  ></v-text-field>
                </div>
              </div>
            </div>
          </div>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('closeModal')"> Fechar </v-btn>

        <!-- BOTÂO PARA REAGENDAMENTO -->
        <v-btn
          text
          @click="gerarRelatorioPdf()"
          style="background-color: #1c8219; color: #fff !important"
        >
          <span> EXPORTAR PDF </span>
        </v-btn>

        <v-btn
          color="success"
          text
          @click="gerarRelatorioExcel()"
          style="background-color: #1c8219; color: #fff !important"
        >
          <span> EXPORTAR EXCEL </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import Mixin from "@/mixins/vuex_snackbar_mixin.js";
import RelatorioService from "@/services/relatorio_service.js";

export default {
  mixins: [Mixin],
  components: {},
  props: {},
  data: () => ({
    startDate: null,
    endDate: null,
    services: {
      relatorioService: RelatorioService.build(),
    },
  }),
  mounted() {},
  watch: {},
  computed: {},
  methods: {
    gerarRelatorioPdf() {
      this.loadingPdf = true;
      const startDate = this.startDate.split("-").join("");
      const endDate = this.endDate.split("-").join("");

      this.services.relatorioService
        .exportMapaCirurgicoPdf(startDate, endDate)
        .then((resp) => {
          if (resp.status) {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Falha ao carregar",
              color: "error",
            });
            return;
          }
          this.$_ACTIONS_showSnackbarMessage({
            message: "Dados encontrados com sucesso! Gerando PDF...",
            color: "sucess",
          });
          let link = document.createElement("a");
          link.download = `mapa-cirurgico.pdf`;
          let url = window.URL.createObjectURL(resp);
          link.href = url;
          link.click();
        })
        .catch(() => {
          this.$_ACTIONS_showSnackbarMessage({
            message: "Falha ao carregar",
            color: "error",
          });
        })
        .finally(() => {
          this.loadingPdf = false;
        });
    },
    gerarRelatorioExcel() {
      console.log("Gerando Excel");
      this.loadingPdf = true;
      const startDate = this.startDate.split("-").join("");
      const endDate = this.endDate.split("-").join("");

      this.services.relatorioService
        .exportMapaCirurgicoExcel(startDate, endDate)
        .then((resp) => {
          if (resp.status) {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Falha ao carregar",
              color: "error",
            });
            return;
          }
          this.$_ACTIONS_showSnackbarMessage({
            message: "Dados encontrados com sucesso! Gerando PDF...",
            color: "sucess",
          });
          let link = document.createElement("a");
          // link.target = "_blank";
          link.download = `mapa-cirurgico.xlsx`;
          let url = window.URL.createObjectURL(resp);
          link.href = url;
          link.click();
        })
        .catch(() => {
          this.$_ACTIONS_showSnackbarMessage({
            message: "Falha ao carregar",
            color: "error",
          });
        })
        .finally(() => {
          this.loadingPdf = false;
        });
    },
  },
};
</script>