<template>
  <div>
    <div class="p-lg-3">
      <div class="row" justify-content-between>
        <h3 class="mt-0 mb-2 font-weight-bold text-left">Pré-agendamentos</h3>
      </div>
    </div>

    <v-data-table
      v-on:update:sort-by="options.ordenacao = true"
      :footer-props="{'items-per-page-options': [5, 10, 15, 20]}"
      :search="search"
      :headers="headers"
      :items="preAgendamentosMapped"
      :options.sync="options"
      :server-items-length="totalList"
      :loading="loading"
      sort-by="descricao"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <!-- DIALOG CADASTRO -->
          <v-dialog v-model="dialogCadastrar" hide-overlay transition="dialog-bottom-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="#3E682A" dark class="mb-2" v-bind="attrs" v-on="on">
                Novo Pré-agendamento
              </v-btn>
            </template>
            <modal-cadastro v-if="dialogCadastrar"  @close="closeDialogCadastrar" @response="atualizarPreAgendamentoPosCadastro($event)"/>
          </v-dialog>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="mdi-magnify" label="Pesquisar" single-line hide-details/>
          <!-- DIALOG DE EXCLUSÃO-->
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline" style="word-break: normal;">Tem certeza de que deseja excluir este
                PreAgendamento?
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#3E682A" text @click="closeDelete">Cancelar</v-btn>
                <v-btn class="btn-danger" text style="color: #fff !important;" @click="deleteItemConfirm">Deletar</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- DIALOG EDIÇÃO -->
          <v-dialog v-if="dialogEdicao" v-model="dialogEdicao" max-width="500px">
            <modal-edicao
                v-if="dialogEdicao"
                :preAgendamento="editedPreAgendamento"
                @close="dialogEdicao = false"
                @response="atualizarPreAgendamentoPosEdicao($event)"
            />
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="deleteItem(item)">
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-data>
        <p class="text-center">Nenhum registro encontrado</p>
        <v-btn color="#3E682A" dark @click="atualizarPreAgendamentos">
          Atualizar
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>

import modalCadastro from '@/components/pre_agendamentos/modal_cadastro'
import modalEdicao from "@/components/pre_agendamentos/modal_edicao";
import Mixin from "@/mixins/vuex_snackbar_mixin.js";
import PreAgendamentoService from "../services/pre_agendamento_service";

export default {
  mixins:[Mixin],
  components: { modalCadastro, modalEdicao },
  data: () => ({
    dialogCadastrar: false,
    dialogEdicao: false,
    dialogDelete: false,
    editedPreAgendamento: {},
    search: '',
    totalList:10,
    options:{
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      ordenacao: false,
      desc:false,
    },
    headers: [
      { text: 'Cliente', align: 'start',  sortable: false, value: 'clienteNome' },
      { text: 'Telefone', align: 'start',  sortable: false, value: 'clienteTelefone' },
      { text: 'Data', align: 'start',  sortable: false, value: 'agendaData' },
      { text: 'H.início', align: 'start',  sortable: false, value: 'agendaHoraInicio' },
      { text: 'H.final', align: 'start',  sortable: false, value: 'agendaHoraFinal' },
      { text: 'Procedimento', align: 'start',  sortable: false, value: 'procedimentoDescricao' },
      // {text: 'Tipo',  align: 'center', value: 'tipo_preAgendamento', sortable: false},
      // {text: 'Ações', align: 'end', value: 'actions', sortable: false},
    ],
    preAgendamentos: [],
    loading: false,
  }),

  watch: {
    dialog(val) {
      val || this.closeDialogCadastrar()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
     options:{
      handler (){
        this.options.desc = this.options.sortDesc[0]??false
        this.atualizarPreAgendamentos();
      },
      deep:true
    },
    search(){
      this.atualizarPreAgendamentos();
    }
  },

  mounted() {
    this.atualizarPreAgendamentos()
  },

  computed: {
    preAgendamentosMapped() {
      return this.preAgendamentos.map((preAgendamento) => {
        const dataArr = preAgendamento.agendaData.split('-')
        const agendaData =  dataArr[2] + '/' + dataArr[1] + '/' + dataArr[0];
        return {...preAgendamento, agendaData }
      })
    }
  },

  methods: {
    atualizarPreAgendamentoPosCadastro() {
      this.atualizarPreAgendamentos()
      this.closeDialogCadastrar()
    },
    atualizarPreAgendamentoPosEdicao() {
      this.dialogEdicao = false
      this.atualizarPreAgendamentos()
    },
    atualizarPreAgendamentos() {
      this.loading = true
      const preAgendamentoservice = new PreAgendamentoService()
      this.loading = true;
      this.preAgendamentos=[];
      const onSucess = (status) => (body) => {
        if (status === 200) {
         this.totalList = body.data.pagination.num_rows
         this.preAgendamentos = body.data.preagendamentos.map(preAgendamento => {
           return {
             preAgendamentoId: ~~preAgendamento.pre_agendamento_id,
             agendaId: ~~preAgendamento.agenda_id,
             procedimentoId: ~~preAgendamento.procedimento_id,
             procedimentoDescricao: preAgendamento.procedimento_descricao || '',
             tipoProcedimentoDescricao: preAgendamento.tipo_procedimento_descricao || '',
             tipoProcedimentoId: ~~preAgendamento.tipo_procedimento_id,
             clienteNome: preAgendamento.cliente_nome || '',
             clienteTelefone: preAgendamento.cliente_telefone || '',
             preAgendamentoStatusId: ~~preAgendamento.pre_agendamento_status_id,
             preAgendamentoStatusDescricao: preAgendamento.pre_agendamento_status_descricao || '',
             agendaHoraInicio: preAgendamento.agenda_hora_inicio || '',
             agendaHoraFinal: preAgendamento.agenda_hora_final || '',
             agendaData: preAgendamento.agenda_data || '',
             agendaTipoProcedimentoDescricao: preAgendamento.agenda_tipo_procedimento_descricao || '',
           }
          });
        } else {
          console.log(body);
        }
      };
      const onError = (error) => {
        console.log(error);
      };
      const onEnd = () => {
        this.loading = false;
      };
      const [page,per_page,sort,search, desc] = [this.options.page, this.options.itemsPerPage, this.options.ordenacao, this.search, this.options.desc];
      preAgendamentoservice.getPaginatedList({onSucess, onError, onEnd},{page,per_page,sort, search, desc});
    },
    editItem(item) {
      this.editedPreAgendamento = JSON.parse(
          JSON.stringify(item)
      )
      this.dialogEdicao = true
    },

    deleteItem(item) {
      this.editedPreAgendamento = JSON.parse(JSON.stringify(item))
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.closeDelete()
      for (let i = 0, len = this.preAgendamentos.length; i < len; i++) {
        if (this.preAgendamentos[i].id === this.editedPreAgendamento.id) {
          const preAgendamentoservice = new PreAgendamentoService()
          preAgendamentoservice.deletePreAgendamento({
            onSucess: status => body => {
              this.$_ACTIONS_showSnackbarMessage({
                message: body.message || this.$global.messages.internalServerError,
                color: status === 200 ? 'sucess' : 'error'
              })
              status === 200 && this.preAgendamentos.splice(i, 1)
            },
            onError: () => {
              this.$_ACTIONS_showSnackbarMessage({
                message: this.$global.messages.internalServerError,
                color: 'error'
              })
            },
            onEnd: () => {
            }
          }, this.editedPreAgendamento)
          break;
        }
      }
    },

    closeDialogCadastrar() {
      this.dialogCadastrar = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
  },
}
</script>

