<template>
  <div>
    <v-card min-height="100vh" class="py-1">
      <v-toolbar style="background-color: #3e682a; color: #fff">
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          <span class="headline">Arquivo</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-data-table
          v-on:update:sort-by="options.ordenacao = true"
          :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
          :search="search"
          :headers="headers"
          :items="listArquivos"
          :options.sync="options"
          :server-items-length="totalList"
          :loading="loading"
          sort-by="descricao"
          class="elevation-1 m-2"
        >
          <template v-slot:top class="my-4">
            <v-toolbar flat>
              <div class="row mt-2 w-100">
                <div class="row">
                  <v-file-input
                    class="col-3"
                    accept=".pdf,.doc,.docx"
                    label="Add documento"
                    @change="selectFile"
                  ></v-file-input>

                  <v-text-field
                    label="Descrição do arquivo"
                    class="ml-2 col-3"
                    v-model="obs"
                  ></v-text-field>
                  <v-checkbox
                    v-model="visualizacaoCliente"
                    class="mt-3 ml-5"
                  ></v-checkbox>
                  <span class="mt-4 mr-3">Exibir para cliente</span>
                  <v-btn
                    fab
                    class="mx-2"
                    dark
                    small
                    color="#3e682a"
                    :loading="uploading"
                    @click="addDocumento"
                    title="Salvar arquivo"
                  >
                    <v-icon dark small>
                      fas fa-upload
                    </v-icon>
                  </v-btn>
                </div>
              </div>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Pesquisar"
                single-line
                hide-details
              />
              <!-- DIALOG DE EXCLUSÃO-->
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="headline" style="word-break: normal;"
                    >Tem certeza de que deseja excluir este documento?
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#3E682A" text @click="closeDelete"
                      >Cancelar</v-btn
                    >
                    <v-btn
                      class="btn-danger"
                      text
                      style="color: #fff !important;"
                      @click="deleteItemConfirm"
                      >Deletar</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
              class="mr-2"
              small
              @click="donwloadItem(item)"
              title="Baixar arquivo"
            >
              fas fa-download
            </v-icon>
            <v-icon
              class="mr-2"
              small
              @click="deleteItem(item)"
              title="Deletar arquivo"
            >
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:no-data>
            <p class="text-center">Nenhum registro encontrado</p>
            <v-btn color="#3E682A" dark @click="atualizarArquivos">
              Atualizar
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import DocumentoService from "@/services/documentos_service.js";
import Mixin from "@/mixins/vuex_snackbar_mixin.js";

export default {
  mixins: [Mixin],

  props: {
    arquivos: Array,
    paciente: Object,
  },
  beforeMount() {
    this.atualizarArquivos();
  },
  data: () => ({
    uploading: false,
    obs: "",
    file: "",
    dialogDelete: false,
    listArquivos: [],
    editedfile: "",
    search: "",
    totalList: 10,
    options: {
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      ordenacao: false,
      desc: false,
    },
    headers: [
      {
        text: "Nome",
        align: "start",
        sortable: true,
        value: "descricao",
      },
      { text: "Ações", align: "end", value: "actions", sortable: false },
    ],
    loading: false,
    visualizacaoCliente: true,
  }),

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        this.options.desc = this.options.sortDesc[0] ?? false;
        this.atualizarArquivos();
      },
      deep: true,
    },
    search() {
      this.atualizarArquivos();
    },
  },

  methods: {
    selectFile() {
      this.file = document.querySelector("input[type=file]").files[0];
    },
    atualizarArquivos() {
      const documentoService = new DocumentoService();
      this.loading = true;
      this.listArquivos = [];
      documentoService.buscar_arquivos(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.totalList = body.data.pagination.num_rows;
              this.listArquivos = body.data.arquivo;
            } else {
              console.log(body);
            }
          },
          onError: (error) => {
            console.log(error);
          },
          onEnd: () => {
            this.loading = false;
          },
        },
        this.paciente.id_paciente,
        this.options.page,
        this.options.itemsPerPage,
        this.search,
        this.options.ordenacao,
        this.options.desc
      );
    },

    addDocumento() {
      const documentoService = new DocumentoService();
      this.dialogLoading = true;
      var formdata = new FormData();
      const descricao = this.obs;

      formdata.append("arquivo", this.file);
      formdata.append("id_paciente", this.paciente.id_paciente);
      formdata.append("id_clinica", "1");
      formdata.append("descricao", descricao);
      formdata.append("id_medico", "1");
      formdata.append("cliente_view", this.visualizacaoCliente ? "1" : "0");

      documentoService.register(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.atualizarArquivos();
              this.$_ACTIONS_showSnackbarMessage({
                message: body.message,
                color: "sucess",
              });
            }
          },
          onError: (error) => {
            console.log(error);
          },
          onEnd: () => {
            this.uploading = false;
            this.file = "";

            document
              .getElementsByClassName("v-file-input__text")
              .item(0).innerHTML = "";

            this.obs = "";
          },
        },
        formdata
      );
    },
    deleteItem(item) {
      this.editedfile = item.id;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      const documentoService = new DocumentoService();
      this.loading = true;
      documentoService.deletar_arquivo(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.$_ACTIONS_showSnackbarMessage({
                message: body.message,
                color: "sucess",
              });
              this.atualizarArquivos();
            } else {
              console.log(body);
            }
          },
          onError: (error) => {
            console.log(error);
          },
          onEnd: () => {
            this.dialogDelete = false;
            this.loading = false;
          },
        },
        this.editedfile
      );
    },

    closeDelete() {
      this.dialogDelete = false;
    },

    donwloadItem(item) {
      const documentoService = new DocumentoService();
      this.loading = true;
      documentoService.ver_arquivo(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              const listFilesApplication = ["pdf", "doc", "docx"];
              const ext = listFilesApplication.includes(body.data.tipo_arquivo)
                ? "application/"
                : "image/";
              const link = document.createElement("a");
              const tipo_arquivo =
                body.data.tipo_arquivo == "docx"
                  ? "vnd.openxmlformats-officedocument.wordprocessingml.document"
                  : body.data.tipo_arquivo;
              link.href =
                "data:" + ext + tipo_arquivo + ";base64," + body.data.arquivo;

              link.download = item.descricao;
              link.click();
            } else {
              console.log(body);
            }
          },
          onError: (error) => {
            console.log(error);
          },
          onEnd: () => {
            this.loading = false;
          },
        },
        item.id
      );
    },
  },
};
</script>
