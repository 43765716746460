import HTTPService from '@/services/http_service.js'

export default class AssistenteService extends HTTPService {
    resource = 'assistente'

    register(responseFunctions, assistente) {
        this.fileRequest(responseFunctions, 'POST', this.resource, assistente)
    }

    self_register(responseFunctions, assistente) {
        this.request(responseFunctions, 'POST', 'auth/' + this.resource + '/auto-cadastro', assistente)
    }

    update(responseFunctions, assistente, idAssistente) {
        this.fileRequest(responseFunctions, 'POST', this.resource + "/" + idAssistente, assistente)
    }

    get(responseFunctions, idAssistente) {
        const url = this.resource + '/' + idAssistente
        this.request(responseFunctions, 'GET', url)
    }

    getAssistenteByIdUsuario({onSucess, onError, onEnd}, idUsuario) {
        const url = `${this.resource}/usuario/${idUsuario}`;
        this.request({onSucess, onError, onEnd}, 'GET', url)
    }

    getAll(onSucess, onError, onEnd, page, per_page, filter, order, desc) {
        const responseFunctions = {
            onSucess,
            onError,
            onEnd
        }
        let queryparams = {
            page: page,
            per_page: per_page
        }
        if (filter === '') {
            if (order) {
                let queryparams = {
                    page: page,
                    per_page: per_page,
                    sort: desc ? 'nome' : '-nome'
                }
                this.request(responseFunctions, 'GET', this.resource, null, queryparams)
            } else {
                this.request(responseFunctions, 'GET', this.resource, null, queryparams)
            }
        } else {
            const byNome = this.isCpf(filter)
            if (byNome) {
                let queryparams = {
                    page: page,
                    per_page: per_page,
                    nome: filter,
                }
                if (order) {
                    let queryparams = {
                        page: page,
                        per_page: per_page,
                        nome: filter,
                        sort: desc ? 'nome' : '-nome'
                    }
                    this.request(responseFunctions, 'GET', this.resource, null, queryparams)
                } else {
                    this.request(responseFunctions, 'GET', this.resource, null, queryparams)
                }
            } else {
                if (!filter) { filter = '' }
                let cpf = filter.replace(/(\.|-)/g, '');
                if (cpf.length > 3 && cpf.length < 7) {
                    cpf = cpf.replace(/^(\d{3})(\d)/, "$1.$2");
                }
                if (cpf.length > 6 && cpf.length < 11) {
                    cpf = cpf.replace(/^(\d{3})(\d{3})(\d)/, "$1.$2.$3");
                }
                if (cpf.length > 9 && cpf.length < 13) {
                    cpf = cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d)/, "$1.$2.$3-$4");
                }
                let queryparams = {
                    page: page,
                    per_page: per_page,
                    cpf: cpf,
                }
                if (order) {
                    let queryparams = {
                        page: page,
                        per_page: per_page,
                        cpf: cpf,
                        sort: desc ? 'nome' : '-nome'
                    }
                    this.request(responseFunctions, 'GET', this.resource, null, queryparams)
                } else {
                    this.request(responseFunctions, 'GET', this.resource, null, queryparams)
                }
            }

        }
    }

    isCpf(input) {
        const eCpf = input ? input.replace(/(\.|-)/g, '') : ''
        return isNaN(eCpf);
    }

    getAssistenteByName (responseFunctions, nome) {
        this.request(responseFunctions, 'GET', this.resource+'?nome='+nome, null)
    }

    //Função original
    getAssistenteDataByUsuarioID(responseFunctions, idUsuario) {
        this.request(responseFunctions, 'GET', this.resource + '/usuario/' + idUsuario)
    }

    searchByNome (vm) {
        return nome =>{
            if (!nome) {
                vm.pacientes = [];
                vm.paciente=''
            }
            if (!nome || !(nome = nome.trim()) || nome.length < 3 || vm.loading_paciente) {
                return;
            }
            vm.loading_paciente = true;
            vm.pacientes = [];
            const requestFunctions = (
                {
                    onSucess: (status) => (body) => {
                        if (status === 200) {
                            vm.pacientes = body.data.pacientes;
                        } else {
                            vm.pacientes = [];
                        }
                    },
                    onError: (error) => {
                        console.error(error);
                        vm.pacientes = [];
                    },
                    onEnd: () => {
                        vm.loading = false;
                    },
                },
                this.getPacienteByName(requestFunctions, nome)
            );
        }
    }
}