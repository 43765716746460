<template>
  <div>
    <v-card min-width="400px" style="width: 100%">
      <v-card-title>
        <span class="headline text-center">Detalhes agendamento</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <div class="row">
            <div class="col-lg-12">
              <div v-if="detalhesAgenda">
                <div class="row">
                  <div class="col-md-4">
                    <v-text-field
                      label="Data"
                      :value="detalhesAgenda.data | data"
                      placeholder=" "
                      readonly
                    ></v-text-field>
                  </div>

                  <div class="col-md-4">
                    <v-text-field
                      label="Horário inicial"
                      :value="detalhesAgenda.horarioInicial"
                      placeholder=" "
                      readonly
                    ></v-text-field>
                  </div>

                  <div class="col-md-4">
                    <v-text-field
                      label="Horário final"
                      :value="detalhesAgenda.horarioFinal"
                      placeholder=" "
                      readonly
                    ></v-text-field>
                  </div>

                  <div class="col-md-12">
                    <v-text-field
                      label="Paciente"
                      :value="detalhesAgenda.paciente.nome"
                      placeholder=" "
                      readonly
                    ></v-text-field>
                  </div>

                  <div class="col-md-12">
                    <v-text-field
                      label="Email"
                      :value="detalhesAgenda.paciente.email"
                      placeholder=" "
                      readonly
                    ></v-text-field>
                  </div>

                  <div class="col-md-12">
                    <v-text-field
                      label="Tipo de procedimento"
                      :value="detalhesAgenda.tipoProcedimento"
                      placeholder=" "
                      readonly
                    ></v-text-field>
                  </div>

                  <div class="col-md-12">
                    <v-text-field
                      label="Procedimento"
                      :value="detalhesAgenda.procedimento"
                      placeholder=" "
                      readonly
                    ></v-text-field>
                  </div>

                  <div class="col-md-6">
                    <v-text-field
                      label="CPF"
                      :value="detalhesAgenda.paciente.cpf"
                      placeholder=" "
                      readonly
                    ></v-text-field>
                  </div>

                  <div class="col-md-6">
                    <v-text-field
                      label="Telefone 1"
                      :value="detalhesAgenda.paciente.telefone_1"
                      placeholder=" "
                      readonly
                    ></v-text-field>
                  </div>

                  <div class="col-md-12">
                    <label><strong>Informações Adicionais</strong></label>
                    <v-select
                      v-model="procedimentos_form"
                      :items="procedimentos"
                      item-text="descricao"
                      item-value="id"
                      chips
                      label="Procedimentos Adicionais"
                      multiple
                    ></v-select>
                  </div>

                  <div class="col-md-12">
                    <v-textarea
                      label="Local"
                      :value="detalhesAgenda.local"
                      @input="detalhesAgenda.local = $event"
                      variant="outlined"
                      rows="2"
                      row-height="20"
                    >
                    </v-textarea>
                  </div>

                  <div class="col-md-12">
                    <v-textarea
                      label="Observações"
                      :value="detalhesAgenda.observacao"
                      @input="detalhesAgenda.observacao = $event"
                      variant="outlined"
                      rows="2"
                      row-height="20"
                    >
                    </v-textarea>
                  </div>

                  <!-- <div class="col-md-6">
                    <v-text-field
                      label="Telefone 2"
                      :value="detalhesAgenda.paciente.telefone_2"
                      placeholder=" "
                      readonly
                    ></v-text-field>
                  </div>

                  <div class="col-md-6">
                    <v-text-field
                      label="CEP"
                      :value="detalhesAgenda.paciente.cep"
                      placeholder=" "
                      readonly
                    ></v-text-field>
                  </div>

                  <div class="col-md-8">
                    <v-text-field
                      label="Logradouro"
                      :value="detalhesAgenda.paciente.logradouro"
                      placeholder=" "
                      readonly
                    ></v-text-field>
                  </div>

                  <div class="col-md-4">
                    <v-text-field
                      label="Número"
                      :value="detalhesAgenda.paciente.numero"
                      placeholder=" "
                      readonly
                    ></v-text-field>
                  </div>

                  <div class="col-md-6">
                    <v-text-field
                      label="Estado"
                      :value="detalhesAgenda.paciente.estado"
                      placeholder=" "
                      readonly
                    ></v-text-field>
                  </div>

                  <div class="col-md-6">  
                    <v-text-field
                      label="Cidade"
                      :value="detalhesAgenda.paciente.cidade"
                      placeholder=" "
                      readonly
                    ></v-text-field>
                  </div>

                  <div class="col-md-6">
                    <v-text-field
                      label="Bairro"
                      :value="detalhesAgenda.paciente.bairro"
                      placeholder=" "
                      readonly
                    ></v-text-field>
                  </div>

                  <div class="col-md-6">
                    <v-text-field
                      label="Complemento"
                      :value="detalhesAgenda.paciente.complemento"
                      placeholder=" "
                      readonly
                    ></v-text-field>
                  </div>
                  
                  <div class="col-md-12">
                    <v-text-field
                      label="Referências"
                      :value="detalhesAgenda.paciente.referencias"
                      placeholder=" "
                      readonly
                    ></v-text-field>
                  </div> -->
                </div>
              </div>
              <div v-else>
                <v-progress-circular
                  v-if="loading"
                  indeterminate
                  color="green"
                ></v-progress-circular>
                <v-alert v-if="error" type="error" dark>
                  Erro no carregamento dos dados. Tente novamente dentro de
                  alguns minutos. Se o serviço continuar sem funcionar, contate
                  o administrador do sistema.
                </v-alert>
              </div>
            </div>
          </div>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('close')"> Fechar </v-btn>

        <v-btn
          v-if="!detalhes.vencido"
          color="error"
          text
          :loading="loadingDesmarcar"
          @click="desmarcarConsulta"
          style="background-color: #ff5252; color: #fff !important"
        >
          <span v-if="!loadingDesmarcar"> Desmarcar </span>
          <v-progress-circular
            v-else
            indeterminate
            color="white"
          ></v-progress-circular>
        </v-btn>

        <v-btn
          v-if="!detalhes.vencido"
          color="error"
          text
          :loading="loadingRemarcar"
          @click="funcaoRemarcar(detalhesAgenda)"
          style="background-color: #ffa500; color: #fff !important"
        >
          <span v-if="!loadingRemarcar"> Remarcar </span>
          <v-progress-circular
            v-else
            indeterminate
            color="white"
          ></v-progress-circular>
        </v-btn>

        <v-btn
          v-if="!detalhes.vencido"
          color="error"
          text
          :loading="loadingDesmarcar"
          @click="informacaoAdicionalAgendamento"
          style="background-color: #ff5252; color: #fff !important"
        >
          <span v-if="!loadingDesmarcar"> Editar </span>
          <v-progress-circular
            v-else
            indeterminate
            color="white"
          ></v-progress-circular>
        </v-btn>
      </v-card-actions>
    </v-card>

    <confirmation-dialog
      v-if="confirmDesmarcar"
      :enabled="confirmDesmarcar"
      :dialogColor="'error'"
      :title="'Desmarcar consulta'"
      :confirmationMessage="
        'Você tem certeza que deseja desmarcar essa consulta?'
      "
      :confirmationButtonMessage="'Confirmar'"
      :abortButtonMessage="'Cancelar'"
      :confirmationButtonColor="'error'"
      @abort="confirmDesmarcar = false"
      @confirm="desmarcarConsulta"
    ></confirmation-dialog>

    <v-dialog v-model="modalVerReagendamento" max-width="500px">
      <modal-reagendamento
        v-if="modalVerReagendamento"
        @closeRemarcar="closeRemarcar"
        @closeRemarcaAtualiza="closeRemarcaAtualiza"
        :detalhesAgenda="detalhesAgenda"
      />
    </v-dialog>
  </div>
</template>

<script>
import PacienteService from "@/services/paciente_service.js";
import ConfirmationDialog from "@/components/dialogs/confirmation";
import Mixin from "@/mixins/vuex_usuario_mixin.js";
import AgendamentoService from "@/services/agendamento_service";
import ModalReagendamento from "./modal_detalhes_reagendamento";
import ProcedimentoService from "@/services/procedimento_service";

export default {
  mixins: [Mixin],
  components: { ConfirmationDialog, ModalReagendamento },
  props: {
    detalhes: {
      type: Object,
      default: () => {
        return {};
      },
      required: false,
      validator: (obj) => {
        switch (true) {
          case !/^\d{4}-\d{2}-\d{2}$/.test(obj.data):
            return false;
          case !/^\d{2}:\d{2}$/.test(obj.horarioInicial):
            return false;
          case !/^\d{2}:\d{2}$/.test(obj.horarioFinal):
            return false;
          case +obj.idPaciente < 1:
            return false;
          case +obj.idAgenda < 1:
            return false;
          case typeof obj.procedimento !== "string":
            return false;
          default:
            return true;
        }
      },
    },
  },
  data: () => ({
    paciente: null,
    loading: false,
    loadingDesmarcar: false,
    loadingRemarcar: false,
    error: false,
    confirmRemarcar: false,
    confirmDesmarcar: false,
    modalVerReagendamento: false,
    detalhesReagendamento: {},
    procedimentos: [],
    procedimentos_form: [],
    loading_procedimentos: false,
    observacao: null,
    observacao_form: null,
    localAgendamento: "",
  }),
  mounted() {
    if (!this.detalhesAgenda) {
      this.atualizarPaciente(this.detalhes);
      this.getProcedimentosAdicionais(this.detalhes);
    }
    console.log(this.detalhes);
    //this.observacao = this.detalhes.observacao
  },
  beforeMount() {
    this.listaProcedimentos();
  },
  watch: {
    detalhes(detalhes) {
      this.atualizarPaciente(detalhes);
      this.getProcedimentosAdicionais(this.detalhes);
    },
  },
  computed: {
    detalhesAgenda() {
      if (this.paciente) {
        const detalhesAgenda = this.detalhes;
        detalhesAgenda.paciente = this.paciente;
        return detalhesAgenda;
      } else {
        return null;
      }
    },
  },
  methods: {
    desmarcarConsulta() {
      this.confirmDesmarcar = false;
      const { idAgendamento } = this.detalhesAgenda;
      const agendamentoService = new AgendamentoService();
      this.loadingDesmarcar = true;
      agendamentoService.desmarcarAgendamento(
        {
          onSucess: (status) => (body) => {
            this.$emit("desmarcar", {
              status,
              message: body.message,
              idAgenda: this.detalhesAgenda.idAgenda,
            });
          },
          onError: (error) => {
            this.$emit("desmarcar", { error });
          },
          onEnd: () => {
            this.loadingDesmarcar = false;
          },
        },
        idAgendamento
      );
    },
    informacaoAdicionalAgendamento() {
      const { idAgendamento } = this.detalhesAgenda;
      const request = {
        observacao: this.detalhesAgenda.observacao || "",
        procedimentos_adicionais: this.procedimentos_form,
        local: this.detalhesAgenda.local,
      };

      const agendamentoService = new AgendamentoService();
      agendamentoService.informacaoAdicionalAgendamento(
        {
          onSucess: (status) => (body) => {
            this.$emit("editar", {
              status,
              message: body.message,
            });
            this.$emit("atualizar");
          },
          onError: (error) => {
            this.$emit("editar", { error });
          },
          onEnd: () => {},
        },
        idAgendamento,
        request
      );
    },
    funcaoRemarcar() {
      this.modalVerReagendamento = true;
    },
    closeRemarcar() {
      this.modalVerReagendamento = false;
    },
    closeRemarcaAtualiza() {
      this.modalVerReagendamento = false;
      this.$emit("atualizar");
      this.$emit("close");
    },
    atualizarPaciente(detalhes) {
      const pacienteService = new PacienteService();
      this.loading = true;
      this.error = false;
      this.paciente = null;
      pacienteService.get(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.paciente = {};
              this.paciente.nome = body.data.nome;
              this.paciente.cpf = body.data.cpf;
              this.paciente.email = body.data.email;
              this.paciente.telefone_1 = body.data.telefone_1;
              this.paciente.telefone_2 = body.data.telefone_2;
              this.paciente.cep = body.data.cep;
              this.paciente.cidade = body.data.cidade;
              this.paciente.estado = body.data.estado;
              this.paciente.logradouro = body.data.logradouro;
              this.paciente.bairro = body.data.bairro;
              this.paciente.referencias = body.data.referencias;
              this.paciente.complemento = body.data.complemento;
              this.paciente.numero = body.data.numero;
            } else {
              this.loading = false;
              this.error = true;
              console.error(body.message);
            }
          },
          onError: (error) => {
            if (error) {
              this.loading = false;
              this.error = true;
              console.error(error);
            }
          },
          onEnd: () => {
            this.loading = false;
          },
        },
        detalhes.idPaciente
      );
    },
    listaProcedimentos() {
      this.loading_procedimentos = true;
      const procedimentoService = new ProcedimentoService();
      procedimentoService.getList({
        onSucess: (status) => (body) => {
          if (status === 200) {
            this.procedimentos = body.data.procedimentos;
          } else {
            this.procedimentos = [];
          }
        },
        onError: (error) => {
          console.error(error);
          this.procedimentos = [];
        },
        onEnd: () => {
          this.loading_procedimentos = false;
        },
      });
    },
    getProcedimentosAdicionais(detalhes) {
      this.loading_procedimentos = true;
      //const { idAgendamento } = this.detalhesAgenda;

      const agendamentoService = new AgendamentoService();
      agendamentoService.getProcedimentoAdicionalAgendamento(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.procedimentos_form = this.procedimentosItens(body.data);
            } else {
              //this.procedimentos = [];
            }
          },
          onError: (error) => {
            console.error(error);
            this.procedimentos = [];
          },
          onEnd: () => {
            this.loading_procedimentos = false;
          },
        },
        detalhes.idAgendamento
      );
    },
    procedimentosItens(procedimento) {
      let i = 0;
      let resp = [];
      while (i < procedimento.length) {
        resp[i] = +procedimento[i].id_procedimento;
        i++;
      }
      return resp;
    },
  },
};
</script>
