<template>
  <div class="d-flex col-12">
     <v-text-field
        class="col-10"
        :disabled="disabled"
        v-model="pergunta.texto_pergunta"
        :required="pergunta.obrigatorio"
        :messages="pergunta.obrigatorio?'obrigatória':'não obrigatória'"
      ></v-text-field>
      <v-switch
          class="col-2"
          v-model="mudar_obrigatoria"
          :disabled="disabled"
          label="mudar obrigatoriedade?"
        ></v-switch>
  </div>
</template>
<script>
import Mixin from "@/mixins/vuex_snackbar_mixin.js";

export default {
  mixins: [Mixin],
  props:{
    pergunta:Object,
    disabled:Boolean,
    
  },
  beforeMount(){
    this.mudar_obrigatoria = this.pergunta.obrigatorio
  },
  data: () => ({
    mudar_obrigatoria:null
  }),
  watch:{
    mudar_obrigatoria(value){
      this.pergunta.obrigatorio = value
    },
  }
};
</script>
<style scoped>
.v-progress-circular {
  margin: 1rem;
}
</style>