import HTTPService from '@/services/http_service.js'

export default class UsuarioService extends HTTPService {
    resource = 'usuario'
    static build() {
        return new UsuarioService();
    }
    alterarSenha (responseFunctions, senhas, idUsuario) {
        this.request(responseFunctions, 'POST', this.resource+'/'+idUsuario+'/alterar-senha', senhas)
    }

    esqueciSenha (responseFunctions, email) {
        this.request(responseFunctions, 'POST', 'auth/'+this.resource+'/esqueci-a-senha', email)
    }

    getUsuarioData ({onSucess, onError, onEnd}, idUsuario) {
        const url = `${this.resource}/${idUsuario}`;
        this.request({onSucess, onError, onEnd}, 'GET', url)
    }

    updateUsuarioData ({onSucess, onError, onEnd}, idUsuario, data) {
        const url = `${this.resource}/${idUsuario}`;
        this.request({onSucess, onError, onEnd}, 'POST', url, data)
    }

    updateUsuarioDataFile ({onSucess, onError, onEnd}, idUsuario, data) {
        const url = `${this.resource}/${idUsuario}`;
        this.fileRequest({onSucess, onError, onEnd}, 'POST', url, data)
    } 

    alterarEmail({onSucess, onError, onEnd}, idUsuario, {email}) {
        const url = `email/${idUsuario}`;
        const data = {email};
        const behavior = {onSucess, onError, onEnd};
        const method = 'POST';
        this.request(behavior, method, url, data);
    }
}