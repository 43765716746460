<template>
  <div class="d-flex flex-column">
    <p class="resp">{{ nomeMedico }}</p>
    <p class="resp">
      Encaminho o(a) paciente <b>{{ nome }}</b>, para avaliação e conduta que julgar
      necessária.
    </p>
    <p class="ml-auto">Agradeço a atenção.</p>
    <div class="d-flex flex-column text-end pt-8">
      <p class="ml-auto">
        Fortaleza, {{ gerarData().dia
        }}<span class="space">_</span>de<span class="space">_</span
      >{{ gerarData().mes }}<span class="space">_</span
      >{{ gerarData().ano }}.
      </p>
      <p class="d-flex flex-column ml-auto">
        <span> _________________________________________</span>
        <span class="ml-2">Luiz Nestor Vasconcelos Rodrigues </span>
        <span class="ml-1 resp">Cirurgião Plástico - CRM 6110 - RQE 2395</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "documento_encaminhamento",
  props: {
    nomeMedico: {required: true},
    nome: {required: true},
  },
  methods: {
    gerarData() {
      const meses = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novovembro",
        "Dezembro",
      ];
      let data = new Date();
      return {
        dia: data.getDate(),
        mes: meses[data.getMonth()],
        ano: data.getFullYear(),
      };
    },
  }
}
</script>

<style scoped>

</style>
