<template>
  <div>
    <!-- filtro -->
    <div class="my-2">
      <v-row justify="center">
        <v-expansion-panels focusable>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h5>Filtrar</h5>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <filtroAgendamento
                @filtro="filtrarCalendario($event)"
                @limparFiltro="limparFiltro"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </div>
    <v-sheet height="64">
      <v-toolbar flat>
        <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday"
          >Hoje</v-btn
        >
        <v-btn fab text small color="grey darken-2" @click="prev">
          <v-icon small> mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn fab text small color="grey darken-2" @click="next">
          <v-icon small> mdi-chevron-right </v-icon>
        </v-btn>
        <v-toolbar-title v-if="$refs.calendar">
          {{ $refs.calendar.title }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-menu bottom right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
              <span>{{ calendario.labels[calendario.typeView] }}</span>
              <v-icon right>mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="calendario.typeView = 'day'">
              <v-list-item-title>{{ calendario.labels.day }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="calendario.typeView = 'week'">
              <v-list-item-title>{{
                calendario.labels.week
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="calendario.typeView = 'month'">
              <v-list-item-title>{{
                calendario.labels.month
              }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>
    </v-sheet>
    <v-sheet height="600">
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="primary"
      ></v-progress-linear>
      <v-calendar
        v-show="!loading"
        ref="calendar"
        v-model="calendario.focus"
        color="primary"
        interval-height="120px"
        :event-text-color="getEventText"
        :events="horarios"
        :event-color="getEventColor"
        :type="calendario.typeView"
        @click:event="showEvent"
        @click:more="viewDay"
        @click:date="viewDay"
        v-on:moved="chengEvent"
      >
      </v-calendar>
    </v-sheet>

    <!-- MODAL - AGENDA -->
    <v-dialog v-model="dialog" max-width="500px">
      <modal-agendar-paciente
        v-if="modalMarcarAgendamento"
        @close="dialog = false"
        :detalhesAgenda="detalhesAgenda"
        @submit="tratarRespostaAgendar"
      />
      <modal-detalhes-agenda
        v-if="modalVerDetalhesAgendamento"
        :detalhes="detalhesAgenda"
        @close="dialog = false"
        @editar="tratarRespostaEditar"
        @desmarcar="tratarRespostaDesmarcar"
        @atualizar="atualizarCalendario"
      />
      <modal-horario-desabilitado
        v-if="modalHabilitarHorario"
        @close="dialog = false"
        :HorarioVencido="vencido"
      />
    </v-dialog>
  </div>
</template>

<script>
import AgendaService from "@/services/agenda_service.js";
import ModalHorarioDesabilitado from "./modal_horario_desabilitado";
import ModalAgendarPaciente from "./modal_agendar_paciente";
import ModalDetalhesAgenda from "./modal_detalhes_agenda";
import FiltroAgendamento from "@/components/agendamentos/filtro_agendamento.vue";
import Mixin from "@/mixins/vuex_mixin.js";

export default {
  mixins: [Mixin],
  components: {
    ModalHorarioDesabilitado,
    ModalAgendarPaciente,
    ModalDetalhesAgenda,
    FiltroAgendamento,
  },

  beforeMount() {
    let data = new Date();
    this.pesquisa_periodo(data), this.atualizarCalendario();
  },
  mounted() {
    this.$refs.calendar.checkChange();
  },
  data: () => ({
    focusCalendario: "",
    dialog: false,
    loading: false,
    corHorarioCancelado: "grey",
    corHorarioOcupado: "primary",
    //aqui
    corHorarioLivre: "#3e682a",
    typeViewCalendario: "month",
    labelsCalendario: {
      month: "Mês",
      week: "Semana",
      day: "Dia",
    },
    calendario: {
      labels: {
        month: "Mês",
        week: "Semana",
        day: "Dia",
      },
      focus: "",
      typeView: "month",
    },
    modalMarcarAgendamento: false,
    modalVerDetalhesAgendamento: false,
    modalHabilitarHorario: false,
    horarios: [],
    detalhesAgenda: {},
    vencido: true,
    params: {},
  }),
  methods: {
    pesquisa_periodo(data) {
      console.log(data);
      let ano = data.getFullYear();
      let mes = data.getMonth();
      let di = new Date(ano, mes, -8);
      let df = new Date(ano, mes + 1, +8);
      this.params = {
        data_inicio: di.toISOString().substr(0, 10),
        data_fim: df.toISOString().substr(0, 10),
      };
    },
    viewDay({ date }) {
      this.calendario.focus = date;
      this.calendario.typeView = "day";
    },
    getEventText(event) {
      if (event.color == "yellow darken-1") {
        return "white";
      }
      return "white";
    },
    getEventColor(event) {
      if(event.idAgendamento == 0){
        if(event.preAgendado == true){
          return 'yellow darken-2'
        }else{
          return 'grey'
        }
      }else{
        if(event.consultaId > 0 ){
          return '#3E682A'
        }else{
          console.log("evento",event)
          if(event.statusConsulta == 1){
            return 'purple'
          }else{
            return '#188ae2'
          }
        }
      }
      },
     
      // if(event.preAgendado == true && event.idAgendamento == 0){
      //   event.color = "yellow"
      // }else{
      //   if(event.idAgendamento > 0 && event.consultaId > 0){
      //   event.color = "green"
      // }else if(event.idAgendamento > 0 && event.statusConsulta == 0){
      //   event.color = "blue"
      // }} 
      // return event.color;
    setToday() {
      this.calendario.focus = "";
    },
    chengEvent(e) {
      console.log(e);
      this.pesquisa_periodo(new Date(e.year, e.month - 1, 1));
      this.atualizarCalendario();
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent(context) {
      const eventParsed = context.eventParsed;
      const dataHorarioSelecionado =
        eventParsed.start.date + " " + eventParsed.start.time;
      const horarioObj = this.horarios.find((horario) => {
        return horario.start === dataHorarioSelecionado;
      });
      // if(horarioObj.preAgendado && !horarioObj.idAgendamento) {
      // this.modalMarcarAgendamento = true;
      // this.$_ACTIONS_showSnackbarMessage({
      //   message: "Esse horário está pré-agendado.",
      //   color: "sucess",
      // });
      // return;
      // }
      const vencido = horarioObj.vencido;
      const cancelado = horarioObj.cancelado;
      const marcado = horarioObj.marcado;
      this.dialog = true;
      this.detalhesAgenda = null;
      this.modalHabilitarHorario = false;
      this.modalVerDetalhesAgendamento = false;
      this.modalMarcarAgendamento = false;
      let modal = marcado
        ? cancelado
          ? "modalMarcarAgendamento"
          : "modalVerDetalhesAgendamento"
        : vencido
        ? "modalHabilitarHorario"
        : "modalMarcarAgendamento";
      this[modal] = true;

      if (marcado) {
        console.log(horarioObj);
        this.detalhesAgenda = {
          idAgenda: horarioObj.idAgenda,
          procedimento: horarioObj.procedimento,
          idPaciente: horarioObj.idPaciente,
          tipoProcedimento: horarioObj.name,
          idTipoProcedimento: horarioObj.idTipoProcedimento,
          idAgendamento: horarioObj.idAgendamento,
          horarioInicial: horarioObj.horarioInicial,
          horarioFinal: horarioObj.horarioFinal,
          data: horarioObj.data,
          vencido: horarioObj.vencido,
          observacao: horarioObj.observacao,
          local: horarioObj.local,
        };
      } else if (!vencido) {
        console.log(horarioObj);
        this.detalhesAgenda = horarioObj;
      }
    },
    tratarRespostaAgendar(event) {
      if (event.error) {
        this.$_ACTIONS_showSnackbarMessage({
          message:
            "Um erro aconteceu durante o processamento da sua solicitação.",
          color: "error",
        });
      } else {
        const requestWasSucessfull = event.status === 200;
        this.$_ACTIONS_showSnackbarMessage({
          message: event.message,
          color: requestWasSucessfull ? "sucess" : "error",
        });
        this.dialog = !requestWasSucessfull;
        if (requestWasSucessfull) {
          const { request } = event;
          const { procedimento } = event;
          for (let i = 0, len = this.horarios.length; i < len; i++) {
            if (this.horarios[i].idAgenda === this.detalhesAgenda.idAgenda) {
              this.horarios[i].marcado = true;
              this.horarios[i].cancelado = false;
              this.horarios[i].idPaciente = request.id_paciente;
              this.horarios[i].idCliente = request.id_cliente;
              this.horarios[i].idAgendamento = request.id_agendamento;
              this.horarios[i].observacao = request.observacao;
              this.horarios[i].procedimento = procedimento;
              this.horarios[i].color = this.corHorarioOcupado;
              break;
            }
          }
          this.atualizarCalendario();
        }
      }
    },
    tratarRespostaEditar(event) {
      if (event.error) {
        this.$_ACTIONS_showSnackbarMessage({
          message:
            "Um erro aconteceu durante o processamento da sua solicitação.",
          color: "error",
        });
      } else {
        const requestWasSucessfull = event.status === 200;
        this.$_ACTIONS_showSnackbarMessage({
          message: event.message,
          color: requestWasSucessfull ? "sucess" : "error",
        });
        this.dialog = !requestWasSucessfull;
      }
    },
    tratarRespostaDesmarcar(event) {
      if (event.error) {
        this.$_ACTIONS_showSnackbarMessage({
          message:
            "Um erro aconteceu durante o processamento da sua solicitação.",
          color: "error",
        });
      } else {
        const requestWasSucessfull = event.status === 200;
        this.$_ACTIONS_showSnackbarMessage({
          message: event.message,
          color: requestWasSucessfull ? "sucess" : "error",
        });
        this.dialog = !requestWasSucessfull;
        if (requestWasSucessfull) {
          for (let i = 0, len = this.horarios.length; i < len; i++) {
            if (this.horarios[i].idAgenda === this.detalhesAgenda.idAgenda) {
              this.horarios[i].marcado = false;
              this.horarios[i].idPaciente = 0;
              this.horarios[i].idAgendamento = 0;
              this.horarios[i].procedimento = "";
              this.horarios[i].color = this.corHorarioLivre;
              this.horarios[i].cancelado = true;
              break;
            }
          }
          this.atualizarCalendario();
        }
      }
    },
    atualizarCalendario() {
      const agendaService = new AgendaService();
      const idMedico = 1;
      this.loading = true;
      agendaService.getAgendaList(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.horarios = [];
              console.log(body.data);
              body.data.agendas.forEach((horario) => {
                let titulo = "";
                if (horario.nome != "") {
                  titulo = `${horario.tipo_procedimento}: ${horario.nome}`;
                } else {
                  titulo = `${horario.tipo_procedimento}`;
                }

                const horarioAgenda = {
                  name: titulo,
                  idAgenda: horario.id,
                  procedimento: horario.procedimento,
                  start: horario.data + " " + horario.hora_inicio,
                  end: horario.data + " " + horario.hora_final,
                  data: horario.data,
                  horarioInicial: horario.hora_inicio,
                  horarioFinal: horario.hora_final,
                  idAgendamento: horario.id_agendamento,
                  idPaciente: horario.id_paciente,
                  idTipoProcedimento: horario.id_tipo_procedimento,
                  color: horario.id_agendamento
                    ? this.definir_cor(true, horario.data, horario.cancelado_em)
                    : horario.pre_agendamento_id
                    ? "yellow darken-1"
                    : this.definir_cor(
                        false,
                        horario.data,
                        horario.cancelado_em
                      ),
                  timed: false,
                  marcado: !!horario.id_agendamento,
                  cancelado:
                    horario.cancelado_em === "" ||
                    horario.cancelado_em === "0000-00-00 00:00:00"
                      ? false
                      : true,
                  vencido: this.horarioVencido(horario.data),
                  preAgendado: !!horario.pre_agendamento_id,
                  observacao: horario.observacao,
                  local: horario.local,
                  statusConsulta: horario.confirmado_st,
                  consultaId: horario.id_consulta
                };
                this.horarios.push(horarioAgenda);
              });
            } else {
              console.error("error");
            }
          },
          onError: () => {
            this.$_ACTIONS_showSnackbarMessage({
              message: this.$global.messages.internalServerError,
              color: "error",
            });
          },
          onEnd: () => {
            this.loading = false;
          },
        },
        idMedico,
        this.params
      );
    },
    horarioVencido(data) {
      let dataHorario = new Date(data).toISOString().split("T")[0];
      let dataHj = new Date().toISOString().split("T")[0];
      return dataHorario < dataHj ? true : false;
    },
    definir_cor(ocupado, data, cancelado_em) {
      let dataHorario = new Date(data).toISOString().split("T")[0];
      let dataHj = new Date().toISOString().split("T")[0];
      if (
        ocupado &&
        (cancelado_em === "" || cancelado_em === "0000-00-00 00:00:00")
      ) {
        return this.corHorarioOcupado;
      } else if (dataHorario < dataHj) {
        return this.corHorarioCancelado;
      } else {
        return this.corHorarioLivre;
      }
    },
    filtrarCalendario(event) {
      this.loading = true;
      const procedimento = event[0];
      const paciente = event[1];
      const data = event[2];
      if (data) {
        if (procedimento) {
          this.params = {
            data_inicio: data,
            data_fim: data,
            id_procedimento: procedimento,
          };
        }
        if (paciente) {
          this.params = {
            data_inicio: data,
            data_fim: data,
            id_paciente: paciente,
          };
        }
        if (procedimento && paciente) {
          this.params = {
            data_inicio: data,
            data_fim: data,
            id_procedimento: procedimento,
            id_paciente: paciente,
          };
        }
        this.atualizarCalendario();
      }
      if (paciente) {
        this.horarios = this.horarios.filter(function(horario) {
          return horario.idPaciente == paciente;
        });
      }
      if (procedimento) {
        this.horarios = this.horarios.filter(function(horario) {
          return horario.procedimento == procedimento;
        });
      }
      if (data) {
        this.calendario.typeView = "day";
        this.calendario.focus = data;
      }
      this.loading = false;
    },
    limparFiltro() {
      let data = new Date();
      this.pesquisa_periodo(data), this.atualizarCalendario();
      this.calendario.typeView = "month";
    },
  },
};
</script>
<style>
.theme--light.v-calendar-events .v-event-timed {
  justify-content: center;
  display: flex;
  flex-direction: column;
  font-weight: bold;
}
.theme--light.v-calendar-events .v-event-timed.yellow.darken-1 {
  border-color: white !important;
}
</style>
