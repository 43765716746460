<template>
  <v-card>
    <v-toolbar dark color="#3e682a">
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Enviar imagens</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        dark
        color="primary"
        @click="salvarTodasFotos"
        :disabled="salvarTodasDisabled"
      >
        Salvar Todas
      </v-btn>
    </v-toolbar>
    <v-container>
      <div class="d-flex align-center flex-wrap justify-center">
        <div v-for="(item, index) in fotoUploads" :key="index" class="mx-2">
          <v-img
            v-for="(preview, i) in item.preview"
            :key="i"
            lazy-src="https://picsum.photos/id/11/10/6"
            max-height="150"
            max-width="800px"
            class="m-auto"
            :src="preview"
          ></v-img>
          <v-file-input
            v-model="item.fotos"
            accept="image/png, image/jpeg, image/bmp"
            multiple
            placeholder="Adicione fotos"
            prepend-icon="mdi-camera"
            @click:clear="clean(item)"
            @change="onChange($event, item)"
            style="margin:30% auto 5% auto; max-width:800px;"
          ></v-file-input>
          <v-textarea
            style="width:500px;"
            v-model="item.obs"
            label="Observação"
            class="col-12 m-auto"
            rows="3"
          ></v-textarea>
          <v-divider
            v-if="index !== fotoUploads.length - 1"
            class="my-4"
          ></v-divider>
        </div>
      </div>
    </v-container>
  </v-card>
</template>

<script>
import Mixin from "@/mixins/vuex_snackbar_mixin.js";
import FotoService from "@/services/fotos_service.js";

export default {
  components: {},
  props: {
    paciente: Object,
  },
  mixins: [Mixin],
  data: () => ({
    fotoFullScreen: false,
    fotoFullScreenRef: "",
    loadingClassificar: false,
    buscando: false,
    dialogLoading: false,
    fotoUploads: [
      {
        fotos: [],
        obs: "",
        preview: [],
      },
    ],
    galeriaFotos: [],
    selected: [],
    descricao: "",
    criado_em: "",
    deletedItem: "",
    confirmDeletar: false,
    //Antes e Depois
    fotoAntesSelecionada: {},
    fotoDepoisSelecionada: {},
    fotoAntes: [],
    fotoDepois: [],
    modelGaleriaAntes: null,
    modelGaleriaDepois: null,
    width: 300,
    paciente: {},
    salvarTodasDisabled: true, // Inicialmente desabilitado até que todos os inputs estejam preenchidos
  }),
  computed: {
    todosInputsPreenchidos() {
      return this.fotoUploads.every(item => item.fotos.length > 0 && item.obs !== "");
    },
  },
  watch: {
    todosInputsPreenchidos(newVal) {
      this.salvarTodasDisabled = !newVal; // Habilita ou desabilita o botão "Salvar Todas" conforme os inputs são preenchidos
    }
},
  methods: {
    addFotoUpload() {
      this.fotoUploads.push({
        fotos: [],
        obs: "",
        preview: [],
      });
    },
    clean(item) {
      item.fotos = [];
      item.preview = [];
    },
    onChange(event, item) {
      const files = event.target.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const reader = new FileReader();
        reader.onloadend = (e) => {
          item.preview.push(e.target.result);
        };
        if (file) {
          reader.readAsDataURL(file);
        }
      }
    },
    salvarTodasFotos() {
      this.salvarTodasDisabled = true; // Desabilita o botão "Salvar Todas"
      this.fotoUploads.forEach((item) => {
        if (item.fotos.length > 0 && item.obs !== "") {
          this.enviarFotos(item);
        }
      });
    },
    enviarFotos(item) {
      item.fotos.forEach((foto) => {
        const reader = new FileReader();
        reader.onloadend = (e) => {
          this.salvarFoto(e, item);
        };
        if (foto) {
          reader.readAsDataURL(foto);
        }
      });
    },
    salvarFoto(e, item) {
      const fotoService = new FotoService();
      this.dialogLoading = true;
      const params = {
        id_clinica: 1,
        id_paciente: this.paciente.id_paciente,
        id_medico: 1,
        descricao: item.obs,
        image: e.target.result,
      };
      fotoService.register(
        {
          onSucess: (status) => {
            if (status === 200) {
              this.$_ACTIONS_showSnackbarMessage({
                message: "Foto salva com sucesso",
                color: "sucess",
              });
              this.$emit('close');
            }
          },
          onError: (error) => {
            console.log(error);
            this.$_ACTIONS_showSnackbarMessage({
              message: "Erro ao enviar",
              color: "error",
            });
          },
          onEnd: () => {
            this.dialogLoading = false;
            item.fotos = [];
            item.preview = [];
            item.obs = "";
            this.salvarTodasDisabled = !this.todosInputsPreenchidos; // Habilita o botão "Salvar Todas" novamente se todos os inputs estiverem preenchidos
          },
        },
        params
      );
    },
    searchPhoto(id) {
      const fotoService = new FotoService();
      fotoService.getFoto(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              for (let i = 0, len = this.galeriaFotos.length; i < len; i++) {
                if (this.galeriaFotos[i].id === id) {
                  this.galeriaFotos[i].imagem = body.data[0].imagem;
                  this.galeriaFotos[i].criado_em = body.data[0].criado_em;
                  break;
                }
              }
            }
          },
          showSnackbar(message, color) {
            this.snackbar.message = message;
            this.snackbar.color = color;
            this.snackbar.show = true;
          },
          onError: (error) => {
            console.log(error);
          },
          onEnd: () => {},
        },
        id
      );
    },
    dialogConfirmDeletar(id) {
      this.deletedItem = id;
      this.confirmDeletar = true;
    },
    deletarClassificacao() {
      this.confirmDeletar = false;
      const fotoService = new FotoService();
      fotoService.deleteFoto(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.$_ACTIONS_showSnackbarMessage({
                message: body.message,
                color: "sucess",
              });
              this.carregarFotos();
            }
          },
          onError: (error) => {
            console.log(error);
          },
          onEnd: () => {},
        },
        this.deletedItem
      );
    },
  },
};
</script>
