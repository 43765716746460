import HTTPService from '@/services/http_service.js'

export default class FormularioService extends HTTPService {
    resource = 'formulario'
    register (responseFunctions, formulario) {
        this.jsonRequest(responseFunctions, 'POST', this.resource, formulario)
    }
    
    updateCompleto (responseFunctions, formulario, idFormulario) {
        this.jsonRequest(responseFunctions, 'POST', this.resource+"/complete/"+idFormulario, formulario )
    }

    updateSimples (responseFunctions, formulario, idFormulario) {
        this.jsonRequest(responseFunctions, 'POST', this.resource+"/simples/"+idFormulario, formulario )
    }
    
    desativar (responseFunctions, idFormulario) {
        this.jsonRequest(responseFunctions, 'POST', this.resource+"/desativa/"+idFormulario )
    }
    
    shortUpdate (responseFunctions, formulario, idFormulario) {
        this.jsonRequest(responseFunctions, 'POST', this.resource+"/simple/"+idFormulario, formulario )
    }

    get (responseFunctions, idFormulario) {
        const url = this.resource + '/' + idFormulario
        this.request(responseFunctions, 'GET', url)
    }

    getAll ({onSucess, onError, onEnd}, filter) {
        if(filter === null) {
            this.request({onSucess, onError, onEnd}, 'GET', this.resource+'/medico/1')
        }else{
            this.request({onSucess, onError, onEnd}, 'GET', this.resource+'/medico/1', null, filter)
        }
    }

    getAnamneseByName (responseFunctions, nome) {
        this.request(responseFunctions, 'GET', this.resource, null, { nome })
    }

    searchByNome (vm) {
        return nome =>{
            if (!nome) {
                vm.forms = [];
                vm.formulario=''
            }
            if (!nome || !(nome = nome.trim()) || nome.length < 3 || vm.loading_formulario) {
                return;
            }
            vm.loading_formulario = true;
            vm.anamneses = [];
            const requestFunctions = (
                {
                    onSucess: (status) => (body) => {
                        if (status === 200) {
                            vm.formularios = body.data.formularios;
                        } else {
                            vm.formularios = [];
                        }
                    },
                    onError: (error) => {
                        console.error(error);
                        vm.formularios = [];
                    },
                    onEnd: () => {
                        vm.loading = false;
                    },
                },
                this.getFormularioByName(requestFunctions, nome)
            );
        }
    }

} 