<template>
  <div>
    <div v-if="pergunta.id_tipo_pergunta === 1" class="justify-center">
      <form-consultas-field-tipo-text
        :pergunta="pergunta"
        @resposta="respondido($event)"
      />
    </div>

    <div v-if="pergunta.id_tipo_pergunta === 2">
      <form-consultas-field-tipo-paragrafo
        :pergunta="pergunta"
        @resposta="respondido($event)"
      />
    </div>

    <div v-if="pergunta.id_tipo_pergunta === 3">
      <form-consultas-field-tipo-radio
        :pergunta="pergunta"
        @resposta="respondido($event)"
      />
    </div>

    <div v-if="pergunta.id_tipo_pergunta === 4">
      <form-consultas-field-tipo-check
        :pergunta="pergunta"
        @resposta="respondido($event)"
      />
    </div>

    <div v-if="pergunta.id_tipo_pergunta === 5">
      <h4 class="align-content-start">Cálculo do IMC</h4>
      <v-row>
        <v-col>
          <v-text-field label="Peso(kg)" v-model="peso" />
        </v-col>
        <v-col>
          <v-text-field label="Altura(m)" v-model="altura" />
        </v-col>
        <v-col>
          <v-text-field label="Imc" :disabled="true" readonly v-model="imc" />
        </v-col>
      </v-row>
      <!--      <label>Cálculo IMC</label>-->
      <!--      <form-field-tipo-text :pergunta="pergunta" :disabled="disabled"/>-->
    </div>
    <div v-if="pergunta.id_tipo_pergunta === 6">
      <form-consultas-field-tipo-text
        :pergunta="pergunta"
        @resposta="respondido($event)"
      />
    </div>

    <div v-if="pergunta.id_tipo_pergunta === 7">
      <form-consultas-field-tipo-numeric
        :pergunta="pergunta"
        @resposta="respondido($event)"
      />
    </div>
  </div>
</template>
<script>
import Mixin from "@/mixins/vuex_snackbar_mixin.js";
import FormConsultasFieldTipoText from "@/components/consultas/formulario_consulta_campo_text.vue";
import FormConsultasFieldTipoNumeric from "@/components/consultas/formulario_consulta_campo_numeric.vue";
import FormConsultasFieldTipoParagrafo from "@/components/consultas/formulario_consulta_campo_paragrafo.vue";
import FormConsultasFieldTipoRadio from "@/components/consultas/formulario_consulta_campo_radio.vue";
import FormConsultasFieldTipoCheck from "@/components/consultas/formulario_consulta_campo_check.vue";

export default {
  mixins: [Mixin],
  props: {
    pergunta: Object,
  },
  beforeMount() {
    console.log("this.pergunta");
    console.log(this.pergunta);
  },
  components: {
    FormConsultasFieldTipoText,
    FormConsultasFieldTipoNumeric,
    FormConsultasFieldTipoParagrafo,
    FormConsultasFieldTipoRadio,
    FormConsultasFieldTipoCheck,
  },
  watch: {
    altura() {
      const altura = +`${this.altura}`.replace(/,/g, ".");
      const peso = +`${this.peso}`.replace(/,/g, ".");
      if (!+altura || !+peso) return;
      this.$emit("resposta", {
        id_pergunta: this.pergunta.id,
        resposta: this.pesoAlturaJoined,
        imc: true,
      });
    },
    peso() {
      const altura = +`${this.altura}`.replace(/,/g, ".");
      const peso = +`${this.peso}`.replace(/,/g, ".");
      if (!+altura || !+peso) return;
      this.$emit("resposta", {
        id_pergunta: this.pergunta.id,
        resposta: this.pesoAlturaJoined,
        imc: true,
      });
    },
  },
  computed: {
    imc() {
      const altura = +`${this.altura}`.replace(/,/g, ".");
      const peso = +`${this.peso}`.replace(/,/g, ".");
      const imc = (altura > 0 ? peso / altura ** 2 : 0)
        .toFixed(1)
        .replace(/\./g, ",");
      return imc || 0;
    },
    pesoAlturaJoined() {
      const altura = +`${this.altura}`.replace(/,/g, ".");
      const peso = +`${this.peso}`.replace(/,/g, ".");
      const imc = `${this.imc}`.replace(/,/g, ".");
      return `${altura},${peso},${imc}`;
    },
  },
  data: () => ({
    peso: 0,
    altura: 0,
  }),
  methods: {
    respondido(e) {
      console.log("fill");
      console.log(e);
      this.$emit("resposta", e);
    },
  },
};
</script>
<style scoped>
.v-progress-circular {
  margin: 1rem;
}
</style>
