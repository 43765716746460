import HTTPService from '@/services/http_service.js'

export default class RelatorioService extends HTTPService {
    
  static build() {
    return new RelatorioService();
  }
  async exportMapaCirurgicoPdf(dataInicio, dataFinal) {
    return await this.get(`relatorio/pdf/mapa-cirurgico/${dataInicio}/${dataFinal}`).then(
      (resp) => {
        if (resp.status != 200) return resp;
        return resp.blob();
      }
    );
  }

  async exportMapaCirurgicoExcel(dataInicio, dataFinal) {
    return await this.get(`relatorio/excel/mapa-cirurgico/${dataInicio}/${dataFinal}`).then(
      (resp) => {
        if (resp.status != 200) return resp;
        return resp.blob();
      }
    );
  }

} 