<template>
  <div>
    <p>Atestado Médico</p>
    <p class="indent resp">
      Atesto para devidos fins que <b>{{ nome }}</b> , necessita
      afastar-se de suas atividades laborais por um período de
      <b>{{ dias }}</b> dias, por motivo de procedimento cirúrgico.
    </p>

    <p>CID: {{ codigoCFM }}</p>
    <div class="d-flex flex-column text-end pt-8">
      <p class="ml-auto">
        Fortaleza, {{ gerarData().dia }}<span class="space">_</span>de<span class="space">_</span
      >{{ gerarData().mes }}<span class="space">_</span
      >{{ gerarData().ano }}.
      </p>
      <p class="d-flex flex-column ml-auto">
        <span> _________________________________________</span>
        <span class="ml-1">Luiz Nestor Vasconcelos Rodrigues - CRM 6110 </span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "documento_atestado",
  props: {
    codigoCFM: {required: true},
    nome: {required: true},
    dias: {required: true},
  },
  methods: {
    gerarData() {
      const meses = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novovembro",
        "Dezembro",
      ];
      let data = new Date();
      return {
        dia: data.getDate(),
        mes: meses[data.getMonth()],
        ano: data.getFullYear(),
      };
    },
  }
}
</script>

<style scoped>

</style>
