import HTTPService from '@/services/http_service.js'

export default class CEPService extends HTTPService {
    static build() {
        return new CEPService();
    }
  request ({ onSucess, onError, onEnd }, method, resource, bodyParams, queryParamsObj) {
      if (typeof onSucess !== 'function') {
          throw new Error('onSucess is not a function.')
      }
      if (typeof onError !== 'function') {
          throw new Error('onError is not a function.')
      }
      if (typeof onEnd !== 'function') {
          throw new Error('onEnd is not a function.')
      }
      const headers = new Headers();
      headers.append("Content-Type", "application/x-www-form-urlencoded");

      let url = ''
      if (method === 'GET') {
          const queryParams = queryParamsObj ? this.encodeQueryParams(queryParamsObj) : ''
          url = this.baseURL(resource + queryParams)
      } else {
          url = this.baseURL(resource)
      }
      const redirect = 'follow'
      const config =  { method , redirect}
      if (['POST', 'GET', 'DELETE'].includes(method)) {
          config.headers = headers
      }
      if (method === 'POST' && typeof bodyParams === 'object' && JSON.stringify(bodyParams) !== '{}') {
          const urlencoded = new URLSearchParams();
          Object.keys(bodyParams).forEach(campo => {
              if (bodyParams[campo] instanceof Object) {
                  const objParam = bodyParams[campo]
                  Object.keys(objParam).forEach(nameParam => {
                      urlencoded.append(`${campo}[${nameParam}]`, objParam[nameParam])
                  })
              } else {
                  urlencoded.append(campo, bodyParams[campo])
              }

          })
          config.body = urlencoded
      }
      let request = null
      try {
          request = fetch(url, config)
      } catch (error) {
          request = new Error(error)
      }
      if (request instanceof Error) {
          onError()
      } else {
          request.then(response => {
              const { status } = response
              // if (status === 403) {
              //     this.tratarErroForbidden();
              //     return;
              // }
              response.json()
                  .then(onSucess ? onSucess(status) : () => {})
                  .catch(onError || (() => {}))
                  .finally(onEnd || (() => {}))
          })
          .catch(onError || (() => {}))
          .finally(onEnd || (() => {}))
      }
  }

    baseURL (args) {
        return `https://viacep.com.br/ws/${args}`
    }

    buscaCep (responseFunctions, cep) {
      this.request(responseFunctions, 'GET', `${cep}/json`, null)
    }

    /**
        @param {string} cepSemMascara
        @returns {Promise<{estado, cep, logradouro, complemento, bairro, localidade, uf, ibge, gia, ddd, siafi}>}
     */
    async buscarCep(cepSemMascara) {
        try {
            const response = await this.get(this.baseURL(`${cepSemMascara}/json`));
            if (response.status !== 200) {
                return {estado: '', cep: '', logradouro: '', complemento: '', bairro: '', localidade: '', uf: '', ibge: '', gia: '', ddd: '', siafi: ''}
            }
            const {cep, logradouro, complemento, bairro, localidade, uf, ibge, gia, ddd, siafi} = await response.json();
            const estadosUf = {
                'RO': 'Rondônia',
                'AC': 'Acre',
                'AM': 'Amazonas',
                'RR': 'Roraima',
                'PA': 'Pará',
                'AP': 'Amapá',
                'TO': 'Tocantins',
                'MA': 'Maranhão',
                'PI': 'Piauí',
                'CE': 'Ceará',
                'RN': 'Rio Grande do Norte',
                'PB': 'Paraíba',
                'PE': 'Pernambuco',
                'AL': 'Alagoas',
                'SE': 'Sergipe',
                'BA': 'Bahia',
                'MG': 'Minas Gerais',
                'ES': 'Espírito Santo',
                'RJ': 'Rio de Janeiro',
                'SP': 'São Paulo',
                'PR': 'Paraná',
                'SC': 'Santa Catarina',
                'RS': 'Rio Grande do Sul (*)',
                'MS': 'Mato Grosso do Sul',
                'MT': 'Mato Grosso',
                'GO': 'Goiás',
                'DF': 'Distrito Federal',
            }
            return {
                estado: estadosUf[(uf || '')] || '',
                cep: cep || '',
                logradouro: logradouro || '',
                complemento: complemento || '',
                bairro: bairro || '',
                localidade: localidade || '',
                uf: uf || '',
                ibge: ibge || '',
                gia: gia || '',
                ddd: ddd || '',
                siafi: siafi || ''
            }
        } catch (e) {
            return null;
        }
    }

}
