<template>
  <v-card>
    <v-card-title>
      <span class="headline">Cadastro de pré-agendamento</span>
    </v-card-title>
    <v-card-text>
      <v-container fluid>
        <v-row>
          <v-col cols="6">
            <v-text-field v-model="clienteNome" label="Cliente" />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="clienteTelefone"
              label="Telefone"
              v-mask="'(##)#####-####'"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-card>
              <v-card-title>
                Procedimento
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="procedimentoDataTable.search"
                  append-icon="mdi-magnify"
                  label="Busque pelo nome ou tipo do procedimento"
                  single-line
                  hide-details
                  dense
                ></v-text-field>
              </v-card-title>
              <v-data-table
                v-model="procedimentoDataTable.selected"
                :headers="procedimentoDataTable.headers"
                :items="procedimentos"
                item-key="procedimentoId"
                show-select
                class="elevation-1"
                single-select
                :show-expand="false"
                dense
                :search="procedimentoDataTable.search"
                height="300"
                calculate-widths
                :loading="procedimentoDataTable.loading"
                :footer-props="{ 'items-per-page-options': [5, 20, 50, 100] }"
              />
            </v-card>
          </v-col>

          <v-col cols="6">
            <v-card>
              <v-card-title>
                Horário
              </v-card-title>
              <v-card-text>
                <v-menu
                  ref="menu"
                  v-model="agendaDataTable.menu"
                  :close-on-content-click="false"
                  :return-value.sync="agendaDataTable.filter"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <!-- v-model="agendaDataTable.filter" -->
                    <v-text-field
                      v-model="dataFormatada"
                      label="Data inicial - Data final"
                      prepend-icon="mdi-calendar"
                      clearable
                      v-bind="attrs"
                      v-on="on"
                      @click="limpar()"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="agendaDataTable.filter"
                    no-title
                    range
                    scrollable
                    color="green darken-4"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text @click="agendaDataTable.menu = false">
                      Cancelar
                    </v-btn>
                    <v-btn @click="filterByDataInicialAndDataFinal()">OK</v-btn>
                  </v-date-picker>
                </v-menu>
                <v-data-table
                  v-model="agendaDataTable.selected"
                  :headers="agendaDataTable.headers"
                  :items="agendaDataTable.agendas"
                  item-key="agendaId"
                  show-select
                  class="elevation-1"
                  single-select
                  :show-expand="false"
                  dense
                  height="300"
                  calculate-widths
                  :loading="agendaDataTable.loading"
                  :footer-props="{ 'items-per-page-options': [5, 20, 50, 100] }"
                >
                  <template v-slot:item.data="{ item }">
                    {{ formatarData(item.data) }}
                  </template>
                </v-data-table>
                <!--                  <v-spacer></v-spacer>-->
                <!--                  <v-btn text @click="menu = false"> Cancelar </v-btn>-->
                <!--                  <v-btn class="btn-app-primary" @click="$refs.menu.save(datas)">-->
                <!--                    OK-->
                <!--                  </v-btn>-->
                <!--                </v-date-picker>-->
              </v-card-text>
              <!--              <v-data-table-->
              <!--              />-->
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions v-if="!loading">
      <v-spacer></v-spacer>
      <v-btn color="#3E682A" text @click="$emit('close')"> Cancelar </v-btn>
      <v-btn color="#3E682A" :loading="loading" text @click="salvar">
        Salvar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ProcedimentoService from "../../services/procedimento_service";
import AgendaService from "../../services/agenda_service";
import PreAgendamentoService from "../../services/pre_agendamento_service";
import SnackbarMixin from "@/mixins/vuex_snackbar_mixin.js";
export default {
  mixins: [SnackbarMixin],
  data: () => ({
    agendaId: 0,
    procedimentoId: 0,
    clienteNome: "",
    clienteTelefone: "",
    procedimentos: [],
    procedimentoService: ProcedimentoService.build(),
    preAgendamentoService: PreAgendamentoService.build(),
    agendaService: AgendaService.build(),
    loading: false,
    procedimentoDataTable: {
      singleSelect: true,
      selected: [],
      headers: [
        {
          text: "Procedimento",
          align: "start",
          sortable: true,
          value: "procedimentoDescricao",
        },
        {
          text: "Tipo",
          align: "start",
          sortable: true,
          value: "tipoProcedimentoDescricao",
        },
      ],
      search: "",
      loading: false,
    },
    agendaDataTable: {
      menu: false,
      filter: [],
      singleSelect: true,
      selected: [],
      headers: [
        { text: "Data", align: "start", sortable: true, value: "data" },
        {
          text: "H.Inicio",
          align: "start",
          sortable: true,
          value: "horaInicio",
        },
        { text: "H.Final", align: "start", sortable: true, value: "horaFinal" },
        {
          text: "Tipo procedimento",
          align: "start",
          sortable: true,
          value: "tipoProcedimentoDescricao",
        },
      ],
      loading: false,
      agendas: [],
    },
    dataFormatada: "",
  }),
  async mounted() {
    this.procedimentoDataTable.loading = true;
    const [procedimentos] = await Promise.all([
      this.procedimentoService.getListAsync(),
    ]);
    this.procedimentoDataTable.loading = false;
    this.procedimentos = procedimentos;
    console.log(this.procedimentos);
  },
  methods: {
    async filterByDataInicialAndDataFinal() {
      this.agendaDataTable.menu = false;
      const { filter } = this.agendaDataTable;
      this.dataFormatada = "";
      console.log("filter", filter);
      if (!filter) return;
      filter.sort();
      const dataInicio = filter[0] || filter[1] || "";
      const dataFinal = filter[1] || filter[0] || "";
      this.dataFormatada =
        dataInicio == dataFinal
          ? `${this.formatarData(dataInicio)}`
          : `${this.formatarData(dataInicio)} - ${this.formatarData(
              dataFinal
            )}`;
      this.agendaDataTable.loading = true;
      this.agendaDataTable.agendas = await this.agendaService.getHorariosDisponiveisAsync(
        { dataInicio, dataFinal }
      );
      this.agendaDataTable.loading = false;
    },
    async salvar() {
      const allowRegister =
        this.bodyRequest.clienteTelefone &&
        this.bodyRequest.clienteNome &&
        this.bodyRequest.agendaId &&
        this.bodyRequest.procedimentoId;
      if (!allowRegister) return;
      this.loading = true;
      const success = await this.preAgendamentoService.register(
        this.bodyRequest
      );
      this.loading = false;
      this.$_ACTIONS_showSnackbarMessage({
        message: success
          ? "O pré agendamento foi realizado com sucesso."
          : "Erro.",
        color: success ? "sucess" : "error",
      });
      success && this.$emit("response");
    },
    limpar() {
      this.agendaDataTable.filter = [];
      this.dataFormatada = "";
    },
    formatarData(date) {
      console.log("date", date);
      if (!date) return null;

      const [ano, mes, dia] = date.split("-");
      // return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      return `${dia.padStart(2, "0")}/${mes.padStart(2, "0")}/${ano}`;
    },
  },
  computed: {
    bodyRequest() {
      const clienteNome = this.clienteNome;
      const clienteTelefone = this.clienteTelefone;
      const agendaId = ~~(this.agendaDataTable.selected[0] || {}).agendaId;
      const procedimentoId = ~~(this.procedimentoDataTable.selected[0] || {})
        .procedimentoId;
      return { clienteNome, clienteTelefone, agendaId, procedimentoId };
    },
  },
};
</script>
