import UsuarioService from '@/services/usuario_service.js';
import PacienteService from '@/services/paciente_service.js';
import AssistenteService from '@/services/assistente_service.js';
import VuexMixin from "@/mixins/vuex_mixin.js";
import ValidationService from "@/services/validation_service.js";

export default {
    data: () => ({
        DADOS_CADASTRAIS_MIXIN_loading: false,
    }),
    mixins: [VuexMixin],
    computed: {
        DADOS_CADASTRAIS_MIXIN_srcFotoUsuario() {
            if (!this.$_GETTERS_usuario.dados) {
                return '';
            }
            const tipo = this.$_GETTERS_usuario.dados.tipo;            
            const base64 = this.$_GETTERS_usuario.dados.foto;
            if (/^data:image\//.test(base64)) {
                return base64;
            }
            if (!tipo || !base64) {
                return '';
            }
            const src =  ['png', 'jpeg', 'jpg'].includes(tipo)
                ? {
                    png: `data:image/png;base64,${base64}`,
                    jpeg: `data:image/jpeg;base64,${base64}`,
                    jpg: `data:image/jpeg;base64,${base64}`
                }[tipo]
                : 'https://picsum.photos/id/103/200/300';
            return src;
        },
        DADOS_CADASTRAIS_MIXIN_validationNome () {
            return [
              ValidationService.isNotEmpty('O nome não pode ser vazio.')
            ]
        },
        DADOS_CADASTRAIS_MIXIN_validationTelefone1 () {
            return [
              ValidationService.isNotEmpty('O telefone não pode ser vazio.'),
              ValidationService.isTelefone('Informe um telefone válido.')
            ]
        },
        DADOS_CADASTRAIS_MIXIN_validationTelefone2 () {
            return [
              ValidationService.isTelefone('Informe um telefone válido.')
            ]
        },
        DADOS_CADASTRAIS_MIXIN_validationCPF () {
            return [
              ValidationService.isNotEmpty('O CPF não pode ser vazio.'),
              ValidationService.isCPF()
            ]
        },
        DADOS_CADASTRAIS_MIXIN_validationRG () {
            return [
              ValidationService.isNotEmpty('O RG não pode ser vazio.')
            ]
        },
        DADOS_CADASTRAIS_MIXIN_validationDataNascimento () {
            return [
              ValidationService.isNotEmpty('A data de nascimento não pode ser vazia'),
            ]
        },
        DADOS_CADASTRAIS_MIXIN_validationCEP () {
            return [
              ValidationService.isNotEmpty('O CEP não pode ser vazio'),
              ValidationService.isCEP(),
            ]
        },
        DADOS_CADASTRAIS_MIXIN_validationNumero () {
            return [
              ValidationService.isNotEmpty('O número não pode ser vazio'),
            ]
        }
    },
    methods: {
        DADOS_CADASTRAIS_MIXIN_getDadosCadastrais () {
            const usuarioService = new UsuarioService();
            this.DADOS_CADASTRAIS_MIXIN_loading = true;
            const id = this.$_GETTERS_usuario.id;

            usuarioService.getUsuarioData(
            {
                onSucess: status => body => {
                    this.DADOS_CADASTRAIS_MIXIN_loading = false;
                    if (status === 200) {
                        const dados = {
                            bairro: body.data.bairro,
                            cep: body.data.cep,
                            cidade: body.data.cidade,
                            complemento: body.data.complemento,
                            cpf: body.data.cpf,
                            data_nascimento: body.data.data_nascimento,
                            estado: body.data.estado,
                            foto: body.data.foto,
                            genero: body.data.genero,
                            logradouro: body.data.logradouro,
                            nome: body.data.nome,
                            numero: body.data.numero,
                            referencias: body.data.referencias,
                            rg: body.data.rg,
                            telefone_1: body.data.telefone_1,
                            telefone_2: body.data.telefone_2,
                            tipo: body.data.tipo.toLowerCase()
                        };
                        this.$_ACTIONS_setUsuario({ id, dados });
                        if (this.$_GETTER_perfil.description === 'paciente') {
                            this.DADOS_CADASTRAIS_MIXIN_getPacienteIDs({ id, dados })                   
                        } else if (this.$_GETTER_perfil.description === 'assistente') {
                            this.DADOS_CADASTRAIS_MIXIN_getAssistenteIDs({ id, dados })
                        }
                    } else {
                        this.$_ACTIONS_showSnackbarMessage({
                            message: body.message || this.$global.messages.internalServerError,
                            color: "error",
                        });
                    }
                },
                onError: error => {
                    console.error(error)
                    this.$_ACTIONS_showSnackbarMessage({
                        message: this.$global.messages.internalServerError,
                        color: "error",
                    });
                },
                onEnd: () => {
                    this.DADOS_CADASTRAIS_MIXIN_loading = false;
                }
            }, id);
        },

        

        DADOS_CADASTRAIS_MIXIN_updateDadosCadastraisFile (data) {
            const usuarioService = new UsuarioService();
            this.DADOS_CADASTRAIS_MIXIN_loading = true
            const id = this.$_GETTERS_usuario.id;
            usuarioService.updateUsuarioDataFile(
            {
                onSucess: status => body => {
                    console.log(status)
                    console.log(body)
                    this.$_ACTIONS_showSnackbarMessage({
                        message: body.message || this.$global.messages.internalServerError,
                        color: status ? 'sucess' : 'error',
                    });
                    let usuario = Object.assign({}, this.$_GETTERS_usuario)
                    usuario = Object.assign(usuario, data);
                    this.$_ACTIONS_setUsuario(usuario)
                },
                onError: error => {
                    console.error(error)
                    this.$_ACTIONS_showSnackbarMessage({
                        message: this.$global.messages.internalServerError,
                        color: "error",
                    });
                },
                onEnd: () => {
                    this.DADOS_CADASTRAIS_MIXIN_loading = false;
                    this.DADOS_CADASTRAIS_MIXIN_getDadosCadastrais();
                }
            }, id, data);
        },

        DADOS_CADASTRAIS_MIXIN_updateDadosCadastrais (data) {
            const usuarioService = new UsuarioService();
            this.DADOS_CADASTRAIS_MIXIN_loading = true
            const id = this.$_GETTERS_usuario.id;
            usuarioService.updateUsuarioData(
            {
                onSucess: status => body => {
                    console.log(status)
                    console.log(body)
                    this.$_ACTIONS_showSnackbarMessage({
                        message: body.message || this.$global.messages.internalServerError,
                        color: status ? 'sucess' : 'error',
                    });
                    let usuario = Object.assign({}, this.$_GETTERS_usuario)
                    usuario = Object.assign(usuario, data);
                    this.$_ACTIONS_setUsuario(usuario)
                },
                onError: error => {
                    console.error(error)
                    this.$_ACTIONS_showSnackbarMessage({
                        message: this.$global.messages.internalServerError,
                        color: "error",
                    });
                },
                onEnd: () => {
                    this.DADOS_CADASTRAIS_MIXIN_loading = false;
                }
            }, id, data);
        },
        DADOS_CADASTRAIS_MIXIN_getPacienteIDs ({id, dados}) {
            const pacienteService = new PacienteService();
            this.DADOS_CADASTRAIS_MIXIN_loading = true;
            pacienteService.getPacienteByUsuarioID(
            {
                onSucess: status => body => {
                    if (status === 200) {
                        const idPaciente = body.data.id;
                        const idCliente = body.data.id_cliente;
                        this.$_ACTIONS_setUsuario({id, dados, idPaciente, idCliente});
                    } else {
                        this.$_ACTIONS_showSnackbarMessage({
                            message: body.message || this.$global.messages.internalServerError,
                            color: "error",
                        });
                    }
                    this.DADOS_CADASTRAIS_MIXIN_loading = false;
                },
                onError: error => {
                    console.error(error)
                    this.$_ACTIONS_showSnackbarMessage({
                        message: this.$global.messages.internalServerError,
                        color: "error",
                    });
                    this.DADOS_CADASTRAIS_MIXIN_loading = false;
                },
                onEnd: () => {
                    this.DADOS_CADASTRAIS_MIXIN_loading = false;
                }
            }, this.$_GETTERS_usuario.id);
        },
        DADOS_CADASTRAIS_MIXIN_getAssistenteIDs ({id, dados}) {
            const assistenteService = new AssistenteService();
            this.DADOS_CADASTRAIS_MIXIN_loading = true;
            assistenteService.getAssistenteByIdUsuario(
            {
                onSucess: status => body => {
                    if (status === 200) {
                        const idAssistente = body.data.id;
                        this.$_ACTIONS_setUsuario({id, dados, idAssistente});
                    } else {
                        this.$_ACTIONS_showSnackbarMessage({
                            message: body.message || this.$global.messages.internalServerError,
                            color: "error",
                        });
                    }
                    this.DADOS_CADASTRAIS_MIXIN_loading = false;
                },
                onError: error => {
                    console.error(error)
                    this.$_ACTIONS_showSnackbarMessage({
                        message: this.$global.messages.internalServerError,
                        color: "error",
                    });
                    this.DADOS_CADASTRAIS_MIXIN_loading = false;
                },
                onEnd: () => {
                    this.DADOS_CADASTRAIS_MIXIN_loading = false;
                }
            }, this.$_GETTERS_usuario.id);
        },
    }
}

