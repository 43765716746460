<template>
  <div>
    <div class="p-lg-3">
      <div class="row" justify-content-between>
        <h3 class="mt-0 mb-2 font-weight-bold text-left">
          Relatório de Mapa Cirúrgico
        </h3>
      </div>
    </div>
    <v-row align="center" class="my-3">
      <v-col class="d-flex col-3">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="datas"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              outlined
              v-model="computedFormatDate"
              label="Data inicial - Data final"
              prepend-icon="mdi-calendar"
              clearable
              v-bind="attrs"
              v-on="on"
              @click:clear="datas = null"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="datas"
            no-title
            range
            scrollable
            color="green darken-4"
          >
            <v-spacer></v-spacer>
            <v-btn text @click="menu = false"> Cancelar </v-btn>
            <v-btn dark color="success" @click="$refs.menu.save(datas)">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col class="d-flex">
        <v-speed-dial
          v-model="fab"
          :direction="direction"
          :open-on-hover="hover"
          :transition="transition"
        >
          <template v-slot:activator>
            <v-btn
              class="mb-7 btnWid"
              style="width: 9rem; height: 3.2rem"
              dark
              color="primary"
            >
              <v-icon v-if="fab"> mdi-close </v-icon>
              <span v-else> Exportar </span>
            </v-btn>
          </template>

          <div class="mt-n5">
            <v-btn dark color="green" class="btnWid" @click="pdfRelatorio">
              PDF
            </v-btn>
            <v-btn dark color="indigo" class="btnWid" @click="excelRelatorio">
              EXCEL
            </v-btn>
          </div>
        </v-speed-dial>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Mixin from "@/mixins/vuex_mixin.js";
import RelatorioService from "../services/relatorio_service";

export default {
  mixins: [Mixin],
  data() {
    return {
      datas: null,
      menu: false,
      unidades: [],
      unidadeSelected: "",
      loadingPdf: false,
      direction: "bottom",
      fab: false,
      hover: false,
      services: {
        relatorioService: RelatorioService.build(),
      },
      options: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 15,
        ordenacao: false,
        desc: false,
      },
    };
  },
  beforeMount() {
    this.setUnidades();
  },
  computed: {
    computedFormatDate() {
      const datasFormatadas =
        this.datas &&
        this.datas.map((item) =>
          item
            .split("-")
            .reverse()
            .join("/")
        );
      return datasFormatadas;
    },
  },
  methods: {
    pdfRelatorio() {
      this.loadingPdf = true;
      const dataInicial = this.datas ? this.datas[0].split("-").join("") : "";
      const dataFinal = this.datas ? this.datas[1].split("-").join("") : "";

      if (!dataInicial || !dataFinal) {
        this.$_ACTIONS_showSnackbarMessage({
          message: "Preencha os campos corretamente",
          color: "error",
        });
        this.loadingPdf = false;
        return;
      }

      this.services.relatorioService
        .exportMapaCirurgicoPdf(dataInicial, dataFinal)
        .then((resp) => {
          console.log(resp);
          if (resp.status) {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Falha ao carregar",
              color: "error",
            });
            return;
          }
          this.$_ACTIONS_showSnackbarMessage({
            message: "Dados encontrados com sucesso! Gerando PDF...",
            color: "sucess",
          });
          let link = document.createElement("a");
          // link.target = "_blank";
          link.download = `${dataInicial}-${dataFinal}.pdf`;
          let url = window.URL.createObjectURL(resp);
          link.href = url;
          link.click();
        })
        .catch(() => {
          this.$_ACTIONS_showSnackbarMessage({
            message: "Falha ao carregar",
            color: "error",
          });
        })
        .finally(() => {
          this.loadingPdf = false;
        });
    },
    excelRelatorio() {
      this.loadingPdf = true;
      const dataInicial = this.datas ? this.datas[0].split("-").join("") : "";
      const dataFinal = this.datas ? this.datas[1].split("-").join("") : "";

      if (!dataInicial || !dataFinal) {
        this.$_ACTIONS_showSnackbarMessage({
          message: "Preencha os campos corretamente",
          color: "error",
        });
        this.loadingPdf = false;
        return;
      }

      this.services.relatorioService
        .exportMapaCirurgicoExcel(dataInicial, dataFinal)
        .then((resp) => {
          console.log(resp);
          if (resp.status) {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Falha ao carregar",
              color: "error",
            });
            return;
          }
          this.$_ACTIONS_showSnackbarMessage({
            message: "Dados encontrados com sucesso! Gerando PDF...",
            color: "sucess",
          });
          let link = document.createElement("a");
          // link.target = "_blank";
          link.download = `${dataInicial}-${dataFinal}.xlsx`;
          let url = window.URL.createObjectURL(resp);
          link.href = url;
          link.click();
        })
        .catch(() => {
          this.$_ACTIONS_showSnackbarMessage({
            message: "Falha ao carregar",
            color: "error",
          });
        })
        .finally(() => {
          this.loadingPdf = false;
        });
    },
  },
};
</script>

<style></style>
