import TipoProcedimentoService from "@/services/tipo_procedimento_service";

export default {
    data: () => ({
        TIPO_PROCEDIMENTO_MIXIN_tipos_procedimento: [],
        TIPO_PROCEDIMENTO_MIXIN_tipo_procedimento: '',
        TIPO_PROCEDIMENTO_MIXIN_loading: false,
    }),
    methods: {
        TIPO_PROCEDIMENTO_MIXIN_atualizarLista() {
            this.TIPO_PROCEDIMENTO_MIXIN_loading = true
            const tipoProcedimentoService = new TipoProcedimentoService();
            tipoProcedimentoService.getTipoProcedimentos({
                onSucess: status => body => {
                    if (status === 200) {
                        this.TIPO_PROCEDIMENTO_MIXIN_tipos_procedimento = body.data
                    } else {
                        console.error(body)
                    }
                },
                onError: (error) => {
                    console.error(error)
                },
                onEnd: () => {
                    this.TIPO_PROCEDIMENTO_MIXIN_loading = false
                }
            })
        },
    },
}