<template>
  <div class="d-flex col-12">
    <label>{{ pergunta.texto_pergunta }}</label>
    <v-textarea
      class="col-10"
      v-if="pergunta.obrigatorio"
      v-model="resposta.resposta"
      :rules="regras"
      :disabled="!disabled"
      @change="watchChange"
      rows="2"
      @blur="respondido"
      :readonly="!disabled"
    ></v-textarea>
    <v-textarea
      class="col-10"
      v-else
      v-model="resposta.resposta"
      :disabled="!disabled"
      @change="watchChange"
      rows="1"
      @blur="respondido"
      :readonly="!disabled"
    ></v-textarea>
  </div>
</template>
<script>
import Mixin from "@/mixins/vuex_snackbar_mixin.js";

export default {
  mixins: [Mixin],
  props: {
    pergunta: Object,
    disabled: Boolean,
  },
  beforeMount() {
    this.resposta = {
      id_pergunta: this.pergunta.id,
      resposta: this.pergunta.resposta_pergunta_textual,
    };
  },
  data: () => ({
    resposta: {},
    regras: [(value) => !!value || "Obrigatório."],
  }),
  watch: {},
  methods: {
    respondido() {
      this.$emit("resposta", this.resposta);
    },
    watchChange() {
      this.$emit("rchange", this.resposta.resposta);
    },
  },
};
</script>
<style scoped>
.v-progress-circular {
  margin: 1rem;
}
</style>
