<template>
  <v-card>
    <v-toolbar style="background-color: #3e682a; color: #fff">
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        <span class="headline">Visualizar paciente</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items> </v-toolbar-items>
    </v-toolbar>

    <v-card-text>
      <v-container>
        <div class="row">
          <div class="col-lg-12">
            <div class="row">
              <v-card-text>
                <div>
                  <strong style="font-size: 18px">Nome:</strong>
                  {{ detalhes.nome }}
                </div>
                <div>
                  <strong style="font-size: 18px">Telefone:</strong>
                  {{ detalhes.telefone }}
                </div>
              </v-card-text>
            </div>
          </div>
        </div>
      </v-container>
    </v-card-text>
  </v-card>
</template>


<script>
export default {
  props: {
    detalhes: Object,
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    fecharModal() {
      this.dialog = false;
    },
  },
};
</script>


<style scoped>
strong {
  font-size: 12pt;
  font-weight: bold;
  color: #000000;
}
</style>