<template>
  <div>
    <v-card min-height="100vh">
      <v-toolbar style="background-color: #3e682a; color: #fff">
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          <span class="headline">Solicitar Exames</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <div v-if="!loading">
            <div v-if="exames.length < 1">
                <p class="text-center">Nenhum registro encontrado</p>
            </div>
            <div v-else
              v-for="(opt_check, i) in exames"
              :key="i"
            >
              <div class="d-flex">
                <v-checkbox
                  v-model="selecionados"
                  :label="opt_check.nome"
                  :value="opt_check.nome"
                ></v-checkbox>
              </div>
            </div>
          </div>
          <div v-else>
            <v-progress-circular
              indeterminate
              color="green"
            ></v-progress-circular>
          </div>
          <div class="d-flex flex-column">
            <v-text-field
              v-model="obs"
              label="Observação 1"
              class="col-8"
            ></v-text-field>
            <v-textarea
              label="Observação 2"
              class="col-8"
              v-model="obs2"
              rows="2"
            ></v-textarea>
          </div>
        </v-container>
         <div class="row">
          <div class="col-md-12 text-right d-flex justify-lg-space-between">
            <button
              class="btn btn-cancelar btn-padding font-weight-bold text-gray"
               @click="$emit('close')"
            >
              Cancelar
            </button>
            <button
              class="btn btn-app-primary btn-padding font-weight-bold"
              @click="gerarPdf()"
            >
              <span> Gerar PDF </span>
            </button>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ExameService from '@/services/exame_service.js'

export default {
  beforeMount() {
      console.log("loading1")
    this.listaExames()
  },
  data: () => ({
   selecionados:[],
   obs:"avaliação pré-operatório",
   obs2:"",
   exames:[],
   loading: false,
  }),
  methods: {
    listaExames(){
      this.loading = true
      const exameService = new ExameService()
      this.exames=[];
      const onSucess = (status) => (body) => {
        if (status === 200) {
          console.log("200 data")
          console.log(body.data)
          this.exames = body.data.exames
        } else {
          console.log(body);
        }
        };
      const onError = (error) => {
        console.log(error);
      };
      const onEnd = () => {
        console.log("aqui")
        console.log(this.exames)
        this.loading = false;
      };
      exameService.getList({onSucess, onError, onEnd});

    },
    gerarPdf() {
      console.log({s: this.selecionados});
      if (this.selecionados.length < 1) {
        console.log("Faltou os exames");
        return;
      }
      this.$emit("gerarPDF", {
        doc:"exames",
        exames: this.selecionados,
        obs: this.obs,
        obs2: this.obs2
      });
      this.selecionados = [];
      this.obs = "avaliação pré-operatório"
      this.obs2 = ""
    },
  },
};
</script>
