import HTTPService from "@/services/http_service.js";

export default class AgendaService extends HTTPService {
  resource = "agenda";
  static build() {
    return new AgendaService();
  }

  async getHorariosDisponiveisAsync({ dataInicio, dataFinal }) {
    try {
      const response = await this.get(`agenda/medico/1`, {
        data_inicio: dataInicio,
        data_fim: dataFinal,
        sort: "+",
      });
      if (response.status !== 200) return [];
      const { data } = await response.json();
      return data.agendas
        .map((agenda) => ({
          agendaId: agenda.id,
          data: agenda.data,
          horaInicio: agenda.hora_inicio,
          horaFinal: agenda.hora_final,
          tipoProcedimentoId: agenda.id_tipo_procedimento,
          tipoProcedimentoDescricao: agenda.tipo_procedimento,
          agendamentoId: agenda.id_agendamento,
          pacienteId: agenda.id_paciente,
          clienteId: agenda.id_cliente,
          procedimentoId: agenda.id_procedimento,
          procedimentoDescricao: agenda.procedimento,
          canceladoEm: agenda.cancelado_em,
          consultaId: agenda.id_consulta,
          pacienteNome: agenda.nome_paciente,
          preAgendamentoId: agenda.pre_agendamento_id,
          statusConsulta: agenda.confirmado_st,
        }))
        .filter((agenda) => {
          return (
            1 &&
            !agenda.agendamentoId &&
            !agenda.pacienteId &&
            !agenda.clienteId &&
            !agenda.preAgendamentoId &&
            // !agenda.canceladoEm
            agenda.canceladoEm != "0000-00-00 00:00:00" &&
            agenda.canceladoEm != "1900-01-01 00:00:00"
          );
        });
    } catch (e) {
      return [];
    }
  }

  getAgendaList(responseFunctions, idMedico, options) {
    this.request(
      responseFunctions,
      "GET",
      this.resource + "/medico/" + idMedico,
      null,
      options
    );
  }

  getAgendaMedico(
    responseFunctions,
    idMedico,
    paginationComponent,
    filtro = {}
  ) {
    const pagination = paginationComponent
      ? {
          page: paginationComponent.page,
          per_page: paginationComponent.itemsPerPage,
        }
      : {};
    this.request(
      responseFunctions,
      "GET",
      this.resource + "/medico/" + idMedico,
      null,
      { ...pagination, ...filtro }
    );
  }
  getHorariosDisponiveisFromDate(responseFunctions, options) {
    this.request(
      responseFunctions,
      "GET",
      this.resource + "/disponivel/date",
      null,
      options
    );
  }

  agendar(responseFunctions, request) {
    const uri = "agendamento";
    this.request(responseFunctions, "POST", uri, request);
  }

  agendar_paciente(responseFunctions, request) {
    const uri = "agendamento/cliente";
    this.request(responseFunctions, "POST", uri, request);
  }

  criarHorario(responseFunctions, req) {
    this.request(responseFunctions, "POST", this.resource, req);
  }

  criarAgenda(responseFunctions, req) {
    this.request(responseFunctions, "POST", this.resource, req);
  }

  criarAgendaMultiplo(responseFunctions, params) {
    this.request(
      responseFunctions,
      "POST",
      this.resource + "/multiplos",
      params
    );
  }

  atualizarTipoProcedimentoDeAgenda(
    responseFunctions,
    idTipoProcedimento,
    agendaID
  ) {
    const url = `${this.resource}/${agendaID}`;
    this.request(responseFunctions, "POST", url, {
      id_tipo_procedimento: idTipoProcedimento,
      alterado_por: 1,
    });
  }

  excluirAgenda(responseFunctions, agendaID) {
    // ESTÁ SENDO UTILIZADO POST PARA EXCLUSÃO PORQUE A UMBLER INFORMOU ERRO DE CORS AO USAR O MÉTODO DELETE. CORRIGIR.
    const url = `${this.resource}/${agendaID}/deletar`;
    this.request(responseFunctions, "POST", url);
  }
}
