<template>
  <v-card :elevation="0">
    <v-form ref="form" class="mt-4">
      <div class="col-12 row">
        <!-- <v-text-field
                    v-model="tipo_procedimento"
                    label="Tipo procedimento"
                ></v-text-field> -->
        <div class="col-4">
          <v-autocomplete
            v-model="procedimento"
            :items="procedimentos"
            :loading="loading_procedimento"
            hide-no-data
            placeholder=""
            background-color="white"
            :search-input.sync="search"
            label="Procedimento"
            item-text="descricao"
            item-value="id"
            color="black"
            solo
          >
          </v-autocomplete>
        </div>
        <div class="col-4">
          <v-autocomplete
            v-model="paciente"
            :items="pacientes"
            :loading="loading_paciente"
            hide-no-data
            placeholder=""
            background-color="white"
            :search-input.sync="search_paciente"
            label="Paciente"
            item-text="nome"
            item-value="id"
            color="black"
            solo
          >
          </v-autocomplete>
        </div>

        <div class="col-2">
          <v-text-field v-model="data" label="Data" type="date"></v-text-field>
        </div>
      </div>
      <div class="col-12 row justify-end">
        <div class="d-flex flex-lg-row justify-content-center col-4">
          <v-btn color="success" class="mr-4" @click="abrirModal()">
            Mapa Cirurgico
          </v-btn>

          <v-btn
            :disabled="permiteFiltro"
            color="teal darken-1"
            class="mr-4"
            style="color: white !important"
            @click="reset"
          >
            Limpar Filtro
          </v-btn>

          <v-btn
            :disabled="permiteFiltro"
            color="success"
            class="mr-4"
            @click="filtrar"
          >
            Filtrar
          </v-btn>
        </div>
      </div>
    </v-form>

    <v-dialog v-model="modalMapaCirurgico" max-width="500px">
      <modal-mapa-cirurgico
        v-if="modalMapaCirurgico"
        @closeModal="closeModal"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import ProcedimentoService from "@/services/procedimento_service.js";
import PacienteService from "@/services/paciente_service.js";
import ModalMapaCirurgico from "./modal_mapa_cirurgico.vue";

export default {
  components: { ModalMapaCirurgico },
  data: () => ({
    tipo_procedimento: "",
    paciente: "",
    data: "",
    search: "",
    search_paciente: "",
    loading_procedimento: false,
    loading_paciente: false,
    procedimentos: [],
    pacientes: [],
    modalMapaCirurgico: false,
  }),
  watch: {
    search(descricao) {
      const procedimentoService = new ProcedimentoService();
      procedimentoService.searchByDescricao(this)(descricao);
    },
    search_paciente(nome) {
      // const pacienteService = new PacienteService();
      // pacienteService.searchByNome(this)(nome)
      if (!nome) {
        this.pacientes = [];
        this.paciente = "";
      }
      if (!nome || !(nome = nome.trim()) || nome.length < 3 || this.loading) {
        return;
      }
      this.loading = true;
      this.pacientes = [];
      const pacienteService = new PacienteService();
      pacienteService.getPacienteByName(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.pacientes = body.data.pacientes;
            } else {
              this.pacientes = [];
            }
          },
          onError: (error) => {
            console.error(error);
            this.pacientes = [];
          },
          onEnd: () => {
            this.loading = false;
          },
        },
        nome
      );
    },
  },
  computed: {
    permiteFiltro() {
      return (
        this.tipo_procedimento == "" &&
        this.procedimento == "" &&
        this.paciente == "" &&
        this.data == ""
      );
    },
  },
  methods: {
    filtrar() {
      console.log("opa, filtrando...");
      console.log(this.procedimento);
      console.log(this.paciente);
      console.log(this.data);
      this.$emit("filtro", [this.procedimento, this.paciente, this.data]);
    },
    reset() {
      this.$refs.form.reset();
      this.$emit("limparFiltro");
    },
    abrirModal() {
      this.modalMapaCirurgico = true;
    },
    closeModal() {
      this.modalMapaCirurgico = false;
    },
  },
};
</script>