<template>
  <div>
    <v-dialog
      v-model="dialogAdicionarImages"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <ModalInputFotos
        :paciente="paciente"
        @close="dialogAdicionarImages = false"
        v-if="dialogAdicionarImages"
        >TESTE</ModalInputFotos
      >
    </v-dialog>
    <v-card min-height="100vh">
      <v-toolbar style="background-color: #3e682a; color: #fff">
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          <span class="headline">Galeria</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <div class="row">
            <!-- FOTOS DE ANTES -->
            <div class="col-lg-4">
              <h3>Antes</h3>
              <div class="card shadow-none">
                <p>Arraste e solte a foto aqui</p>
                <draggable class="list-group" :list="fotoAntes" group="people">
                  <div
                    class="list-group-item"
                    v-for="(foto, i) in fotoAntes"
                    :key="i"
                  >
                    <v-img
                      :src="`${foto.imagem}`"
                      height="150"
                      class="text-right pa-2"
                      contain
                    ></v-img>
                  </div>
                </draggable>
              </div>
            </div>

            <!-- FOTOS DE DEPOIS -->

            <div class="col-lg-4">
              <h3>Depois</h3>
              <div class="card shadow-none">
                <p>Arraste e solte a foto aqui</p>
                <draggable class="list-group" :list="fotoDepois" group="people">
                  <div
                    class="list-group-item"
                    v-for="(foto, i) in fotoDepois"
                    :key="i"
                  >
                    <v-img
                      :src="`${foto.imagem}`"
                      height="150"
                      class="text-right pa-2"
                      contain
                    ></v-img>
                  </div>
                </draggable>
              </div>
            </div>

            <div class="col-lg-4">
              <h3 class="mb-3">Descrição:</h3>
              <p>Adicione uma observação</p>
              <v-textarea v-model="descricao" outlined rows="3"></v-textarea>
              <v-btn
                color="green darken-3"
                small
                dark
                :loading="loadingClassificar"
                @click="classificar_fotos"
              >
                <span> Classificar </span>
              </v-btn>
            </div>

            <!-- GALERIA GERAL -->
            <div class="col-lg-12">
              <h3>Todas as fotos</h3>
              <div></div>

              <v-btn
                class="buttonAddImagens"
                elevation="2"
                @click="dialogAdicionarImages = true"
                >Adicionar imagens</v-btn
              >
              <div class="mt-8" v-if="galeriaFotos.length > 0">
                <v-item-group v-model="selected">
                  <draggable class="row" :list="galeriaFotos" group="people">
                    <div
                      class="col-lg-3"
                      v-for="(foto, i) in galeriaFotos"
                      :key="i"
                    >
                      <v-item>
                        <!-- <v-dialog
                            v-model="fotoFullScreen"
                            fullscreen
                            transition="dialog-bottom-transition"
                        >
                          <template v-slot:activator="{ on, attrs }"> -->
                        <!--                            <v-btn-->
                        <!--                                color="primary"-->
                        <!--                                dark-->
                        <!--                                v-bind="attrs"-->
                        <!--                                v-on="on"-->
                        <!--                            >-->
                        <!--                              Open Dialog-->
                        <!--                            </v-btn>-->
                        <v-img
                          lazy-src="https://picsum.photos/id/11/10/6"
                          :src="foto.imagem"
                          height="150"
                          class="text-right pa-2"
                          style="border: 3px solid transparent; cursor: pointer"
                        >
                          <v-btn
                            @click="dialogConfirmDeletar(foto.id)"
                            class="ml-auto"
                            small
                            icon
                          >
                            <v-hover v-slot="{ hover }">
                              <v-icon
                                medium
                                :color="hover ? '#F44336' : 'gray'"
                              >
                                fas fa-times</v-icon
                              >
                            </v-hover>
                          </v-btn>
                          <v-btn
                            @click="mostrarFotoFullScreen(foto)"
                            class="ml-auto"
                            small
                            icon
                          >
                            <v-hover v-slot="{ hover }">
                              <v-icon medium :color="hover ? 'blue' : 'gray'">
                                fas fa-solid fa-expand</v-icon
                              >
                            </v-hover>
                          </v-btn>
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="grey lighten-5"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                        <!-- </template> -->
                        <!-- 
                          <v-card class="pa-8 justify-center">
                            <v-img
                                lazy-src="https://picsum.photos/id/11/10/6"
                                :src="foto.imagem"
                                class="pa-2 align-content-center"
                                max-height="90%"
                                max-width="90%"
                                style="border: 3px solid transparent; cursor: pointer"
                                @click="fotoFullScreen = !fotoFullScreen"
                            />
                          </v-card> -->
                        <!-- </v-dialog> -->
                      </v-item>
                      <p>{{ foto.criado_em }}</p>
                    </div>
                  </draggable>
                </v-item-group>
              </div>
              <div v-else>
                <p v-if="!buscando" class="text-center">
                  Nenhuma foto encontrada
                </p>
                <v-progress-circular
                  v-else
                  indeterminate
                  color="green"
                ></v-progress-circular>
              </div>
            </div>
          </div>
        </v-container>
        <ConfirmationDialog
          v-if="confirmDeletar"
          :enabled="confirmDeletar"
          :dialogColor="'error'"
          :title="'Deletar Foto'"
          :confirmationMessage="'Você tem certeza que deseja deletar a foto?'"
          :confirmationButtonMessage="'Confirmar'"
          :abortButtonMessage="'Cancelar'"
          :confirmationButtonColor="'error'"
          @abort="confirmDeletar = false"
          @confirm="deletarClassificacao"
        ></ConfirmationDialog>
        <v-dialog v-model="dialogLoading" hide-overlay persistent width="300">
          <v-card color="white">
            <v-card-text>
              Carregando...
              <v-progress-linear
                indeterminate
                color="green lighten-3"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-card-text>
    </v-card>

    <!--   <v-dialog
        v-model="fotoFullScreen"
        fullscreen
        transition="dialog-bottom-transition"
    >
&lt;!&ndash;      <v-card class="pa-8 justify-center">
        <v-img
            lazy-src="https://picsum.photos/id/11/10/6"
            :src="fotoFullScreenRef.imagem"
            class="pa-2 align-content-center"
            max-height="90%"
            max-width="90%"
            style="border: 3px solid transparent; cursor: pointer"
            @click="toogleFotoFullScreen"
        />
      </v-card>&ndash;&gt;



    </v-dialog>-->

    <v-app id="inspire">
      <v-row justify="center">
        <v-dialog
          v-model="dialog"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar dark color="green darken-4">
              <v-btn icon dark @click="dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Detalhe da Foto</v-toolbar-title>
            </v-toolbar>
            <div
              class="d-flex flex-column justify-space-between align-center mt-4"
            >
              <v-slider
                v-model="width"
                class="align-self-stretch"
                min="200"
                max="1000"
                step="1"
              ></v-slider>

              <v-img :width="width" :src="fotoFullScreenRef.imagem"></v-img>
            </div>
            <v-divider></v-divider>
          </v-card>
        </v-dialog>
      </v-row>
    </v-app>
  </div>
</template>

<script>
import Mixin from "@/mixins/vuex_snackbar_mixin.js";
import FotoService from "@/services/fotos_service.js";
import draggable from "vuedraggable";
import ConfirmationDialog from "@/components/dialogs/confirmation";
import ModalInputFotos from "./modal_input_fotos.vue";

export default {
  components: {
    draggable,
    ConfirmationDialog,
    ModalInputFotos,
  },
  props: {
    paciente: Object,
  },
  mixins: [Mixin],
  beforeMount() {
    this.carragarFotos();
  },
  data: () => ({
    fotoFullScreen: false,
    fotoFullScreenRef: "",
    loadingClassificar: false,
    buscando: false,
    addFoto: true,
    dialogLoading: false,
    foto: null,
    obs: "",
    preview: "",
    galeriaFotos: [],
    selected: [],
    descricao: "",
    criado_em: "",
    deletedItem: "",
    confirmDeletar: false,
    //Antes e Depois
    fotoAntesSelecionada: {},
    fotoDepoisSelecionada: {},
    fotoAntes: [],
    fotoDepois: [],
    modelGaleriaAntes: null,
    modelGaleriaDepois: null,
    width: 300,
    dialog: false,
    dialogAdicionarImages: false,
    paciente: {
      /* seus dados de paciente */
    },
  }),
  methods: {
    mostrarFotoFullScreen(foto) {
      this.fotoFullScreenRef = foto;
      this.toogleFotoFullScreen();
    },
    toogleFotoFullScreen() {
      this.dialog = true;
    },
    clean() {
      this.addFoto = true;
    },
    onChange() {
      const file = document.querySelector("input[type=file]").files[0];
      const reader = new FileReader();
      reader.onloadend = (e) => {
        this.preview = e.target.result;
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    },
    enviarFoto() {
      const reader = new FileReader();
      reader.onloadend = (e) => {
        this.salvarFoto(e);
      };
      if (this.foto) {
        reader.readAsDataURL(this.foto);
      }
    },
    salvarFoto(e) {
      if (this.obs == "") {
        this.$_ACTIONS_showSnackbarMessage({
          message: "Campo observação é obrigatório.",
          color: "error",
        });
        return;
      }
      const fotoService = new FotoService();
      this.dialogLoading = true;
      const params = {
        id_clinica: 1,
        id_paciente: this.paciente.id_paciente,
        id_medico: 1,
        descricao: this.obs,
        image: e.target.result,
      };
      fotoService.register(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.carragarFotos();
              this.$_ACTIONS_showSnackbarMessage({
                message: body.message,
                color: "sucess",
              });
            }
          },
          onError: (error) => {
            console.log(error);
          },
          onEnd: () => {
            this.dialogLoading = false;
            this.foto = null;
            this.addFoto = true;
            this.preview = "";
            this.obs = "";
          },
        },
        params
      );
    },
    carragarFotos() {
      this.buscando = true;
      this.galeriaFotos = [];
      const usuario = {
        paciente: this.paciente.id_paciente,
      };
      const fotoService = new FotoService();
      fotoService.buscar_fotos_nao_classificadas(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              body.data.galeria.forEach((foto) => {
                const img = {
                  descricao: foto.descricao,
                  id: foto.id,
                  id_clinica: foto.id_clinica,
                  id_medico: foto.id_medico,
                  id_paciente: foto.id_paciente,
                  imagem: "",
                  criado_em: foto.criado_em,
                };
                this.galeriaFotos.push(img);
                this.searchPhoto(foto.id);
              });
            }
          },
          onError: (error) => {
            console.log(error);
          },
          onEnd: () => {
            this.buscando = false;
          },
        },
        usuario
      );
    },
    classificar_fotos() {
      const fotoService = new FotoService();
      this.loadingClassificar = true;
      let params = {
        antes: this.fotoAntes[0].id,
        depois: this.fotoDepois[0].id,
        id_clinica: 1,
        id_medico: 1,
        descricao: this.descricao,
      };
      fotoService.classificar(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.carragarFotos();
              this.$_ACTIONS_showSnackbarMessage({
                message: body.message,
                color: "sucess",
              });
            }
          },
          onError: (error) => {
            console.log(error);
          },
          onEnd: () => {
            this.loadingClassificar = false;
            this.fotoAntes = [];
            this.fotoDepois = [];
            this.descricao = "";
          },
        },
        params
      );
    },
    searchPhoto(id) {
      const fotoService = new FotoService();
      fotoService.getFoto(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              for (let i = 0, len = this.galeriaFotos.length; i < len; i++) {
                if (this.galeriaFotos[i].id === id) {
                  this.galeriaFotos[i].imagem = body.data[0].imagem;
                  this.galeriaFotos[i].criado_em = body.data[0].criado_em;
                  break;
                }
              }
            }
          },
          onError: (error) => {
            console.log(error);
          },
          onEnd: () => {},
        },
        id
      );
    },
    dialogConfirmDeletar(id) {
      this.deletedItem = id;
      this.confirmDeletar = true;
    },
    deletarClassificacao() {
      this.confirmDeletar = false;
      const fotoService = new FotoService();
      fotoService.deleteFoto(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.$_ACTIONS_showSnackbarMessage({
                message: body.message,
                color: "sucess",
              });
              this.carragarFotos();
            }
          },
          onError: (error) => {
            console.log(error);
          },
          onEnd: () => {},
        },
        this.deletedItem
      );
    },
  },
  watch: {
    dialogAdicionarImages(newValue, oldValue) {
      if (oldValue && !newValue) {
        this.carragarFotos();
      }
    },
  },
};
</script>
<style scoped>
.buttonAddImagens {
  margin: 40px auto;
  background-color: #3e682a !important;
  color: #ffffff;
  font-weight: 600;
}
</style>
