<template>
  <div class="w-100">
    <table
      id="lista_agendamentos"
      class="table-crud agenda-lista"
      style="width: 100%"
    >
      <thead>
        <tr id="agenda-titles">
          <td></td>
          <td align="center">Horário</td>
          <td align="center">Paciente</td>
          <td align="center">Status</td>
          <td align="center">Atendimento</td>
          <td align="center">Ações</td>
          <td></td>
        </tr>
      </thead>
      <tbody>
        <template class="pl-2" v-for="compromisso in compromissos">
          <tr
            v-bind:key="compromisso.idAgendamento"
            class="CI green-dot [OPACITY_ROW] content ag-l-content"
          >
            <v-tooltip
              style="opacity: 100%"
              bottom
              :color="
                `${
                  compromisso.idConsulta
                    ? 'green darken-3'
                    : compromisso.preAgendamentoId
                    ? 'yellow'
                    : compromisso.status != 0
                    ? 'purple'
                    : 'blue darken-4'
                } `
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <td
                  v-on="on"
                  v-bind="attrs"
                  :style="
                    `width: 1px; padding:0 !important; background: ${
                      compromisso.idConsulta
                        ? 'green'
                        : compromisso.preAgendamentoId
                        ? 'yellow'
                        : compromisso.status != 0
                        ? 'purple'
                        : 'blue'
                    } !important`
                  "
                />
              </template>
              <span
                :style="
                  compromisso.idAgendamento && !compromisso.preAgendamentoId
                    ? `color: white;opacity:100%`
                    : `color: black;opacity:100%`
                "
                >{{
                  compromisso.idConsulta
                    ? "Compromisso agendado"
                    : compromisso.preAgendamentoId
                    ? "Pré-agendamento"
                    : "Compromisso agendado"
                }}</span
              >
            </v-tooltip>

            <v-tooltip
              style="opacity: 100%"
              bottom
              :color="
                `${
                  compromisso.idConsulta
                    ? 'green darken-3'
                    : compromisso.preAgendamentoId
                    ? 'yellow'
                    : compromisso.status != 0
                    ? 'purple'
                    : 'blue darken-4'
                } `
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <td v-on="on" v-bind="attrs">
                  <strong>{{ compromisso.horarioInicio }}</strong
                  ><br />
                  <strong>{{ compromisso.horarioFinal }}</strong>
                </td>
              </template>
              <span
                :style="
                  compromisso.idAgendamento && !compromisso.preAgendamentoId
                    ? `color: white;opacity:100%`
                    : `color: black;opacity:100%`
                "
                >{{
                  compromisso.observacao
                    ? compromisso.observacao
                    : "Nenhuma observação"
                }}</span
              >
            </v-tooltip>

            <td>
              <span
                @click="mostraPerfilPaciente(compromisso)"
                class="btnSelecionarPaciente"
                >{{ compromisso.nomePaciente }}</span
              >
            </td>

            <td>
              {{
                compromisso.idConsulta
                  ? "ATENDIDO"
                  : compromisso.status != 0
                  ? "CONFIRMADO"
                  : "PENDENTE"
              }}
            </td>
            <td>
              <v-icon
                v-if="!compromisso.idConsulta && !compromisso.preAgendamentoId"
                small
                class="mr-2"
                :title="compromisso.idConsulta ? 'PENDENTE' : 'CONFIRMADO'"
                @click="atualizarStatusPacientes(compromisso)"
              >
                fas fa-sync
              </v-icon>
            </td>
            <v-tooltip
              style="opacity: 100%"
              bottom
              :color="
                `${
                  compromisso.idConsulta
                    ? 'green darken-3'
                    : compromisso.preAgendamentoId
                    ? 'yellow'
                    : compromisso.status != 0
                    ? 'purple'
                    : 'blue darken-4'
                } `
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <td v-on="on" v-bind="attrs">
                  <span class="tipo-proc">{{
                    compromisso.tipoProcedimento
                  }}</span>
                  <br />
                  <span class="proc">{{ compromisso.procedimento }}</span>
                </td>
              </template>
              <span
                :style="
                  compromisso.idAgendamento && !compromisso.preAgendamentoId
                    ? `color: white;opacity:100%`
                    : `color: black;opacity:100%`
                "
                >Adicionais:
                {{
                  compromisso.procedimentoAdicionalDescricao
                    ? compromisso.procedimentoAdicionalDescricao
                    : "Nenhum procedimento!"
                }}</span
              >
            </v-tooltip>

            <td>
              <v-icon
                v-if="compromisso.idAgendamento"
                small
                class="mr-2"
                title="Atestado"
                @click="gerarAtestado(compromisso)"
                >fas fa-file-medical
              </v-icon>
              <v-icon
                v-if="compromisso.idAgendamento"
                small
                class="mr-2"
                title="Encaminhamento Médico"
                @click="gerarEncaminhamento(compromisso)"
                >fas fa-user-md</v-icon
              >
              <v-icon
                v-if="compromisso.idAgendamento"
                small
                class="mr-2"
                title="Solicitar exames"
                @click="gerarSolicitacaoDeExame(compromisso)"
                >fas fa-tasks
              </v-icon>
              <v-icon
                v-if="compromisso.idAgendamento"
                small
                class="mr-2"
                title="Receita Novas"
                @click="gerarReceitasNovas(compromisso)"
                >fas fa-capsules
              </v-icon>
              <v-icon
                v-if="compromisso.idAgendamento"
                small
                class="mr-2"
                title="Receita Carbonada"
                @click="gerarReceitasCarbonada(compromisso)"
                >fas fa-file-alt
              </v-icon>
              <v-icon
                v-if="compromisso.idAgendamento"
                small
                class="mr-2"
                title="Termo de responsabilidade"
                @click="gerarTermos(compromisso)"
                >fas fa-file-signature</v-icon
              >
              <v-icon
                v-if="compromisso.idAgendamento"
                small
                class="mr-2"
                title="Galeria de fotos"
                @click="listGalerias(compromisso.paciente_id)"
                >fas fa-images
              </v-icon>
              <v-icon
                v-if="compromisso.idAgendamento"
                small
                class="mr-2"
                title="Fotos classificadas"
                @click="listFotosClassificadas(compromisso.paciente_id)"
                >fa-solid fa-bookmark
              </v-icon>
              <v-icon
                v-if="compromisso.idAgendamento"
                small
                class="mr-2"
                title="Documentos"
                @click="listFile(compromisso.paciente_id)"
                >fas fa-folder
              </v-icon>
              <v-icon
                v-if="compromisso.idAgendamento"
                small
                class="mr-2"
                title="Prontuários"
                @click="listProntuarios(compromisso.paciente_id)"
                >fas fa-file-medical-alt
              </v-icon>
            </td>
            <td>
              <v-menu
                :close-on-content-click="false"
                :nudge-width="200"
                offset-x
              >
                <template v-slot:activator="{ on, attrs }">
                  <button
                    @click="openProntuariosAgendaOnly(compromisso)"
                    v-bind="attrs"
                    v-on="on"
                    class="ag-btn-atd [ATENDIDO]"
                    v-if="!compromisso.idConsulta"
                  >
                    {{
                      compromisso.idAgendamento
                        ? "ATENDER"
                        : "CADASTRAR CLIENTE"
                    }}
                  </button>
                </template>
                <v-container class="bg-white">
                  <h4>selecionar tipo de formulário</h4>
                  <v-select
                    v-model="edited.idFormulario"
                    @change="modalConsulta = true"
                    item-value="id"
                    item-text="titulo"
                    :items="formularios"
                    dense
                  ></v-select>
                </v-container>
              </v-menu>
            </td>
            <td></td>
          </tr>
          <tr v-bind:key="`it-${compromisso.idAgendamento}`" class="spacer">
            <td colspan="9" class="p-1"></td>
          </tr>
        </template>

        <v-dialog
          v-model="dialogPerfil"
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <cadastro-edicao-paciente
            v-if="dialogPerfil"
            :paciente="paciente"
            :visualizar="false"
            @close="dialogPerfil = false"
          />
        </v-dialog>

        <v-dialog
          v-model="modalConsulta"
          fullscreen
          scrollable
          hide-overlay
          persistent
          transition="dialog-bottom-transition"
        >
          <modal-iniciar-consulta
            @close="modalConsulta = false"
            :paciente="paciente"
            :info="{
              idAgendamento: edited.idAgendamento,
              idFormulario: edited.idFormulario,
            }"
            @finalizarConsulta="finalizarConsulta($event)"
          />
        </v-dialog>

        <v-dialog
          v-model="dialogCadastroPaciente"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar style="background-color: #3e682a; color: #fff">
              <v-btn icon dark @click="dialogCadastroPaciente = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Cadastrar novo paciente</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-list three-line subheader>
              <cadastro-novo-paciente
                :paciente="{ nome: nome_props, telefone: telefone_props }"
                @close="dialogCadastroPaciente = false"
              />
            </v-list>
          </v-card>
        </v-dialog>

        <!-- dialogs prontuario view -->
        <!-- DIALOG FOTOS CLASSIFICADAS -->
        <v-dialog
          v-model="dialogFotosClassificadas"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <fotos-classificadas
            :paciente="edited_paciente_id"
            :key="rederComp"
            @close="close()"
          />
        </v-dialog>
        <!-- DIALOG DE PRONTUÁRIO -->
        <v-dialog
          v-model="dialogP"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <prontuarios :consultas="prontuarios" @close="dialogP = false" />
        </v-dialog>

        <!-- DIALOG DE PRONTUÁRIO -->
        <v-dialog
          v-model="dialogGaleria"
          fullscreen
          hide-overlay
          persistent
          transition="dialog-bottom-transition"
        >
          <galerias
            :paciente="{ id_paciente: edited_paciente_id }"
            :key="rederComp"
            @close="close()"
          />
        </v-dialog>
        <v-dialog v-model="dialogLoading" hide-overlay persistent width="300">
          <v-card color="white">
            <v-card-text>
              Carregando...
              <v-progress-linear
                indeterminate
                color="green lighten-3"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="dialogArquivo"
          fullscreen
          hide-overlay
          persistent
          transition="dialog-bottom-transition"
        >
          <arquivo
            :paciente="edited_paciente_id"
            :key="rederComp"
            @close="close()"
          />
        </v-dialog>
        <v-dialog v-model="dialogAtestado" hide-overlay persistent width="50%">
          <atestado @close="close()" @gerarPDF="gerarPDF($event)" />
        </v-dialog>
        <v-dialog
          v-model="dialogExames"
          hide-overlay
          persistent
          style="width: 50%"
        >
          <exames @close="close()" @gerarPDF="gerarPDF($event)" />
        </v-dialog>
        <v-dialog
          v-model="dialogEncaminhamento"
          hide-overlay
          persistent
          width="30%"
        >
          <encaminhamento @close="close()" @gerarPDF="gerarPDF($event)" />
        </v-dialog>
        <v-dialog v-model="dialogReceita" hide-overlay persistent width="50%">
          <receita @close="close()" @gerarPDF="gerarPDF($event)" />
        </v-dialog>
        <v-dialog v-model="dialogTermo" hide-overlay persistent width="40%">
          <termos @close="close()" @gerarPDF="gerarPDF($event)" />
        </v-dialog>
        <v-dialog
          v-model="dialogReceitaCarbonada"
          hide-overlay
          persistent
          width="50%"
        >
          <receita-carbonada @close="close()" @gerarPDF="gerarPDF($event)" />
        </v-dialog>
      </tbody>
    </table>

    <!-- Modal Detalhe -->
    <v-dialog
      v-model="dialogDetalhe"
      hide-overlay
      transition="dialog-bottom-transition"
      width="50%"
    >
      <detalhe-paciente-preagendamento
        v-if="dialogDetalhe"
        :detalhes="{ nome: nome_props, telefone: telefone_props }"
        :visualizar="false"
        @close="dialogDetalhe = false"
      />
    </v-dialog>

    <documentos-pdf
      :htmlToPdf.sync="htmlToPdf"
      :arquivos.sync="arquivos"
      :dialogArquivo.sync="dialogArquivo"
      :dialogAtestado.sync="dialogAtestado"
      :nome.sync="nome"
      :dias.sync="dias"
      :codigoCFM.sync="codigoCFM"
      :dialogEncaminhamento.sync="dialogEncaminhamento"
      :nomeMedico.sync="nomeMedico"
      :dialogExames.sync="dialogExames"
      :exames.sync="exames"
      :obs.sync="obs"
      :obs2.sync="obs2"
      :dialogReceita.sync="dialogReceita"
      :receita.sync="receita"
      :dialogReceitaCarbonada.sync="dialogReceitaCarbonada"
      :logradouro.sync="logradouro"
      :numero.sync="numero"
      :complemento.sync="complemento"
      :dialogTermo.sync="dialogTermo"
      :tipoTermo.sync="tipoTermo"
      :procedimento.sync="procedimento"
      :cpf.sync="cpf"
    />
  </div>
</template>

<script>
import FormularioService from "@/services/formulario_service.js";
import ConsultaService from "@/services/consulta_service.js";
import PacienteService from "@/services/paciente_service.js";
import ModalIniciarConsulta from "@/components/consultas/modal_iniciar_consulta";
import CadastroEdicaoPaciente from "@/components/pacientes/cadastro_edicao_paciente.vue";
import CadastroNovoPaciente from "@/components/pacientes/cadastro_novo_paciente_dashboard.vue";
import DetalhePacientePreagendamento from "@/components/pacientes/detalhe_novo_paciente_dashboard.vue";
import Atestado from "@/components/documentos/atestado.vue";
import Encaminhamento from "@/components/documentos/encaminhamento.vue";
import Exames from "@/components/documentos/exames.vue";
import Receita from "@/components/documentos/receita.vue";
import ReceitaCarbonada from "@/components/documentos/receita_carbonada.vue";
import Termos from "@/components/documentos/termos.vue";
import Galerias from "@/components/prontuarios/galeria.vue";
import Arquivo from "@/components/prontuarios/arquivos.vue";
import Prontuarios from "@/components/prontuarios/prontuarios.vue";
import ProfissionalService from "@/services/profissional_service.js";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import FotosClassificadas from "@/components/prontuarios/fotos_classificadas.vue";
import DocumentosPdf from "@/components/documentos/pdfs/documentosPdf";
import AgendamentoService from "@/services/agendamento_service";
import VuexSnackbarMixin from "@/mixins/vuex_snackbar_mixin.js";

export default {
  mixins: [VuexSnackbarMixin],
  components: {
    ModalIniciarConsulta,
    CadastroEdicaoPaciente,
    Prontuarios,
    Galerias,
    Arquivo,
    Atestado,
    Exames,
    Encaminhamento,
    Receita,
    ReceitaCarbonada,
    Termos,
    FotosClassificadas,
    CadastroNovoPaciente,
    DetalhePacientePreagendamento,
    DocumentosPdf,
  },
  props: {
    compromissos: { type: Array, required: true },
  },
  beforeMount() {
    this.selectNext();
  },

  data: () => ({
    dialogCadastroPaciente: false,
    modalConsulta: false,
    dialogLoading: false,
    rederComp: 0,
    prontuarios: [],
    formularios: [],
    formulario: "",
    renderComp: 0,
    procedimento: "",
    indexCompromissoSelecionado: 0,
    idAgendamentoSelecionado: null,
    loadingFormularios: false,
    telaCarregada: false,
    dialog: false,
    procedimentoAtendido: {},
    edited: {},
    paciente: {},
    dialogPerfil: false,
    dialogDetalhe: false,
    dialogCadastro: false,
    dialogGaleria: false,
    dialogFotosClassificadas: false,
    visualizar: false,
    pacientes: [],
    htmlToPdf: "",
    // Documentos
    arquivos: [],
    dialogArquivo: false,
    // Atestado
    dialogAtestado: false,
    dialogP: false,
    nome: "",
    dias: "",
    codigoCFM: "",
    //Encaminhamento
    dialogEncaminhamento: false,
    nomeMedico: "",
    //Solicitar Exame
    dialogExames: false,
    exames: [],
    obs: "",
    edited_paciente_id: 0,
    nome_props: "",
    telefone_props: "",
    obs2: "",
    //Receita Nova
    dialogReceita: false,
    receita: "",
    //Receita Carbonada
    dialogReceitaCarbonada: false,
    logradouro: "",
    numero: "",
    complemento: "",
    //Termos
    dialogTermo: false,
    tipoTermo: "",
    cpf: "",
  }),
  computed: {
    compromissosNaoAtrasados() {
      return this.compromissos.filter((compromisso) => !compromisso.emAtraso);
    },
    procedimentoPossuiFormularios() {
      return this.formularios.length > 0;
    },
  },
  methods: {
    finalizarConsulta() {
      this.$emit("atualizar");
    },
    mostraPerfilPaciente(cmp) {
      if (!cmp.idAgendamento && cmp.preAgendamentoId) {
        this.dialogDetalhe = true;
        this.nome_props = cmp.nomePaciente;
        this.telefone_props = cmp.telefone1;
      } else {
        this.loadingPacienteAndOpen(cmp.paciente_id);
      }
    },

    atualizarListaPacientes() {
      const profissionalService = new ProfissionalService();
      this.loading = true;
      this.pacientes = [];
      const onSucess = (status) => (body) => {
        if (status === 200) {
          this.totalList = body.data.pagination.num_rows;

          this.pacientes = body.data.pacientes.map((paciente) => {
            return paciente;
          });

          if (this.buscarEspecifico) {
            this.pacientes = this.pacientes.filter(
              (paciente) => paciente.id == this.$router.params.paciente.id
            );
          }
        } else {
          console.log(body);
        }
      };
      const onError = (error) => {
        console.log(error);
      };
      const onEnd = () => {
        this.loading = false;
      };

      this.search = this.buscarEspecifico
        ? this.$router.params.paciente.nome
        : this.search;

      profissionalService.getPacienteList(
        onSucess,
        onError,
        onEnd,
        this.options.page,
        this.options.itemsPerPage,
        this.search,
        this.options.ordenacao,
        this.options.desc
      );

      if (this.buscarEspecifico) {
        this.buscarEspecifico = false;
      }
    },

    atualizarStatusPacientes(compromisso) {
      const agendamentoService = new AgendamentoService();
      agendamentoService.alteraStatusAgendamento(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.$_ACTIONS_showSnackbarMessage({
                message: "Alterado com sucesso!",
                color: "sucess",
              });
              this.finalizarConsulta();
              console.log();
            } else {
              console.log(body);
            }
          },
          onError: (error) => {
            console.error(error);
            this.$_ACTIONS_showSnackbarMessage({
              message: this.$global.messages.internalServerError,
              color: "error",
            });
          },
          onEnd: () => {},
        },
        compromisso.idAgendamento,
        compromisso.status == 1 ? 0 : 1
      );
    },
    gerarAtestado(item) {
      this.nome = item.nome;
      this.dialogAtestado = true;
    },
    gerarEncaminhamento(item) {
      this.nome = item.nome;
      this.dialogEncaminhamento = true;
    },
    gerarSolicitacaoDeExame(item) {
      this.nome = item.nome;
      this.dialogExames = true;
    },
    gerarReceitasNovas(item) {
      this.nome = item.nome;
      this.dialogReceita = true;
    },
    gerarReceitasCarbonada(item) {
      this.nome = item.nome;
      this.logradouro = item.logradouro;
      this.numero = item.numero;
      this.complemento = item.complemento;
      this.dialogReceitaCarbonada = true;
    },
    gerarTermos(item) {
      this.nome = item.nome;
      this.cpf = item.cpf;
      this.dialogTermo = true;
    },
    gerarData() {
      const meses = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novovembro",
        "Dezembro",
      ];
      let data = new Date();
      return {
        dia: data.getDate(),
        mes: meses[data.getMonth()],
        ano: data.getFullYear(),
      };
    },

    gerarPDF(info) {
      switch (info.doc) {
        case "atestado":
          this.htmlToPdf = info.doc;
          this.dias = info.dias;
          this.codigoCFM = info.codigoCFM;
          this.$nextTick(() => {
            this.close();
            this.criarPDF(
              document.querySelector("#examesPDF").innerHTML,
              "Atestado",
              "padrao"
            );
          });
          break;
        case "termo":
          this.htmlToPdf = info.doc;
          this.tipoTermo = info.termo_tipo;
          this.procedimento = info.procedimento;
          this.$nextTick(() => {
            this.close();
            this.criarPDF(
              document.querySelector("#examesPDF").innerHTML,
              "Termo de Responsabilidade",
              "padrao"
            );
          });
          break;
        case "receita":
          this.htmlToPdf = info.doc;
          this.receita = info.receita;
          this.$nextTick(() => {
            this.close();
            this.criarPDF(
              document.querySelector("#examesPDF").innerHTML,
              "Receita",
              "padrao"
            );
          });
          break;
        case "receita-carbonada":
          this.htmlToPdf = info.doc;
          this.receita = info.receita;

          this.$nextTick(() => {
            this.close();
            this.criarPDF(
              document.querySelector("#examesPDF").innerHTML,
              "Receita-carbonada",
              "carbonada"
            );
          });
          break;
        case "encaminhamento":
          this.htmlToPdf = info.doc;
          this.nomeMedico = info.nomeMedico;
          this.$nextTick(() => {
            this.close();
            this.criarPDF(
              document.querySelector("#examesPDF").innerHTML,
              "Encaminhamento",
              "padrao"
            );
          });
          break;
        case "exames":
          this.htmlToPdf = info.doc;
          this.exames = info.exames;
          this.obs = info.obs;
          this.obs2 = info.obs2;
          this.$nextTick(() => {
            this.close();
            this.criarPDF(
              document.querySelector("#examesPDF").innerHTML,
              "Solicitação de exames",
              "padrao"
            );
          });
          break;

        default:
          console.log("Nenhum tipo de documento encontrado");
          break;
      }
    },
    criarPDF(content, nome, tipo) {
      window.html2canvas = html2canvas;
      if (tipo == "padrao") {
        var doc = new jsPDF("p", "mm", "a4");
        doc.html(content, {
          callback: function(pdf) {
            pdf.save(nome + ".pdf");
          },
          x: 5,
          y: 5,
        });
      } else {
        var docm = new jsPDF("l", "mm", "a4");
        docm.html(content, {
          callback: function(pdf) {
            pdf.save(nome + ".pdf");
          },
          x: 1,
          y: 1,
        });
      }
    },

    listProntuarios(item) {
      this.paciente = item;
      this.dialogLoading = true;
      const consultaService = new ConsultaService();
      consultaService.getListByPacienteId(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.prontuarios = body.data.consultas;
              this.dialogP = true;
            }
          },
          onError: (error) => {
            console.log(error);
          },
          onEnd: () => {
            this.dialogLoading = false;
          },
        },
        item
      );
    },

    forceRender() {
      this.rederComp += 1;
    },

    listFile(item) {
      this.edited_paciente_id = item;
      this.dialogArquivo = true;
      this.forceRender();
    },

    listGalerias(item) {
      this.edited_paciente_id = item;
      this.dialogGaleria = true;
      this.forceRender();
    },

    listFotosClassificadas(item) {
      this.edited_paciente_id = item;
      this.dialogFotosClassificadas = true;
      this.forceRender();
    },

    close() {
      this.dialog = false;
      this.dialogGaleria = false;
      this.dialogFotosClassificadas = false;
      this.dialogAtestado = false;
      this.dialogEncaminhamento = false;
      this.dialogExames = false;
      this.dialogReceita = false;
      this.dialogReceitaCarbonada = false;
      this.dialogTermo = false;
      this.dialogArquivo = false;
      this.forceRender();
    },

    loadingPaciente(id) {
      this.paciente = {};
      const pacienteService = new PacienteService();
      pacienteService.get(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.paciente = body.data;
              this.dialog = true;
            }

            this.dialog = true;
          },
          onError: (error) => {
            this.messageSnackbar = error;
            this.typeMessageSnackbar = "error";
            console.log(error);
          },
          onEnd: () => {},
        },
        id
      );
    },

    loadingPacienteAndOpen(id) {
      this.paciente = {};
      const pacienteService = new PacienteService();
      pacienteService.get(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.paciente = body.data;
              this.dialog = true;
            }

            this.dialog = true;
          },
          onError: (error) => {
            this.messageSnackbar = error;
            this.typeMessageSnackbar = "error";
            console.log(error);
          },
          onEnd: () => {
            this.dialogPerfil = true;
          },
        },
        id
      );
    },

    reloadConsultas() {
      this.selectNext();
    },

    openProntuariosAgenda(cmp) {
      this.loadingPaciente(cmp.paciente_id);
      this.edited = cmp;
      this.carregarTiposFormularios();

      this.modalConsulta = true;
    },

    openProntuariosAgendaOnly(cmp) {
      if (!cmp.idAgendamento && cmp.preAgendamentoId) {
        this.dialogCadastroPaciente = true;
        this.nome_props = cmp.nomePaciente;
        this.telefone_props = cmp.telefone1;
      } else {
        this.loadingPaciente(cmp.paciente_id);
        this.carregarTiposFormulariosPorProcedimento(cmp.idProcedimento);
        this.edited = cmp;
      }
    },

    selectNext() {
      let compromisso = {};
      for (
        let index = 0;
        index < this.compromissosNaoAtrasados.length;
        index++
      ) {
        compromisso = this.compromissosNaoAtrasados[index];
        if (compromisso.idConsulta == null) {
          this.selecionarAgendamento(function() {}, compromisso);
          this.procedimento = compromisso.idProcedimento;
          this.idAgendamentoSelecionado = compromisso.idAgendamento;
          this.observacao = compromisso.observacao;
          this.indexCompromissoSelecionado = index;
          this.carregarTiposFormularios();
          this.$emit("paciente", { nome: compromisso.nomePaciente });
          break;
        }
      }
    },
    carregarTiposFormularios() {
      this.loadingFormularios = true;
      this.formularios = [];
      const formularioService = new FormularioService();
      formularioService.getAll(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.telaCarregada = true;
              body.data.formularios.forEach((form) => {
                let formulario = [];
                (formulario["id"] = form.id),
                  (formulario["titulo"] = form.titulo);
                formulario["descricao"] = form.descricao;
                formulario["quantidade_perguntas"] = form.quantidade_perguntas;
                this.formularios.push(formulario);
              });
            }
          },
          onError: (error) => {
            console.log(error);
          },
          onEnd: () => {
            this.loadingFormularios = false;
          },
        },
        {
          procedimento: this.procedimento,
          ativo: 1,
        }
      );
    },

    carregarTiposFormulariosPorProcedimento(procedimento) {
      this.loadingFormularios = true;
      this.formularios = [];
      const formularioService = new FormularioService();
      formularioService.getAll(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.telaCarregada = true;
              this.formularios = body.data.formularios;
            }
          },
          onError: (error) => {
            console.log(error);
          },
          onEnd: () => {
            this.loadingFormularios = false;
          },
        },
        {
          procedimento: procedimento,
          ativo: 1,
        }
      );
    },

    iniciarConsulta() {
      this.$emit("iniciar-consulta", {
        idAgendamentoSelecionado: this.idAgendamentoSelecionado,
        idFormulario: this.formulario,
      });
    },

    limparSelecao() {
      this.indexCompromissoSelecionado = null;
      this.idAgendamentoSelecionado = null;
    },
    registrarPacienteAusente() {
      this.$emit("paciente-ausente", {
        idAgendamentoSelecionado: this.idAgendamentoSelecionado,
      });
    },
    selecionarAgendamento(toggle, compromisso) {
      toggle();
      this.procedimento = compromisso.idProcedimento;
      this.procedimentoAtendido = compromisso;
      this.idAgendamentoSelecionado = compromisso.idAgendamento;
      this.carregarTiposFormularios();
      this.$emit("paciente", { nome: compromisso.nomePaciente });
    },
    componenteCard(active, idConsulta) {
      return {
        "00": "CardPacientePosterior",
        "01": "CardPacienteAnterior",
        10: "CardPacienteAtual",
        11: "CardPacienteAtual",
      }[`${+active}${+!!idConsulta}`];
    },
  },
};
</script>

<style scoped>
/*#main {*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-content: center;*/
/*  font-size: 4px;*/
/*  height: 15.8rem;*/
/*  max-height: 17rem;*/
/*  !* width: 13vw; *!*/
/*  padding: 1rem;*/
/*  !* background-color: tomato; *!*/
/*}*/

#tabela_agenda_lista {
  width: 100%;
  float: left;
  margin-top: 74px;
}
.boxTable {
  overflow: auto;
}
.boxTable {
  margin-top: 10px !important;
  margin-bottom: 80px !important;
  border-radius: 15px;
}

#tabela_agenda_lista table {
  line-height: 22px;
  border-collapse: collapse;
}
.agenda-lista {
  font-size: 9pt !important;
}
table {
  font-size: 12px;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

#tabela_agenda_lista table {
  line-height: 22px;
  border-collapse: collapse;
}
.agenda-lista {
  font-size: 9pt !important;
}
table {
  font-size: 12px;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

.agenda-lista thead {
  font-size: 9pt;
  box-shadow: none;
}

strong {
  font-size: 12pt;
  font-weight: bold;
  color: #000000;
}

.btnSelecionarPaciente {
  color: #070060;
  text-decoration: none;
  font-size: 14px;
  cursor: pointer;
}

.btnSelecionarPaciente:hover {
  filter: brightness(0.4);
}

.tipo-proc {
  font-size: 8pt !important;
  color: #5f6f7f !important;
}

.proc {
  font-size: 12pt !important;
  color: #000 !important;
  font-weight: bold !important;
  letter-spacing: 0;
}

.table-crud thead {
  color: #5f6f7f;
  font-size: 12px;
  text-decoration: none;
  height: 40px;
  border-radius: 15px !important;
  margin: 5px !important;
}

.agenda-lista .ag-l-content .ag-l-item.ag-l-status {
  max-width: 200px;
  width: 200px;
}
.agenda-lista .ag-l-content .ag-l-status {
  border-left: solid #e3e5e8;
  border-left-width: medium;
  border-right: solid #e3e5e8;
  border-right-width: medium;
  border-width: 1px;
  color: #5f6f7f;
}
.agenda-lista .ag-l-content .ag-l-item {
  padding: 1em 1em;
  font-weight: bolder;
}
.table-crud td {
  background-color: #fff;
  padding: 15px 6px;
  align-items: center;
}
td,
th {
  padding: 0;
}

.agenda-lista #agenda-titles {
  width: 100%;
  color: #999;
  background-color: #f8fafd;
  text-align: left;
}
.agenda-lista .ag-l-content .ag-l-item:nth-of-type(1) {
  max-width: 60px;
  width: 60px;
  padding-left: 20px;
  border-radius: 6px 0 0 6px;
}
.agenda-lista .ag-l-content .ag-l-item {
  padding: 1em 1em;
  padding-left: 1em;
  font-weight: bolder;
}
.agenda-lista .ag-l-content .compromisso_agendado {
  color: #000;
}
.table-crud td:first-child,
.table-crud th:first-child {
  border-radius: 10px 0 0 10px;
}
.agenda-lista .colored-border {
  position: relative;
}
.table-crud td {
  background-color: #fff !important;
  padding: 15px 6px;
  align-items: center;
}
.compromisso_agendado {
  cursor: pointer;
  overflow: hidden;
  color: #fff;
}
td,
th {
  padding: 0;
}

table tr {
  border: 0;
  margin: 5px !important;
  border-radius: 20px !important;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.05) !important;
}
.agenda-lista .ag-l-content {
  border-radius: 8px !important;
}
#tabela_agenda_lista table {
  line-height: 22px;
  border-collapse: collapse;
}
.agenda-lista {
  font-size: 9pt !important;
}
table {
  font-size: 12px;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

table {
  border-spacing: 0;
}
.CI td {
  background: #fff;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.09);
  margin: 0 !important;
}
.spacer td {
  background: transparent !important;
  box-shadow: 0 !important;
}

tr td:first-child {
  border-top-left-radius: 10px;
}
tr td:last-child {
  border-top-right-radius: 10px;
}
tr td:first-child {
  border-bottom-left-radius: 10px;
}
tr td:last-child {
  border-bottom-right-radius: 10px;
}

.agenda-lista .ag-btn-atd {
  font-size: 8pt;
  float: right;
  border-style: solid;
  border-color: #3e682a;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center right 15%;
  text-align: left;
  background-size: 13%;
  border-radius: 200px;
  border-width: 2px;
  height: 35px;
  padding-top: 0.25rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  color: #3e682a;
}

.agenda-lista .ag-btn-atd:hover {
  color: #fff;
  background-color: #3e682a;
}
</style>
