<template>
  <div id="examesPDF" ref="examesPDF" v-show="false">
    <div id="header" v-if="htmlToPdf != 'receita-carbonada'">
      <img src="@/assets/images/logo-horizontal.png" alt="" />
    </div>
    <div id="main">
      <documento-exame
        v-if="htmlToPdf == 'exames'"
        :exames="exames"
        :nome="nome"
        :obs="obs"
        :obs2="obs2"
      />
      <documento-atestado
        v-else-if="htmlToPdf == 'atestado'"
        :dias="dias"
        :nome="nome"
        :codigo-c-f-m="codigoCFM"
      />
      <documento-receita
        v-else-if="htmlToPdf == 'receita'"
        :nome="nome"
        :receita="receita"
      />
      <documento-receita-carbonada
        v-else-if="htmlToPdf == 'receita-carbonada'"
        :receita="receita"
        :nome="nome"
        :cpf="cpf"
        :complemento="complemento"
        :logradouro="logradouro"
        :numero="numero"
      />
      <documento-termos
        v-else-if="htmlToPdf == 'termo'"
        :nome="nome"
        :cpf="cpf"
        :procedimento="procedimento"
        :tipo-termo="tipoTermo"
      />
      <documento-encaminhamento
        v-else-if="htmlToPdf == 'encaminhamento'"
        :nome="nome"
        :nome-medico="nomeMedico"
      />
    </div>
    <div id="footer" v-if="htmlToPdf != 'receita-carbonada'">
      <img src="@/assets/images/footer.jpg" alt="" />
    </div>
  </div>
</template>

<script>
import DocumentoExame from "./documento_exame";
import DocumentoAtestado from "./documento_atestado";
import DocumentoReceita from "./documento_receita";
import DocumentoReceitaCarbonada from "./documento_receita_carbonada";
import DocumentoTermos from "./documento_termos";
import DocumentoEncaminhamento from "./documento_encaminhamento";
export default {
  name: "documentosPdf",
  components: {
    DocumentoEncaminhamento,
    DocumentoTermos,
    DocumentoReceitaCarbonada,
    DocumentoReceita,
    DocumentoAtestado,
    DocumentoExame,
  },
  props: {
    htmlToPdf: { required: true },
    // Documentos
    arquivos: { required: true },
    dialogArquivo: { required: true },
    // Atestado
    dialogAtestado: { required: true },
    nome: { required: true },
    dias: { required: true },
    codigoCFM: { required: true },
    //Encaminhamento
    dialogEncaminhamento: { required: true },
    nomeMedico: { required: true },
    //Solicitar Exame
    dialogExames: { required: true },
    exames: { required: true },
    obs: { required: true },
    obs2: { required: true },
    //Receita Nova
    dialogReceita: { required: true },
    receita: { required: true },
    //Receita Carbonada
    dialogReceitaCarbonada: { required: true },
    logradouro: { required: true },
    numero: { required: true },
    complemento: { required: true },
    //Termos
    dialogTermo: { required: true },
    tipoTermo: { required: true },
    procedimento: { required: true },
    cpf: { required: true },
  },
  methods: {
    gerarData() {
      const meses = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novovembro",
        "Dezembro",
      ];
      let data = new Date();
      return {
        dia: data.getDate(),
        mes: meses[data.getMonth()],
        ano: data.getFullYear(),
      };
    },
  },
};
</script>

<style>
#examesPDF {
  text-align: center;
}

p {
  letter-spacing: 0.5px;
}

#header,
#footer,
#main {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  margin-left: auto;
  width: 12.5rem;
  margin-right: auto;
}
#header {
  height: 1rem;
  /* background-color: red; */
}
#header img {
  height: 1rem;
  width: 2.5rem;
}
#main {
  display: flex;
  justify-content: center;
  align-content: center;
  font-size: 4px;
  height: 15.8rem;
  max-height: 17rem;
  /* width: 13vw; */
  padding: 1rem;
  /* background-color: tomato; */
}
#footer {
  /* background-color: rebeccapurple; */
  margin-top: 0.1rem;
  margin-bottom: 0;
  margin-right: 0.1rem;
  height: 1.2rem;
}
#footer img {
  max-width: 12.5rem;
  max-height: 1.2rem;
}

.carbonada {
  font-size: 3px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  /* background-color: rgb(233, 255, 255); */
  margin-left: 6rem;
  padding-top: 0px;
}

.texto {
  padding: 1px;
  font-size: 5px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.space {
  color: white;
}
.resp {
}
.indent {
  text-indent: 5px;
}
</style>
