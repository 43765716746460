<template>
  <div class="d-flex col-12">
     <label>{{ pergunta.texto_pergunta}}</label>
     <v-textarea
        class="col-10"
        v-if="pergunta.obrigatorio"
        v-model="resposta.resposta"
        :rules="regras"
        rows="2"
        @blur="respondido"
      ></v-textarea>
     <v-textarea
        class="col-10"
        v-else
        v-model="resposta.resposta"
        rows="2"
        @blur="respondido"
      ></v-textarea>
  </div>
</template>
<script>
import Mixin from "@/mixins/vuex_snackbar_mixin.js";

export default {
  mixins: [Mixin],
  props:{
    pergunta:Object,
  },
  beforeMount(){
     this.resposta = {
      id_pergunta:this.pergunta.id,
      resposta:""
    }
  },
  data: () => ({
    resposta:{},
    regras: [
        value => !!value || 'Obrigatório.',
    ]
  }),
  methods:{
    respondido(){
      this.$emit("resposta", this.resposta)
    }
  }
};
</script>
<style scoped>
.v-progress-circular {
  margin: 1rem;
}
</style>