<template>
  <div class="row">
    <v-col col="12" class="row col-12">
      <v-col col="6" class="col-6">
        <v-select
          v-model="dashboard"
          :items="['legado', 'nova']"
          label="Dashboard"
        ></v-select
      ></v-col>
      <div class="col-4">
        <v-text-field
          v-if="dashboard == 'nova'"
          v-model="data_consultas"
          label="Data"
          type="date"
        ></v-text-field>
      </div>
      <div class="col-2">
        <v-btn
          @click="atualizarCompromissos"
          class="mt-4"
          color="green"
          text="white"
          elevation="2"
          >Atualizar</v-btn
        >
      </div>
    </v-col>
    <div v-if="dashboard == 'legado'">
      <fila-atual
        v-if="compromissos.length"
        :compromissos="compromissos"
        @iniciar-consulta="iniciarConsulta($event)"
        @paciente-ausente="ativarModalAtrasoPaciente"
        @paciente="paciente = $event"
      />

      <clientes-atrasados
        :compromissos="compromissos"
        @iniciar-consulta="iniciarConsulta"
        @remover="removerPacienteDaListaDeAtrasados($event)"
      />

      <compromissos :compromissos="compromissos" />

      <v-dialog
        v-model="modalConsulta"
        fullscreen
        scrollable
        hide-overlay
        persistent
        transition="dialog-bottom-transition"
      >
        <modal-iniciar-consulta
          :paciente="paciente"
          :info="info"
          @close="cancelarConsulta"
          @finalizarConsulta="finalizarConsulta($event)"
        />
      </v-dialog>
      <dialog-confirmacao-atraso
        :enabled="modalAtrasoPaciente"
        @cancel="modalAtrasoPaciente = false"
        @confirm="moverPacienteParaListaDeAtrasados"
      />
    </div>
    <div v-else class="tabela_agenda_lista" style="width: 100vw">
      <fila-tabela
        v-if="compromissos.length && !loading"
        :compromissos="compromissos"
        @iniciar-consulta="iniciarConsulta($event)"
        @paciente-ausente="ativarModalAtrasoPaciente"
        @paciente="paciente = $event"
        @atualizar="atualizarCompromissos"
      />

      <v-progress-circular indeterminate color="green" v-if="loading" />

      <v-dialog
        v-model="modalConsulta"
        fullscreen
        scrollable
        hide-overlay
        persistent
        transition="dialog-bottom-transition"
      >
        <modal-iniciar-consulta
          :paciente="paciente"
          :info="info"
          @close="cancelarConsulta"
          @finalizarConsulta="finalizarConsulta($event)"
        />
      </v-dialog>
      <dialog-confirmacao-atraso
        :enabled="modalAtrasoPaciente"
        @cancel="modalAtrasoPaciente = false"
        @confirm="moverPacienteParaListaDeAtrasados"
      />
    </div>
  </div>
</template>

<script>
import FilaAtual from "./fila_atual";
import FilaTabela from "./fila_tabela";
import ClientesAtrasados from "./clientes_atrasados";
import Compromissos from "./compromissos";
import ModalIniciarConsulta from "@/components/consultas/modal_iniciar_consulta";
import DialogConfirmacaoAtraso from "@/components/dashboards/dashboard_medico/fila_atual/dialog_confirmacao_atraso.vue";
import DashboardService from "@/services/dashboard_service";
import VuexSnackbarMixin from "@/mixins/vuex_snackbar_mixin.js";

export default {
  mixins: [VuexSnackbarMixin],
  beforeMount() {
    // this.atualizarCompromissos();
  },
  components: {
    FilaAtual,
    FilaTabela,
    ClientesAtrasados,
    Compromissos,
    ModalIniciarConsulta,
    DialogConfirmacaoAtraso,
  },

  data: () => ({
    modalConsulta: false,
    modalAtrasoPaciente: false,
    paciente: {},
    info: {},
    dashboard: "nova",
    compromissos: [],
    loading: false,
    indexCompromissoSelecionado: null,
    idAgendamentoSelecionado: null,
    data_consultas: null,
  }),
  mounted() {
    if (this.dashboard === "nova") {
      // Obter a data atual no formato YYYY-MM-DD
      const today = new Date().toISOString().slice(0, 10);
      // Definir o valor do input para a data atual
      this.data_consultas = today;
      // Chamar a função para atualizar compromissos
      this.atualizarCompromissos();
    }
  },
  methods: {
    atualizarCompromissos() {
      this.loading = true;
      this.compromissos = [];
      const dashboardService = new DashboardService();
      dashboardService.getDashboarMedico(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              for (let index = 0; index < body.data.length; index++) {
                const element = body.data[index];
                this.compromissos.push({
                  idAgendamento: ~~element.id_agendamento,
                  idConsulta: ~~element.id_consulta,
                  emAtraso: !!element.atrasado,
                  data: element.data || "",
                  horarioInicio: element.hora_inicio || "",
                  horarioFinal: element.hora_final || "",
                  nomePaciente: element.nome || "",
                  telefone1: element.telefone_1 || "",
                  paciente_id: ~~element.paciente_id,
                  procedimento: element.procedimento || "",
                  tipoProcedimento: element.tipo_procedimento || "",
                  idProcedimento: ~~element.id_procedimento,
                  idTipoProcedimento: ~~element.id_tipo_procedimento,
                  preAgendamentoId: ~~element.pre_agendamento_id,
                  observacao: element.observacao,
                  status: element.confirmado_st || 0,
                  procedimentoAdicionalDescricao:
                    element.procedimento_adicional_descricao,
                });
              }
            }
          },
          onError: (error) => {
            console.error(error);
            this.$_ACTIONS_showSnackbarMessage({
              message: this.$global.messages.internalServerError,
              color: "error",
            });
          },
          onEnd: () => {
            this.loading = false;
          },
        },
        1,
        this.data_consultas
      );
    },
    iniciarConsulta(e) {
      this.info = e;
      this.modalConsulta = true;
    },
    cancelarConsulta() {
      this.modalConsulta = false;
      this.info = {};
      this.compromissos = [];
      this.atualizarCompromissos();
    },
    finalizarConsulta(e) {
      this.modalConsulta = false;
      this.$_ACTIONS_showSnackbarMessage({
        message: e.msg,
        color: e.cor,
      });
      this.$router.replace({ name: "prontuarios", params: { search: e.nome } });
      this.info = {};
      this.compromissos = [];
    },
    ativarModalAtrasoPaciente(event) {
      this.idAgendamentoSelecionado = event.idAgendamentoSelecionado;
      this.modalAtrasoPaciente = true;
    },
    moverPacienteParaListaDeAtrasados() {
      for (let i = 0, len = this.compromissos.length; i < len; i++) {
        let compromisso = this.compromissos[i];
        if (compromisso.idAgendamento === this.idAgendamentoSelecionado) {
          compromisso.emAtraso = true;
          this.idAgendamentoSelecionado = null;
          break;
        }
      }
      this.modalAtrasoPaciente = false;
    },
    removerPacienteDaListaDeAtrasados(event) {
      for (let i = 0, len = this.compromissos.length; i < len; i++) {
        let compromisso = this.compromissos[i];
        if (compromisso.idAgendamento === event.idAgendamento) {
          compromisso.emAtraso = false;
          break;
        }
      }
    },
  },
};
</script>

<style scoped>
.titulo-cards-fila span {
  margin-right: 1rem;
  font-size: 1rem;
}

.titulo-cards-fila hr {
  display: block;
  width: 100%;
  color: #fff;
  margin: 0;
}

/* dashboard nova */
</style>
