<template>
  <div class="account-pages w-100 mt-5 mb-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6 col-xl-5">
          <div class="card shadow">
            <div class="card-body p-4">
              <div class="text-center mb-4">
                <a href="index.html">
                  <span>
                    <img
                      src="@/assets/images/logo-login.png"
                      alt=""
                      height="200px"
                    />
                  </span>
                </a>
              </div>

              <div class="pt-2 form-login">
                <div class="form-group mb-3">
                  <label for="EmailUsuario">Usuário</label>
                  <input
                    class="form-control"
                    v-model="email"
                    type="email"
                    id="EmailUsuario"
                    required=""
                    placeholder="Escreva seu email"
                  />
                </div>

                <div class="form-group mb-3">
                  <label for="SenhaUsuario">Senha</label>
                  <input
                    class="form-control"
                    v-model="senha"
                    type="password"
                    required=""
                    id="SenhaUsuario"
                    placeholder="Senha"
                  />
                  <a
                    class="text-muted float-right"
                    @click="esqueceu_senha"
                    ><small>Esqueceu sua senha?</small></a
                  >
                </div>
                
                <br><br>

                <div class="form-group mb-0 text-center">
                  <button
                    class="btn btn-app-primary btn-block btn-rounded width-lg font-weight-bold"
                    @click.prevent="login"
                  >
                    <span v-if="!loading"> Entrar </span>
                    <v-progress-circular
                      v-else
                      indeterminate
                      color="white"
                    ></v-progress-circular>
                  </button>
                  <div class="justify-content-md-center mt-2">
                    <button
                      v-if="this.$_GETTER_perfil.description === 'paciente'"
                      class="btn btn-app-primary btn-block btn-rounded width-lg font-weight-bold"
                      @click="nova_conta"
                    >
                      Criar Nova Conta
                    </button>
                    <button
                      class="btn btn-outline-app-primary btn-block btn-rounded width-lg font-weight-bold"
                      @click="voltar"
                    >
                      Voltar
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!-- end card-body -->
          </div>
          <!-- end card -->
        </div>
        <!-- end col -->
      </div>
      <!-- end row -->
    </div>
    <!-- end container -->
  </div>
</template>

<script>
import AuthService from "@/services/auth_service";
import Mixin from "@/mixins/vuex_mixin.js";
import DadosCadastraisMixin from '@/mixins/dados_cadastrais_mixin.js';
export default {
  mixins: [Mixin, DadosCadastraisMixin],
  data: () => ({
    email: "",
    senha: "",
    loading: false,
    formValid: false,
    formLogin: {},
  }),
  beforeMount() {
    this.$_ACTIONS_setUsuario({});
    this.$_ACTIONS_setToken("");
  },
  mounted() {
    this.formLogin = document.forms[0];
  },
  methods: {
    esqueceu_senha() {
      this.$router.replace("esqueceu-senha");
    },
    nova_conta() {
      this.$router.replace("novo-cadastro");
    },
    voltar() {
      this.$_ACTIONS_perfil({id:0, description:"home"});
      this.$router.replace("home");
    },
    login() {
      this.loading = true;
      const usuario = {
        email: this.email,
        senha: this.senha,
      };
      const authService = new AuthService();
      authService.login(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.$_ACTIONS_setToken(body.data.token);
              this.$_ACTIONS_setUsuario({id: body.data.id_usuario});
              this.DADOS_CADASTRAIS_MIXIN_getDadosCadastrais();
              this.$router.replace("principal");
            } else {
              this.$_ACTIONS_showSnackbarMessage({
                message: body.message || this.$global.messages.internalServerError,
                color: "error",
              });
            }
          },
          onError: (error) => {
            console.error(error);
            this.$_ACTIONS_showSnackbarMessage({
              message: this.$global.messages.internalServerError,
              color: "error",
            });
          },
          onEnd: () => {
            this.loading = false;
          },
        },
        usuario, 
        this.$_GETTER_perfil.description
      );
    },
  },
};
</script>