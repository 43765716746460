<template>
  <div class="d-flex flex-wrap col-12">
    <div class="d-flex flex-colunm col-12">
     <v-text-field
        class="col-10"
        v-model="pergunta.texto_pergunta"
        :required="pergunta.obrigatorio"
        :messages="pergunta.obrigatorio?'obrigatória':'não obrigatória'"
        :disabled="disabled"
      ></v-text-field>
      <v-switch
          class="col-2"
          v-model="mudar_obrigatoria"
          label="mudar obrigatoriedade?"
          :disabled="disabled"
        ></v-switch>
    </div>
    <div class="d-flex flex-wrap col-12">
      <div
        v-for="(opt_check, i) in opts_check"
        :key="i"
      >
        <div class="d-flex">
          <v-checkbox></v-checkbox>
          <v-text-field 
            :disabled="disabled"
            v-model="opt_check.descricao"
          ></v-text-field>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Mixin from "@/mixins/vuex_snackbar_mixin.js";

export default {
  mixins: [Mixin],
  props:{
    pergunta:Object,
    disabled:Boolean
  },
  beforeMount(){
    this.mudar_obrigatoria = this.pergunta.obrigatorio
    this.opts_check = Object.values(this.pergunta.opcoes)
  },
  data: () => ({
    mudar_obrigatoria:null,
    opts_check:[],
  }),
  watch:{
    mudar_obrigatoria(value){
      this.pergunta.obrigatorio = value
    },
  }
};
</script>
<style scoped>
.v-progress-circular {
  margin: 1rem;
}
</style>