import CEPService from "../services/cep_service"

export default {
    install: function (Vue) {

        // 1. add global method or property
        // Vue.myGlobalMethod = ...

        // 2. add a global asset
        // Vue.directive('my-directive', {})

        // 3. add an instance method
        // Esse é o método assíncrono, novo e documentado para buscar cep. Deve ser usado em detrimento do outro.
        Vue.prototype.$pesquisarCep = async function (cep) {
            const cepSemMascara = cep.replace(/\D/, '');
            return CEPService.build().buscarCep(cepSemMascara);
        }

        // Esse é o método legado de busca de cep. Seu uso deve ser evitado. Deixei aqui por questões de compatibilidade e evitar novos bugs.
        Vue.prototype.$buscarCep = (obj, cep) => {
            const service  = new CEPService()
            service.buscaCep({
                onSucess: (status) => (body) => {
                    if(status === 200){
                        obj.logradouro = body.logradouro
                        obj.uf = body.uf
                        obj.cidade = body.localidade
                        obj.bairro = body.bairro
                        obj.complemento = body.complemento
                        obj.estado = body.estado
                    }

                },

                onError: function () {
                    //
                },

                onEnd: function () {
                    //
                }
            }, cep)
        }
    }
}
