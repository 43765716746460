<template>
  <div class="d-flex flex-column w-100">
    <p>PARA:<span class="space">_</span>{{ nome }}</p>
    <p><b>Solicito:</b></p>
    <u v-for="(exame, i) in exames" :key="i">
      <li class="text-nowrap resp">{{ i + 1 }} {{ exame }}</li>
    </u>
    <div>
      <p>OBS.:<span class="space">_</span>{{ obs }}</p>
      <p v-if="obs2 != ''">OBS.:<span class="space">_</span>{{ obs2 }}</p>
      <p class="d-flex flex-column ml-auto">
        <span> _________________________________________</span>
        <span class="ml-2">Luiz Nestor Vasconcelos Rodrigues </span>
        <span class="ml-1 resp">Cirurgião Plástico - CRM 6110 - RQE 2395</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "documento_exame",
  props: {
    nome: {required: true},
    obs: {required: true},
    obs2: {required: true},
    exames: {required: true},
  }
}
</script>

<style scoped>

</style>
