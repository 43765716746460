<template>
  <div>
    <v-form class="p-lg-3 row" ref="form" v-model="valid" lazy-validation>
      <div class="col-lg-12 row">
          <!-- CAMPO DE DATA INÍCIO -->
          <div class="col-lg-3 form-group clearfix mb-3">
            <v-text-field
              label="Data Início"
              :error-messages="error.dataInicio"
              v-model="dataInicio"
              :rules="validationDataInicio"
              clear-icon="clear"
              required
              style="margin: 0 5px; padding-top: 0px !important"
              :type="'date'"
              :outlined="false"
            />
          </div>
          <!-- CAMPO DE DATA FINAL -->
          <div class="col-lg-3 form-group clearfix mb-3">
            <v-text-field
              label="Data Final"
              :error-messages="erroDataMenorQue"
              v-model="dataFinal"
              :rules="validationDataFinal"
              clear-icon="clear"
              required
              style="margin: 0 5px; padding-top: 0px !important"
              :type="'date'"
              :outlined="false"
            />
          </div>
          <!-- CAMPO DE HORA INÍCIO -->
          <div class="col-lg-3 form-group clearfix mb-3">
            <v-text-field
              label="Hora Início"
              v-model="horaInicio"
              :rules="validationHorarioInicio"
              :type="'text'"
              required
              v-mask="'##:##'"
              placeholder="Hora Início"
              style="padding-top: 0 !important"
            ></v-text-field>
          </div>
          <!-- CAMPO DE HORA FINAL -->
          <div class="col-lg-3 form-group clearfix mb-s3">
            <v-text-field
              label="Hora Final"
              :error-messages="erroHorarioMenorQue"
              v-model="horaFinal"
              :rules="validationHorarioFinal"
              :type="'text'"
              v-mask="'##:##'"
              required
              placeholder="Hora Final"
              style="padding-top: 0 !important"
            ></v-text-field>
          </div>
          <!-- CAMPO DE DIAS DA SEMANA -->
          <div class="col-lg-12 form-group clearfix mb-s3">
            <div class="row pl-3">
              <v-select
                v-model="diasDaSemanaEscolhidos"
                :items="$global.utils.diasDaSemana"
                label="Dias da semana"
                :placeholder="' '"
                multiple
                :rules="validationDiasDaSemana"
                chips
                item-text="label"
                item-value="value"
              >
              </v-select>
            </div>
          </div>
      </div>
      <div class="col-lg-12 row">
          <!-- CAMPO DE TEMPO DE ATENDIMENTO -->
          <div class="col-lg-3 form-group clearfix mt-3">
            <v-text-field
              label="Tempo de Atendimento"
              :error-messages="error.tempoAtendimento"
              v-model="tempoAtendimento"
              :rules="validationTempoAtendimento"
              v-mask="'##:## hrs'"
              :type="'text'"
              required
              placeholder="Tempo de Atendimento"
              style="padding-top: 0 !important"
            ></v-text-field>
          </div>
          <!-- CAMPO DE PROCEDIMENTO -->
          <div class="col-lg-4 form-group clearfix mb-3 text-dark">
            <label for="Procedimento">Procedimento</label>
            <v-select
              v-model="TIPO_PROCEDIMENTO_MIXIN_tipo_procedimento"
              :items="TIPO_PROCEDIMENTO_MIXIN_tipos_procedimento"
              :loading="TIPO_PROCEDIMENTO_MIXIN_loading"
              :rules="validationProcedimento"
              required
              label="Tipo de Procedimento"
              item-text="descricao"
              item-value="id"
              color="black"
              solo
            >
            </v-select>
          </div>
      </div>
      <!-- BOTÃO SUBMIT FORMULÁRIO -->
      <div>
        <v-btn
          class="btn-app-primary"
          @click="criarAgenda"
          :loading="loading"
        >
          <span>Criar Agenda</span>
        </v-btn>
      </div>
    </v-form>
  </div>
</template>
<script>
import AgendaService from "@/services/agenda_service.js";
import ValidationService from "@/services/validation_service.js";
import VuexSnackbarMixin from "@/mixins/vuex_snackbar_mixin.js";
import VuexUsuarioMixin from "@/mixins/vuex_usuario_mixin.js";
import TipoProcedimentoMixin from "@/mixins/tipo_procedimento_mixin";

export default {
  mixins: [VuexSnackbarMixin, VuexUsuarioMixin, TipoProcedimentoMixin],
  beforeMount() {
    this.validationService = ValidationService;
    this.TIPO_PROCEDIMENTO_MIXIN_atualizarLista()
  },
  data: () => ({
    loading: false, // Guarda o estado do loading de submit
    valid: false, // Guarda a variável que guarda o estado da validação do formulário
    procedimentoEscolhido: "", // Guarda o procedimento escolhido a partir da busca
    dataInicio: "",
    dataFinal: "",
    horaInicio: "", 
    horaFinal: "",
    diasDaSemanaEscolhidos: [],
    tempoAtendimento: "",
    procedimentos: [], // Guarda a lista de procedimentos encontrados a partir da busca
    headers: [
      {
        text: "Procedimento",
        value: "procedimento",
        align: "start",
        sortable: true,
      },
      { text: "Horário", value: "horario" },
      { text: "Data", value: "date" },
      { text: "Status", value: "status" },
      { text: "Ações", align: "end" },
    ],
    error: {
      tempoAtendimento: "",
      dataInicio: "",
    },
    validationService: {},
  }),
  computed: {
    erroHorarioMenorQue() {
      const tempoMinimoConsulta = this.$global.consultas.tempoMinimo
      const error = this.validationService.horarioMaiorQue(
        this.horaInicio,
        this.horaFinal,
        tempoMinimoConsulta / 60,
        `A consulta deve ter no mínimo ${tempoMinimoConsulta} minutos`
      )();
      return error === true ? "" : error;
    },
    erroDataMenorQue() {
      const error = this.validationService.dataMaiorQue(
        this.dataInicio,
        this.dataFinal
      )();
      return error === true ? "" : error;
    },
    validationDataInicio () {
      return [
        this.validationService.required('Informe uma data de início.'),
        this.validationService.dataMaiorQue(this.dataInicio, this.dataFinal, 'A data final deve ser maior que a inicial.')
      ]
    },
    validationDataFinal () {
      return [
        this.validationService.required('Informe uma data final.'),
        this.validationService.dataMaiorQue(this.dataInicio, this.dataFinal, 'A data final deve ser maior que a inicial.'),
        this.validationService.diferencaEntreDatas(
            this.dataInicio,
            this.dataFinal,
            90,
            'O cadastro de agenda só pode ser feito em um intervalo de 90 dias.'
        )
      ]
    },
    validationHorarioInicio () {
      return [
        this.validationService.required('Informe uma hora de início.'),
        this.validationService.isHorario24Horas(),
        this.validationService.horarioMaiorQue(this.horaInicio, this.horaFinal)
      ]
    },
    validationHorarioFinal () {
      return [
        this.validationService.required('Informe uma hora final.'),
        this.validationService.isHorario24Horas(),
        this.validationService.horarioMaiorQue(this.horaInicio, this.horaFinal)
      ]
    },
    validationDiasDaSemana () {
      return [
        this.validationService.isNotEmpty('Escolha um dia da semana.'),
      ]
    },
    validationTempoAtendimento () {
      return [
        this.validationService.required('Informe um tempo de atendimento.'),
        this.validationService.isHorario24Horas(),
        this.validationService.horarioMaiorQue(
          '00:00',
          this.tempoAtendimento,
          60 * this.$global.consultas.tempoMinimo,
          `A consulta deve ter, no mínimo, ${this.$global.consultas.tempoMinimo} minutos.`,
          `A consulta deve ter, no mínimo, ${this.$global.consultas.tempoMinimo} minutos.`
        ),
      ]
    },
    validationProcedimento () {
      return [
        this.validationService.required('Informe um tipo de procedimento.')
      ]
    },
  },
  methods: {
    criarAgenda() {
      if (!this.$refs.form.validate() || !this.valid) {
        return;
      }
      this.loading = true;
      (new AgendaService()).criarAgendaMultiplo(
        {
          onSucess: (status) => (body) => {
            this.$emit('response')
            this.$_ACTIONS_showSnackbarMessage({
              message: body.message || this.$global.messages.internalServerError,
              color: status === 200 ? 'sucess' : 'error'
            })
            this.$refs.form.reset();
          },
          onError: () => {
            this.$_ACTIONS_showSnackbarMessage({
              message: this.$global.messages.internalServerError,
              color: 'error'
            })
          },
          onEnd: () => {
            this.loading = false;
          },
        },
        {
          id_medico: 1,
          data_inicial: this.dataInicio,
          data_final: this.dataFinal,
          hora_inicio: this.horaInicio,
          hora_fim: this.horaFinal,
          tempo_atendimento: this.tempoAtendimento.replace(" hrs", "").trim(),
          id_tipo_procedimento: this.TIPO_PROCEDIMENTO_MIXIN_tipo_procedimento,
          dias_semana: (() => {
            const obj = {};
            for (let i=0; i < 7; i++){
              obj[i] = this.diasDaSemanaEscolhidos.includes(i) ? 1 : 0
            }
            return obj;
          })(),
          adicionado_por: this.$_GETTERS_usuario.id,
        }
      );
    },
  },
};

</script>

<style scoped>
.btn-app-primary {
  background-color: rgb(62, 104, 42) !important;
  border-color: rgb(62, 104, 42);
  color: white;
}
.form-group label {
  text-align: left !important;
  display: block;
}
</style>