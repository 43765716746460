<template>
  <div>
    <div class="p-lg-3">
      <div class="row justify-content-between">
        <h3 class="mt-0 mb-2 font-weight-bold text-left">Prontuários</h3>
      </div>
    </div>

    <v-data-table
      v-on:update:sort-by="options.ordenacao = true"
      :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
      :search="search"
      :headers="headers"
      :items="pacientes"
      :options.sync="options"
      :server-items-length="totalList"
      sort-by="nome"
      class="elevation-1"
      :loading="loading"
      :loading-text="'Carregando...'"
    >
      <template v-slot:top>
        <v-toolbar flat max-width="100%">
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Pesquisar"
            class="col-5"
            single-line
            hide-details
          ></v-text-field>

          <!-- DIALOG FOTOS CLASSIFICADAS -->
          <v-dialog
            v-model="dialogFotosClassificadas"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
          >
            <fotos-classificadas
              :paciente="paciente.id_paciente"
              :key="rederComp"
              @close="close()"
            />
          </v-dialog>
          <!-- DIALOG DE PRONTUÁRIO -->
          <v-dialog
            v-model="dialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
          >
            <prontuarios :consultas="prontuarios" @close="close()" />
          </v-dialog>

          <!-- DIALOG DE PRONTUÁRIO -->
          <v-dialog
            v-model="dialogGaleria"
            fullscreen
            hide-overlay
            persistent
            transition="dialog-bottom-transition"
          >
            <galerias :paciente="paciente" :key="rederComp" @close="close()" />
          </v-dialog>
          <v-dialog v-model="dialogLoading" hide-overlay persistent width="300">
            <v-card color="white">
              <v-card-text>
                Carregando...
                <v-progress-linear
                  indeterminate
                  color="green lighten-3"
                  class="mb-0"
                ></v-progress-linear>
              </v-card-text>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="dialogArquivo"
            fullscreen
            hide-overlay
            persistent
            transition="dialog-bottom-transition"
          >
            <arquivo :paciente="paciente" :key="rederComp" @close="close()" />
          </v-dialog>
          <v-dialog
            v-model="dialogAtestado"
            hide-overlay
            persistent
            width="50%"
          >
            <atestado @close="close()" @gerarPDF="gerarPDF($event)" />
          </v-dialog>
          <v-dialog
            v-model="dialogExames"
            hide-overlay
            persistent
            style="width: 50%"
          >
            <exames @close="close()" @gerarPDF="gerarPDF($event)" />
          </v-dialog>
          <v-dialog
            v-model="dialogEncaminhamento"
            hide-overlay
            persistent
            width="30%"
          >
            <encaminhamento @close="close()" @gerarPDF="gerarPDF($event)" />
          </v-dialog>
          <v-dialog v-model="dialogReceita" hide-overlay persistent width="50%">
            <receita @close="close()" @gerarPDF="gerarPDF($event)" />
          </v-dialog>
          <v-dialog v-model="dialogTermo" hide-overlay persistent width="40%">
            <termos @close="close()" @gerarPDF="gerarPDF($event)" />
          </v-dialog>
          <v-dialog
            v-model="dialogReceitaCarbonada"
            hide-overlay
            persistent
            width="50%"
          >
            <receita-carbonada @close="close()" @gerarPDF="gerarPDF($event)" />
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" title="Atestado" @click="gerarAtestado(item)"
          >fas fa-file-medical
        </v-icon>
        <v-icon
          small
          class="mr-2"
          title="Encaminhamento Médico"
          @click="gerarEncaminhamento(item)"
          >fas fa-user-md</v-icon
        >
        <v-icon
          small
          class="mr-2"
          title="Solicitar exames"
          @click="gerarSolicitacaoDeExame(item)"
          >fas fa-tasks
        </v-icon>
        <v-icon
          small
          class="mr-2"
          title="Receita Novas"
          @click="gerarReceitasNovas(item)"
          >fas fa-capsules
        </v-icon>
        <v-icon
          small
          class="mr-2"
          title="Receita Carbonada"
          @click="gerarReceitasCarbonada(item)"
          >fas fa-file-alt
        </v-icon>
        <v-icon
          small
          class="mr-2"
          title="Termo de responsabilidade"
          @click="gerarTermos(item)"
          >fas fa-file-signature</v-icon
        >
        <v-icon
          small
          class="mr-2"
          title="Galeria de fotos"
          @click="listGalerias(item)"
          >fas fa-images
        </v-icon>
        <v-icon
          small
          class="mr-2"
          title="Fotos classificadas"
          @click="listFotosClassificadas(item)"
          >fa-solid fa-bookmark
        </v-icon>
        <v-icon small class="mr-2" title="Documentos" @click="listFile(item)"
          >fas fa-folder
        </v-icon>
        <v-icon
          small
          class="mr-2"
          title="Prontuários"
          @click="listProntuarios(item)"
          >fas fa-file-medical-alt
        </v-icon>
      </template>
    </v-data-table>

    <documentos-pdf
      :htmlToPdf.sync="htmlToPdf"
      :arquivos.sync="arquivos"
      :dialogArquivo.sync="dialogArquivo"
      :dialogAtestado.sync="dialogAtestado"
      :nome.sync="nome"
      :dias.sync="dias"
      :codigoCFM.sync="codigoCFM"
      :dialogEncaminhamento.sync="dialogEncaminhamento"
      :nomeMedico.sync="nomeMedico"
      :dialogExames.sync="dialogExames"
      :exames.sync="exames"
      :obs.sync="obs"
      :obs2.sync="obs2"
      :dialogReceita.sync="dialogReceita"
      :receita.sync="receita"
      :dialogReceitaCarbonada.sync="dialogReceitaCarbonada"
      :logradouro.sync="logradouro"
      :numero.sync="numero"
      :complemento.sync="complemento"
      :dialogTermo.sync="dialogTermo"
      :tipoTermo.sync="tipoTermo"
      :procedimento.sync="procedimento"
      :cpf.sync="cpf"
    />
  </div>
</template>

<script>
import Atestado from "@/components/documentos/atestado.vue";
import Encaminhamento from "@/components/documentos/encaminhamento.vue";
import Exames from "@/components/documentos/exames.vue";
import Receita from "@/components/documentos/receita.vue";
import ReceitaCarbonada from "@/components/documentos/receita_carbonada.vue";
import Termos from "@/components/documentos/termos.vue";
import Galerias from "@/components/prontuarios/galeria.vue";
import Arquivo from "@/components/prontuarios/arquivos.vue";
import Prontuarios from "@/components/prontuarios/prontuarios.vue";
import ProfissionalService from "@/services/profissional_service.js";
import ConsultaService from "@/services/consulta_service.js";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import FotosClassificadas from "@/components/prontuarios/fotos_classificadas.vue";
import DocumentosPdf from "../components/documentos/pdfs/documentosPdf";

export default {
  components: {
    DocumentosPdf,
    Prontuarios,
    Galerias,
    Arquivo,
    Atestado,
    Exames,
    Encaminhamento,
    Receita,
    ReceitaCarbonada,
    Termos,
    FotosClassificadas,
  },
  beforeMount() {
    if (this.$route.query.search) {
      this.search = this.$route.params.search;
    }
    if (this.$router.params.paciente) {
      this.buscarEspecifico = true;
    }
  },

  data: () => ({
    rederComp: 0,
    dialog: false,
    loading: false,
    dialogLoading: false,
    search: "",
    totalList: 10,
    buscarEspecifico: false,
    options: {
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      ordenacao: false,
      desc: false,
    },
    headers: [
      {
        text: "Nome",
        align: "start",
        sortable: true,
        value: "nome",
      },
      { text: "CPF", value: "cpf" },
      { text: "Email", value: "email" },
      { text: "Ações", align: "end", value: "actions", sortable: false },
    ],
    dialogCadastro: false,
    dialogGaleria: false,
    dialogFotosClassificadas: false,
    paciente: {},
    visualizar: false,
    pacientes: [],
    prontuarios: [],
    htmlToPdf: "",
    // Documentos
    arquivos: [],
    dialogArquivo: false,
    // Atestado
    dialogAtestado: false,
    nome: "",
    dias: "",
    codigoCFM: "",
    //Encaminhamento
    dialogEncaminhamento: false,
    nomeMedico: "",
    //Solicitar Exame
    dialogExames: false,
    exames: [],
    obs: "",
    obs2: "",
    //Receita Nova
    dialogReceita: false,
    receita: "",
    //Receita Carbonada
    dialogReceitaCarbonada: false,
    logradouro: "",
    numero: "",
    complemento: "",
    //Termos
    dialogTermo: false,
    tipoTermo: "",
    procedimento: "",
    cpf: "",
  }),

  watch: {
    dialog(val) {
      val || this.close();
    },
    search() {
      this.atualizarListaPacientes();
    },
    options: {
      handler() {
        this.options.desc = this.options.sortDesc[0] ?? false;
        this.atualizarListaPacientes();
      },
      deep: true,
    },
  },

  mounted() {
    if (this.$route.query.search) {
      this.search = this.$route.query.search;
      this.atualizarListaPacientes();
    }
    this.atualizarListaPacientes();
  },
  methods: {
    forceRender() {
      this.rederComp += 1;
    },
    atualizarListaPacientes() {
      const profissionalService = new ProfissionalService();
      this.loading = true;
      this.pacientes = [];
      const onSucess = (status) => (body) => {
        if (status === 200) {
          this.totalList = body.data.pagination.num_rows;

          this.pacientes = body.data.pacientes.map((paciente) => {
            return paciente;
          });

          if (this.buscarEspecifico) {
            this.pacientes = this.pacientes.filter(
              (paciente) => paciente.id == this.$router.params.paciente.id
            );
          }
        } else {
          console.log(body);
        }
      };
      const onError = (error) => {
        console.log(error);
      };
      const onEnd = () => {
        this.loading = false;
      };

      this.search = this.buscarEspecifico
        ? this.$router.params.paciente.nome
        : this.search;

      profissionalService.getPacienteList(
        onSucess,
        onError,
        onEnd,
        this.options.page,
        this.options.itemsPerPage,
        this.search,
        this.options.ordenacao,
        this.options.desc
      );

      if (this.buscarEspecifico) {
        this.buscarEspecifico = false;
      }
    },
    gerarAtestado(item) {
      this.nome = item.nome;
      this.dialogAtestado = true;
    },
    gerarEncaminhamento(item) {
      this.nome = item.nome;
      this.dialogEncaminhamento = true;
    },
    gerarSolicitacaoDeExame(item) {
      this.nome = item.nome;
      this.dialogExames = true;
    },
    gerarReceitasNovas(item) {
      this.nome = item.nome;
      this.dialogReceita = true;
    },
    gerarReceitasCarbonada(item) {
      console.log(item);
      this.nome = item.nome;
      this.cpf = item.cpf;
      this.logradouro = item.logradouro;
      this.numero = item.numero;
      this.complemento = item.complemento;
      this.dialogReceitaCarbonada = true;
    },
    gerarTermos(item) {
      this.nome = item.nome;
      this.cpf = item.cpf;
      this.dialogTermo = true;
    },
    gerarData() {
      const meses = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novovembro",
        "Dezembro",
      ];
      let data = new Date();
      return {
        dia: data.getDate(),
        mes: meses[data.getMonth()],
        ano: data.getFullYear(),
      };
    },

    gerarPDF(info) {
      console.log({ info });
      switch (info.doc) {
        case "atestado":
          this.htmlToPdf = info.doc;
          this.dias = info.dias;
          this.codigoCFM = info.codigoCFM;
          this.$nextTick(() => {
            this.close();
            this.criarPDF(
              document.querySelector("#examesPDF").innerHTML,
              "Atestado",
              "padrao"
            );
          });
          break;
        case "termo":
          this.htmlToPdf = info.doc;
          this.tipoTermo = info.termo_tipo;
          this.procedimento = info.procedimento;
          this.$nextTick(() => {
            this.close();
            this.criarPDF(
              document.querySelector("#examesPDF").innerHTML,
              "Termo de Responsabilidade",
              "padrao"
            );
          });
          break;
        case "receita":
          this.htmlToPdf = info.doc;
          this.receita = info.receita;
          this.$nextTick(() => {
            this.close();
            this.criarPDF(
              document.querySelector("#examesPDF").innerHTML,
              "Receita",
              "padrao"
            );
          });
          break;
        case "receita-carbonada":
          this.htmlToPdf = info.doc;
          this.receita = info.receita;

          console.log(this.receita);
          //for (let i = 0; i < this.receita.length; i++) {
          this.$nextTick(() => {
            this.close();
            this.criarPDF(
              document.querySelector("#examesPDF").innerHTML,
              "Receita-carbonada",
              "carbonada"
            );
          });
          //}
          break;
        case "encaminhamento":
          this.htmlToPdf = info.doc;
          this.nomeMedico = info.nomeMedico;
          this.$nextTick(() => {
            this.close();
            this.criarPDF(
              document.querySelector("#examesPDF").innerHTML,
              "Encaminhamento",
              "padrao"
            );
          });
          break;
        case "exames":
          this.htmlToPdf = info.doc;
          this.exames = info.exames;
          this.obs = info.obs;
          this.obs2 = info.obs2;
          this.$nextTick(() => {
            this.close();
            this.criarPDF(
              document.querySelector("#examesPDF").innerHTML,
              "Solicitação de exames",
              "padrao"
            );
          });
          break;

        default:
          console.log("Nenhum tipo de documento encontrado");
          break;
      }
    },
    criarPDF(content, nome, tipo) {
      console.log("tipo: " + tipo);
      window.html2canvas = html2canvas;
      if (tipo == "padrao") {
        const doc = new jsPDF("p", "mm", "a4", true);
        doc.html(content, {
          callback: function(pdf) {
            pdf.save(nome + ".pdf");
          },
          x: 5,
          y: 5,
        });
      } else if (tipo == "carbonada") {
        const docm = new jsPDF("p", "mm", "a4");
        docm.html(content, {
          callback: function(pdf) {
            pdf.save(nome + ".pdf");
          },
          x: 1,
          y: 1,
        });
      } else {
        const docm = new jsPDF("l", "mm", "a4");
        docm.html(content, {
          callback: function(pdf) {
            pdf.save(nome + ".pdf");
          },
          x: 1,
          y: 1,
        });
      }
    },

    listProntuarios(item) {
      this.paciente = item;
      this.dialogLoading = true;
      const consultaService = new ConsultaService();
      consultaService.getListByPacienteId(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.prontuarios = body.data.consultas;
              this.dialog = true;
            }
          },
          onError: (error) => {
            console.log(error);
          },
          onEnd: () => {
            this.dialogLoading = false;
          },
        },
        this.paciente.id_paciente
      );
    },

    listFile(item) {
      console.log(item);
      this.paciente = item;
      this.dialogArquivo = true;
      this.forceRender();
    },

    listGalerias(item) {
      this.paciente = item;
      this.dialogGaleria = true;
      this.forceRender();
    },

    listFotosClassificadas(item) {
      this.paciente = item;
      this.dialogFotosClassificadas = true;
      this.forceRender();
    },

    close() {
      this.dialog = false;
      this.dialogGaleria = false;
      this.dialogFotosClassificadas = false;
      this.dialogAtestado = false;
      this.dialogEncaminhamento = false;
      this.dialogExames = false;
      this.dialogReceita = false;
      this.dialogReceitaCarbonada = false;
      this.dialogTermo = false;
      this.dialogArquivo = false;
      this.forceRender();
    },
  },
};
</script>
<style scoped>
#examesPDF {
  text-align: center;
}

p {
  letter-spacing: 0.5px;
}

#header,
#footer,
#main {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  margin-left: auto;
  width: 12.5rem;
  margin-right: auto;
}
#header {
  height: 1rem;
  /* background-color: red; */
}
#header img {
  height: 1rem;
  width: 2.5rem;
}
#main {
  display: flex;
  justify-content: center;
  align-content: center;
  font-size: 4px;
  height: 15.8rem;
  max-height: 17rem;
  /* width: 13vw; */
  padding: 1rem;
  /* background-color: tomato; */
}
#footer {
  /* background-color: rebeccapurple; */
  margin-top: 0.1rem;
  margin-bottom: 0;
  margin-right: 0.1rem;
  height: 1.2rem;
}
#footer img {
  max-width: 12.5rem;
  max-height: 1.2rem;
}

.carbonada {
  font-size: 3px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  /* background-color: rgb(233, 255, 255); */
  margin-left: 6rem;
  padding-top: 0px;
}

.texto {
  padding: 1px;
  font-size: 5px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.space {
  color: white;
}
.resp {
  font-size: 3px !important;
  margin-bottom: 20px;
}
.indent {
  text-indent: 5px;
}
</style>
