<template>
    <div>
      <div class="p-lg-3">
        <div class="row justify-content-between">
          <div class="d-flex justify-content-between w-100">
            <h3 class="mt-0 mb-2 font-weight-bold text-left">Agendamento</h3>
            <div class="d-flex">
              <div class="d-flex ml-5">
                <i class="mdi mdi-brightness-1 mr-1" style="color: grey"></i>
                <span>Horário Indisponível</span>
              </div>
              <div class="d-flex ml-5">
                <i class="mdi mdi-brightness-1 mr-1" style="color: #fbc02d"></i>
                <span>Pré-agendamento</span>
              </div>
              <div class="d-flex ml-5">
                <i class="mdi mdi-brightness-1 mr-1" style="color: purple"></i>
                <span>Confirmado</span>
              </div>
              <div class="d-flex ml-5">
                <i class="mdi mdi-brightness-1 mr-1" style="color: #3E682A"></i>
                <span>Atendido</span>
              </div>
              <div class="d-flex ml-5">
                <i class="mdi mdi-brightness-1 mr-1" style="color: #188ae2;"></i>
                <span>Pendente</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <!-- Calendario -->
      <v-row class="fill-height">
        <v-col  v-if=" this.$_GETTER_perfil.description !== 'paciente'">
          <calendario/>
        </v-col>
        <v-col v-else>
          <calendario-paciente/>
        </v-col>
      </v-row>
    </div>
</template>

<script>

import Calendario from '@/components/agendamentos/calendario.vue'
import CalendarioPaciente from '../components/agendamentos/calendario_paciente.vue'
import Mixin from '@/mixins/vuex_mixin.js'

export default {
  components: { Calendario,CalendarioPaciente  },
  mixins:[Mixin],
  data: () => ({})
}
</script>

<style scoped>
  .nestor-color {
    background-color: #3E682A !important;
  }
</style>

