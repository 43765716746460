<template>
  <div class="w-100">
    <template>
      <v-row
        class="mx-0 my-3"
        style="
          background-color: #efefef;
          border-radius: 0 4px 4px 0;
        "
      >
        <v-col cols="3" lg="3" md="5" class="p-0">
          <div
            class="px-2 rounded"
            :style="{ borderLeftColor: cor }"
            style="
              border-left-style: solid;
              border-left-width: 6px;
            "
          >
            <v-list-item-content class="pb-0 pt-1">
              <h6 class="text-h6 text-left my-0">
                {{ item.hora_inicio }}
              </h6>
            </v-list-item-content>
            <p class="text-left mb-0">Duração:</p>
            <v-list-item-subtitle
              class="text-subtitle text-left pb-1"
            >
              <p class="mb-0 tempo-procedimento">
                {{
                  item.hora_inicio +
                  " - " +
                  item.hora_final
                }}
              </p>
            </v-list-item-subtitle>
          </div>
        </v-col>
        <v-col cols="9" lg="9" md="7" class="p-0">
          <div class="px-2">
            <v-list-item-content class="pb-0">
              <h4 class="text-left my-0 consultaPaciente">
                {{ item.nome }}
              </h4>
              <v-list-item-subtitle class="text--primary text-left">
                <p class="mb-0 descricao-procedimento">{{ item.procedimento }}</p>
              </v-list-item-subtitle>
            </v-list-item-content>
          </div>
        </v-col>
      </v-row>
    </template>
  </div>
</template>
<script>

export default {
  props:{
    item: {type: Object},
    cor: {type: String, required: true},
  },
  beforeMount(){
    console.log(this.item)
  }
}
</script>

<style scoped>
.tempo-procedimento {
  font-size: 0.8rem;
}

.consultaPaciente {
  font-size: 1rem;
}

.descricao-procedimento {
  font-size: 0.8rem;
}
</style>