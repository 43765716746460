<template>
  <div>
    <v-card class="ma-4" elevation="2" color="white">
      <v-card-title>{{ titulo }}</v-card-title>
      <v-card-text>
        <v-list disabled v-if="lista.length > 0">
          <v-list-item-group>
            <template v-for="(item, i) in lista">
              <v-list-item :key="i">
                <card-list :item="item" :cor="cor"></card-list>
              </v-list-item>
            </template>
          </v-list-item-group>
        </v-list>
        <div v-else>
          <p>{{ messageOnEmpty }}</p>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import CardList from '@/components/dashboards/dashboard_assistente/card_list_card.vue'

export default {
  components:{
    CardList
  },
  props:{
    lista: {type: Array, required: true},
    cor: {type: String, required: true},
    titulo: {type: String, required: true},
    id: {type: Number, required: true, validator: str => ['consulta', 'cirurgia', 'compromisso-pessoal'].includes(str)}
  },
  computed: {
    messageOnEmpty() {
      return {
        'consulta': 'Não há consultas marcadas para hoje.',
        'cirurgia': 'Não há cirurgias marcadas para hoje.',
        'compromisso-pessoal': 'Não há compromissos pessoais marcados para hoje.'
      }[this.id];
    }
  }
}
</script>