<template>
  <div>
    <div class="p-5">
      <form class="p-lg-3 row">
        <div class="col-lg-12">
          <div class="mx-auto my-4">
            <h3>Adicionar foto de perfil</h3>
            <picture-input
              buttonClass="btn btn-app-primary btn-round font-weight-bold"
              removeButtonClass="btn btn-danger btn-round font-weight-bold"
              ref="pictureInput"
              @change="trocarImagem"
              width="400"
              height="400"
              margin="16"
              accept="image/jpeg,image/png"
              size="10"
              :removable="true"
              :customStrings="{
                upload:
                  '<p> Seu dispositivo não oferece suporte para upload de arquivos. </p> ',
                drag:
                  '<h3>Arraste uma imagem ou <br> clique aqui para selecionar um arquivo.</h3>',
                tap:
                  'Toque aqui para selecionar uma imagem <br> de sua galeria.',
                change: 'Trocar imagem',
                remove: 'Remover imagem',
                select: 'Selecione uma imagem',
                selected: '<p>Foto selecionada com sucesso!</p>',
                fileSize: 'O tamanho do arquivo excede o limite',
                fileType: 'Este tipo de arquivo não é compatível.',
              }"
            >
            </picture-input>
          </div>
        </div>
        <div class="col-lg-6 form-group clearfix mb-3">
          <label for="nome">Nome Completo</label>
          <v-text-field
            :error-messages="error.nome"
            v-model="nome"
            :rules="[rules.required]"
            :type="'text'"
            placeholder="Seu nome completo"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-6 form-group clearfix mb-3">
          <label for="emailCadastroUsuario">Email</label>
          <v-text-field
            :error-messages="error.email"
            v-model="email"
            :rules="[rules.required, rules.email]"
            :type="'email'"
            placeholder="Escreva seu email"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-3 form-group clearfix mb-3">
          <label for="Senha">Senha</label>
          <v-text-field
            :error-messages="error.senha"
            v-model="senha"
            background-color="grey lighten-2"
            :type="'password'"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>
        

        <div class="col-lg-3 form-group clearfix mb-3">
          <label for="Telefone01">Telefone 1:</label>
          <v-text-field
            v-mask="'(##)#####-####'"
            :error-messages="error.telefone_1"
            v-model="telefone_1"
            :type="'text'"
            placeholder="(99) 99999-9999"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-3 form-group clearfix mb-3">
          <label for="Telefone01">Telefone 2:</label>
          <v-text-field
            v-mask="'(##)#####-####'"
            :error-messages="error.telefone_2"
            v-model="telefone_2"
            :type="'text'"
            placeholder="(99) 99999-9999"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-3 form-group clearfix mb-3">
          <label for="CPF">CPF</label>
          <v-text-field
            v-mask="'###.###.###-##'"
            :error-messages="error.cpf"
            v-model="cpf"
            :type="'text'"
            placeholder="000.000.000-00"
            style="padding-top: 0 !important"
          ></v-text-field>
          <span class="text-muted">ex: "123.456.7890-12"</span>
        </div>
        <div class="col-lg-3 form-group clearfix mb-3">
          <label for="RG">RG</label>

          <v-text-field
            :error-messages="error.rg"
            v-model="rg"
            :type="'text'"
            placeholder="00.000.000-0"
            style="padding-top: 0 !important"
          ></v-text-field>

          <span class="text-muted">ex: "xx.xxx.xxx-x"</span>
        </div>
        <div class="col-lg-3 form-group clearfix mb-3">
          <label>Data de nascimento</label>

          <v-text-field
            :error-messages="error.data_nascimento"
            v-model="dataNascimento"
            clear-icon="clear"
            style="margin: 0 5px; padding-top: 5px !important"
            :type="'date'"
            :outlined="false"
          />

          <span class="text-muted">ex: "Dia/Mês/Ano"</span>
        </div>
        <div class="col-lg-3 form-group clearfix mb-3">
          <label class="">Gênero</label>
          <v-radio-group :error-messages="error.genero" v-model="genero">
            <v-radio label="Masculino" value="M" />
            <v-radio label="Feminino" value="F" />
            <v-radio label="Outros" value="O" />
          </v-radio-group>
        </div>
        <div class="col-lg-3 form-group clearfix mb-3">
          <label for="Estado-civil">Estado civil</label>

          <v-text-field
            :error-messages="error.estadoCivil"
            v-model="estadoCivil"
            clear-icon="clear"
            style="margin: 0 5px; padding-top: 5px !important"
            :type="'text'"
            :outlined="false"
          />

          <span class="text-muted"
            >ex: Casado, Solteiro, Divorciado, Viúvo"</span
          >
        </div>
        <div class="col-lg-3 form-group clearfix mb-3">
          <label for="Estado-civil">Profissão</label>

          <v-text-field
            :error-messages="error.profissao"
            v-model="profissao"
            clear-icon="clear"
            style="margin: 0 5px; padding-top: 5px !important"
            :type="'text'"
            :outlined="false"
          />
        </div>
        <div class="col-lg-3 form-group clearfix mb-3">
          <label for="Estado-civil">Indicado por</label>

          <v-text-field
            :error-messages="error.indicado_por"
            v-model="indicado_por"
            clear-icon="clear"
            style="margin: 0 5px; padding-top: 5px !important"
            :type="'text'"
            :outlined="false"
          />
        </div>
        <div class="col-lg-3 form-group clearfix mb-3">
          <label for="CEP">CEP</label>

          <v-text-field
            v-mask="'#####-###'"
            :error-messages="error.cep"
            v-model="cep"
            :type="'text'"
            placeholder="00000-000"
            style="padding-top: 0 !important"
          ></v-text-field>

          <span class="text-muted">ex: "xxxxx-xxx"</span>
        </div>
        <div class="col-lg-3 form-group clearfix mb-3">
          <label for="Rua">Logradouro</label>

          <v-text-field
            :error-messages="error.logradouro"
            v-model="logradouro"
            background-color="grey lighten-2"
            :type="'text'"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>
        <div class="col-lg-3 form-group clearfix mb-3">
          <label for="Bairro">Bairro</label>

          <v-text-field
            :error-messages="error.bairro"
            v-model="bairro"
            background-color="grey lighten-2"
            :type="'text'"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>
        <div class="col-lg-3 form-group clearfix mb-3">
          <label for="Numero">Numero</label>

          <v-text-field
            :error-messages="error.numero"
            v-model="numero"
            :type="'text'"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>
        <div class="col-lg-3 form-group clearfix mb-3">
          <label for="Cidade">Cidade</label>

          <v-text-field
            :error-messages="error.cidade"
            v-model="cidade"
            background-color="grey lighten-2"
            :type="'text'"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>
        <div class="col-lg-3 form-group clearfix mb-3">
          <label for="Estado">Estado</label>
          <v-text-field
            :error-messages="error.estado"
            v-model="estado"
            background-color="grey lighten-2"
            :type="'text'"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>
        <div class="col-lg-6 form-group clearfix mb-3">
          <label for="Complemento">Complemento</label>

          <v-text-field
            :error-messages="error.complemento"
            v-model="complemento"
            :type="'text'"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>
        <div class="col-lg-6 form-group clearfix mb-3">
          <label for="Referencia">Referência</label>

          <v-text-field
            :error-messages="error.referencias"
            v-model="referencias"
            :type="'text'"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>
        

        <div class="col-md-12 text-right">
          <button
            @click.prevent="cadastrarAssistente"
            class="btn btn-app-primary btn-rounded font-weight-bold"
          >
            <span v-if="!loading"> Cadastrar </span>
            <v-progress-circular
              v-else
              indeterminate
              color="white"
            ></v-progress-circular>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import CEPMixin from "@/mixins/cep_mixin.js";
import Mixin from "@/mixins/vuex_mixin.js";
import AssistenteService from "@/services/assistente_service.js";
import PictureInput from "vue-picture-input";

export default {
  components: {
    PictureInput,
  },
  mixins: [CEPMixin, Mixin],
  data() {
    return {
      //Validação de Senha
      loading: false,
      // Formulário
      formCadastro: "",
      nome: "",
      email: "",
      telefone_1: "",
      telefone_2: "",
      cpf: "",
      rg: "",
      dataNascimento: "",
      genero: "",
      estadoCivil: "",
      profissao: "",
      indicado_por: "",
      cep: "",
      logradouro: "",
      bairro: "",
      numero: "",
      complemento: "",
      referencias: "",
      cidade: "",
      estado: "",
      senha: "",
      avatarAssistente: '',
      error: {
        nome: "",
        email: "",
        telefone_1: "",
        telefone_2: "",
        cpf: "",
        rg: "",
        data_nascimento: "",
        genero: "",
        estadoCivil: "",
        profissao: "",
        indicado_por: "",
        cep: "",
        logradouro: "",
        bairro: "",
        numero: "",
        complemento: "",
        referencias: "",
        cidade: "",
        estado: "",
        senha: "",
        statusResposta: null,
      },
      rules: {
        required: (value) => !!value || "Campo obrigatório.",
        min: (v) => v.length >= 8 || "Minimo de 8 caracteres",
        emailMatch: () => `O e-mail e a senha inseridos não correspondem`,
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Email inválido.";
        },
      },
    };
  },
  mounted() {
    this.formCadastro = document.forms[0];
  },
  methods: {
    zerarFormulario() {
      Array.from(this.formCadastro.elements).forEach((element) => {
        element.value = "";
      });
    },
    zerarErros() {
      Object.keys(this.error).forEach((error) => {
        this.error[error] = "";
      });
    },
    cadastrarAssistente() {
      if (!this.formCadastro.checkValidity()) {
        return;
      }

      var formdata = new FormData();
      formdata.append('nome', this.nome);
      formdata.append('email', this.email);
      formdata.append('senha', this.senha);
      formdata.append('telefone_1', this.telefone_1);
      formdata.append('telefone_2', this.telefone_2);
      formdata.append('cpf', this.cpf);
      formdata.append('rg', this.rg);
      formdata.append('data_nascimento', this.dataNascimento);
      formdata.append('genero', this.genero);
      formdata.append('estado_civil', this.estadoCivil);
      formdata.append('profissao', this.profissao);
      formdata.append('indicado_por', this.indicado_por);
      formdata.append('observacoes', this.observacoes);
      formdata.append('cep', this.cep);
      formdata.append('logradouro', this.logradouro);
      formdata.append('bairro', this.bairro);
      formdata.append('numero', this.numero);
      formdata.append('complemento', this.complemento);
      formdata.append('referencias', this.referencias);
      formdata.append('cidade', this.cidade);
      formdata.append('estado', this.estado);
      formdata.append('id_medico', 1);        
      formdata.append('foto', this.avatarAssistente);
      this.loading = true;
      const responseFunctions = {
        onSucess: this.mostrarFeedback(),
        onError: (error) => {
          console.error(error);
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: "error",
          });
        },
        onEnd: () => {
          this.loading = false;
        },
      };
      const assistenteService = new AssistenteService();
      assistenteService.register(responseFunctions, formdata);
    },
    mostrarFeedback() {
      return (status) => (body) => {
        this.zerarErros();
        if (body.message) {
          if (status === 200 || status === 201) {
            this.$_ACTIONS_showSnackbarMessage({
              message:
                body.message || this.$global.messages.internalServerError,
              color: "sucess",
            });
            this.zerarFormulario();
            this.$emit("response");
            this.$emit("close");
          }
          if (status === 400 && body.errors) {
            this.$_ACTIONS_showSnackbarMessage({
              message:
                body.message || this.$global.messages.internalServerError,
              color: "error",
            });
            const errors = body.errors;
            Object.keys(body.errors).forEach((error) => {
              if (errors[error]) {
                this.error[error] = errors[error];
              }
            });
          }
        } else {
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: "error",
          });
          this.zerarFormulario();
          this.$emit("response");
          this.$emit("close");
        }
      };
    },

    //Upload de fotos de perfil
    trocarImagem() {
      if (this.$refs.pictureInput) {
        this.avatarAssistente = this.$refs.pictureInput.file;
      } else {
        console.error("API File Reader não suportada: use o <form>");
      }
    },
  },
};
</script>

<style scoped>
.form-group label {
  text-align: left !important;
  display: block;
}

.form-group .label-genero {
  margin-right: 1rem;
}
</style>