import { global } from '@/config/constants'
import vuex from '@/store/index.js'

export default class HTTPService {

    static builder = class {
        build () {

        }
    }

    baseURL (args) {
        return `${global.api.baseURL}/${args}`
    }

    // tratarErroForbidden () {
    //     vuex.dispatch('setUsuario', {});
    //     vuex.dispatch('setToken', '');
    //     vuex.dispatch('showSnackbarMessage', {
    //         message: 'O seu token de acesso expirou. Logue novamente no sistema.',
    //         color: 'error'
    //     });
    //     router.replace('login');
    // }

    encodeQueryParams (params) {
        let uri = ''
        const paramsNames = Object.keys(params)
        for (let i = 0, len = paramsNames.length; i < len; i++) {
            let  paramName = paramsNames[i]
            let paramValue = params[paramName]
            let separator = i === 0 ? '?' : '&'
            uri += `${separator}${paramName}=${paramValue}`
        }
        const encodedURI = encodeURI(uri)
        return encodedURI
    }

    request ({ onSucess, onError, onEnd }, method, resource, bodyParams, queryParamsObj) {
        if (typeof onSucess !== 'function') {
            throw new Error('onSucess is not a function.')
        }
        if (typeof onError !== 'function') {
            throw new Error('onError is not a function.')
        }
        if (typeof onEnd !== 'function') {
            throw new Error('onEnd is not a function.')
        }
        const headers = new Headers();
        headers.append("Content-Type", "application/x-www-form-urlencoded");
        if (vuex.getters.getToken) {
            headers.append("Authorization", `Bearer ${vuex.getters.getToken}`);
        }
        let url = ''
        if (method === 'GET') {
            const queryParams = queryParamsObj ? this.encodeQueryParams(queryParamsObj) : ''
            url = this.baseURL(resource + queryParams)
        } else {
            url = this.baseURL(resource)
        }
        const redirect = 'follow'
        const config =  { method , redirect}
        if (['POST', 'GET', 'DELETE'].includes(method)) {
            config.headers = headers
        }
        if (method === 'POST' && typeof bodyParams === 'object' && JSON.stringify(bodyParams) !== '{}') {
            const urlencoded = new URLSearchParams();
            Object.keys(bodyParams).forEach(campo => {
                if (bodyParams[campo] instanceof Object) {
                    const objParam = bodyParams[campo]
                    Object.keys(objParam).forEach(nameParam => {
                        urlencoded.append(`${campo}[${nameParam}]`, objParam[nameParam])
                    })
                } else {
                    urlencoded.append(campo, bodyParams[campo])
                }

            })
            config.body = urlencoded
        }
        let request = null
        try {
            request = fetch(url, config)
        } catch (error) {
            request = new Error(error)
        }
        if (request instanceof Error) {
            onError()
        } else {
            request.then(response => {
                const { status } = response
                // if (status === 403) {
                //     this.tratarErroForbidden();
                //     return;
                // }
                response.json()
                    .then(onSucess ? onSucess(status) : () => {})
                    .catch(onError || (() => {}))
                    .finally(onEnd || (() => {}))
            })
            .catch(onError || (() => {}))
            .finally(onEnd || (() => {}))
        }
    }
    fileRequest ({ onSucess, onError, onEnd }, method, resource, formData) {
        if (typeof onSucess !== 'function') {
            throw new Error('onSucess is not a function.')
        }
        if (typeof onError !== 'function') {
            throw new Error('onError is not a function.')
        }
        if (typeof onEnd !== 'function') {
            throw new Error('onEnd is not a function.')
        }
        const headers = new Headers();
        // headers.append("Content-type","multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2));
        if (vuex.getters.getToken) {
            headers.append("Authorization", `Bearer ${vuex.getters.getToken}`);
        }
        let url = ''
        url = this.baseURL(resource)
        const redirect = 'follow'
        const config =  { method , redirect}
        config.headers = headers
        config.body = formData
        let request = null
        try {
            request = fetch(url, config)
        } catch (error) {
            request = new Error(error)

        }
        if (request instanceof Error) {
            onError()
        } else {
            request.then(response => {
                const { status } = response
                // if (status === 403) {
                //     this.tratarErroForbidden();
                //     return;
                // }
                response.json()
                    .then(onSucess ? onSucess(status) : () => {})
                    .catch(onError || (() => {}))
                    .finally(onEnd || (() => {}))
            })
            .catch(onError || (() => {}))
            .finally(onEnd || (() => {}))
        }
    }
    jsonRequest ({ onSucess, onError, onEnd }, method, resource, bodyParams, queryParamsObj) {
        if (typeof onSucess !== 'function') {
            throw new Error('onSucess is not a function.')
        }
        if (typeof onError !== 'function') {
            throw new Error('onError is not a function.')
        }
        if (typeof onEnd !== 'function') {
            throw new Error('onEnd is not a function.')
        }
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        if (vuex.getters.getToken) {
            headers.append("Authorization", `Bearer ${vuex.getters.getToken}`);
        }
        let url = ''
        if (method === 'GET') {
            const queryParams = queryParamsObj ? this.encodeQueryParams(queryParamsObj) : ''
            url = this.baseURL(resource + queryParams)
        } else {
            url = this.baseURL(resource)
        }
        const redirect = 'follow'
        const config =  { method , redirect}
        if (['POST', 'GET', 'DELETE'].includes(method)) {
            config.headers = headers
        }
        if (method === 'POST' && typeof bodyParams === 'object' && JSON.stringify(bodyParams) !== '{}') {
            var raw = JSON.stringify(bodyParams);
            config.body = raw
        }
        let request = null
        try {
            request = fetch(url, config)
        } catch (error) {
            request = new Error(error)
        }
        if (request instanceof Error) {
            onError()
        } else {
            request.then(response => {
                const { status } = response
                // if (status === 403) {
                //     this.tratarErroForbidden();
                //     return;
                // }
                response.json()
                    .then(onSucess ? onSucess(status) : () => {})
                    .catch(onError || (() => {}))
                    .finally(onEnd || (() => {}))
            })
            .catch(onError || (() => {}))
            .finally(onEnd || (() => {}))
        }
    }

    get(url, queryStrings = {}) {
        Object.keys(queryStrings)
            .filter(key => ![null, ''].includes(queryStrings[key]))
            .forEach((key, i) => {
                url += ['?', '&'][+!!i] + `${key}=${encodeURI(queryStrings[key])}`;
            })
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        if (vuex.getters.getToken) {
            headers.append("Authorization", `Bearer ${vuex.getters.getToken}`);
        }
        return fetch(this.baseURL(url), {
            method: 'GET',
            redirect: 'follow',
            headers
        })
    }

    post(url, bodyParams = {}) {
        const headers = new Headers();
        headers.append("Content-Type", "application/x-www-form-urlencoded");
        if (vuex.getters.getToken) {
            headers.append("Authorization", `Bearer ${vuex.getters.getToken}`);
        }
        const urlSearchParams = new URLSearchParams();
        Object.keys(bodyParams).forEach(campo => {
            if (bodyParams[campo] instanceof Object) {
                const objParam = bodyParams[campo]
                Object.keys(objParam).forEach(nameParam => {
                    urlSearchParams.append(`${campo}[${nameParam}]`, objParam[nameParam])
                })
            } else {
                urlSearchParams.append(campo, bodyParams[campo])
            }
        })
        const config = {
            method: 'POST',
            redirect: 'follow',
            headers,
            body: urlSearchParams
        }
        return fetch(this.baseURL(url), config);
    }



}
