<template>
  <div>
    <v-card class="ma-4" elevation="2" color="white">
      <v-card-title>Pacientes do dia</v-card-title>
      <v-data-table
        :headers="headers"
        :footer-props="{'items-per-page-options': [5, 10]}"
        :items="lista"
        item-key="nome"
        class="elevation-1"
      >
      </v-data-table>
    </v-card>
  </div>
</template>
<script>

export default {
  components: {},
  props:{
    lista: {type: Array, required: true},
  },
  data: () => ({
      headers: [
        {
          text: "Nome",
          align: "start",
          sortable: true,
          value: "nomePaciente",
        },
        { text: "Procedimento", value: "procedimento",  sortable: false},
        { text: "Telefone", value: "telefone",  sortable: false },
      ],
  }),

};
</script>