<template>
  <div class="carbonada">
    <div class="row row-cols-2" style="width:18rem">
      <!--div class="col" style="border-right: solid gray 0.01px !important;"-->
      <div class="col">
        <!--b>RECEITUÁRIO DE CONTROLE ESPECIAL</b><br /-->
        <div class="row justify-content-evenly">
          <div class=" col col-4 pt-6">
            <div
              class=""
              style="border: solid black 0.1px !important; padding-right: 1px;padding-left: 5px;"
            >
              <span class="ml-1 mt-2 pb-0 " style="padding-top: 1px;"></span
              ><br />
              <span class="ml-1 mt-2 pb-0 " style="padding-top: 1px;"
                ><b>
                  IDENTIFICAÇÃO DO EMITENTE
                </b> </span
              ><br />
              <span
                class="mt-2 pb-0 "
                style="letter-spacing: 0.2px; font-size:2.6px "
                ><b><strong>Nome: </strong></b>Clinica de Cirurgia Plástica
                Nestor Vasconcelos Eireli My Smart Clinic</span
              >
              <br />
              <span
                class="mt-2 pb-0 "
                style="letter-spacing: 0.2px; font-size:2.6px "
                ><b><strong>CRM: </strong></b>6110 CE</span
              ><br />
              <span
                class="mt-2 pb-0 "
                style="letter-spacing: 0.2px; font-size:2.6px "
                ><b><strong>Endereço: </strong></b>R Desembembargador Lauro
                Nogueira, 1500 Sala 1017</span
              ><br />
              <span
                class="mt-2 pb-0 "
                style="letter-spacing: 0.2px; font-size:2.6px "
                ><b><strong>Telefone: </strong></b>(85) 99985-1522</span
              ><br />
              <span
                class="mt-2 pb-0 "
                style="letter-spacing: 0.2px; font-size:2.6px "
                ><b><strong>Cidade e UF: </strong></b>Fortaleza - CE</span
              ><br />
              <!--span style="letter-spacing: 0.2px; font-size:2.6px "
                >Cirurgia Plástica - CRM 6110 - RQE 2395</span
              ><br /-->
              <!--span style="letter-spacing: 0.2px; font-size:2.6px "
                >End.: Riomar Trade Center</span
              ><br />
              <span style="letter-spacing: 0.2px; font-size:2.6px "
                >Rua Desembargador Lauro Nogueira, 1500. Papicu</span
              >
              <br />
              <span style="letter-spacing: 0.2px; font-size:2.6px ">
                10º andar - sala 1017</span
              ><br />
              <span style="letter-spacing: 0.2px; font-size:2.6px "
                >Fone:(85) 99985.1522 / (85)3039.3890</span
              ><br /-->
            </div>
          </div>
          <div class="col-1"></div>
          <div class=" col col-6 p-0 pt-2">
            <b>RECEITUÁRIO DE CONTROLE ESPECIAL</b><br /><br />
            <span class=" pb-0 mb-0"
              >DATA: {{ new Date().toLocaleDateString() }}</span
            ><br />
            1º via Farmácia <br />
            2º via Farmácia <br /><br />
            ______________________________________ <br />
            Assinatura <br />
          </div>
        </div>
      </div>
      <!--div class="col">
        <b>RECEITUÁRIO DE CONTROLE ESPECIAL</b><br />
        <div class="row row-cols-2  receituario_controle">
          <div class=" col col-8 pt-6">
            <div
              class=""
              style="/*border: solid gray 0.1px !important;*/width: 4.5rem !important;  padding-right: 1px;padding-left: 1px;"
            >
              <span class="ml-1 mt-2 pb-0 " style="padding-top: 1px;"></span
              ><br />
              <span class="ml-1 mt-2 pb-0 " style="padding-top: 1px;"
                >IDENTIFICAÇÃO DO EMITENTE</span
              ><br />
              <span class="text-decoration-underline  p-0 mt-0"
                >__________________________________________</span
              ><br />
              <span
                class="ml-1 mt-2 pb-0 "
                style="letter-spacing: 0.2px; font-size:2.6px "
                ><b>Dr. Luiz Nestor Vasconcelos Rodrigues</b></span
              ><br />
              <span style="letter-spacing: 0.2px; font-size:2.6px "
                >Cirurgia Plástica - CRM 6110 - RQE 2395</span
              ><br />
              <span style="letter-spacing: 0.2px; font-size:2.6px "
                >End.: Riomar Trade Center</span
              ><br />
              <span style="letter-spacing: 0.2px; font-size:2.6px "
                >Rua Desembargador Lauro Nogueira, 1500. Papicu</span
              >
              <br />
              <span style="letter-spacing: 0.2px; font-size:2.6px ">
                10º andar - sala 1017</span
              ><br />
              <span style="letter-spacing: 0.2px; font-size:2.6px "
                >Fone:(85) 99985.1522 / (85)3039.3890</span
              ><br />
            </div>
          </div>
          <div class=" col col-4 p-0 pt-2">
            1º via Farmácia <br />
            2º via Farmácia <br /><br /><br /><br />
            ___________________________ <br />
            Carimbo e Assinatura <br />
          </div>
        </div>
      </div-->
    </div>
    <div
      class="row rows-cols-2 pt-0"
      style="width:18rem; height: 170px; padding-top: 0px !important; margin-top: 0px !important"
    >
      <div
        class="col"
        style="/*border-right: solid gray 0.01px !important;*/ padding-top: 0px"
      >
        <b> Paciente: </b>{{ nome }} <br />
        <b> CPF: </b>{{ cpf }} <br />
        <b> Endereço: </b>{{ logradouro }}, {{ numero }}. {{ complemento }}
        <br />
        <br />
        <br />
        <br />
        <u v-for="(item, i) in receita" :key="i">
          <li class="d-flex flex-column">
            <span class="medicamento">
              <b> {{ item.medicamento }} </b
              >................................................................................{{
                item.quantidade
              }}&nbsp;&nbsp;<br />{{ item.posologia }}
            </span>
          </li> </u
        ><br />
        <span class=" pb-0 mb-0"
          >DATA: {{ new Date().toLocaleDateString() }}</span
        >
      </div>
      <!--div class="col" style="padding-top: 0px">
        Paciente: <b>{{ nome }}</b
        ><br />
        Endereço: {{ logradouro }}, {{ numero }}. {{ complemento }} <br />
        <u v-for="(item, i) in receita" :key="i">
          <li class="d-flex flex-column">
            {{ i + 1 }} - {{ item.medicamento }}__{{ item.quantidade
            }}<br />&nbsp;&nbsp;&nbsp;&nbsp;{{ item.posologia }}
          </li> </u
        ><br /><br />
        <span class=" pb-0 mb-0"
          >DATA: {{ new Date().toLocaleDateString() }}</span
        >
      </div-->
    </div>
    <!--asdasdasdasdasdadasdasdasd-->
    <div class="row row-cols-2" style="width:18rem">
      <!--div class="col" style="border-right: solid gray 0.01px !important;"-->
      <div class="col">
        <!--b>RECEITUÁRIO DE CONTROLE ESPECIAL</b><br /-->
        <div class="row justify-content-evenly">
          <div class=" col col-4 pt-6">
            <div
              class=""
              style="border: solid black 0.1px !important; padding-right: 1px;padding-left: 5px;"
            >
              <span class="ml-1 mt-2 pb-0 " style="padding-top: 1px;"></span
              ><br />
              <span><b>IDENTIFICAÇÃO DO COMPRADOR</b></span>
              <br /><br />
              <span><b>Nome Completo: </b></span><br />
              <span><b>RG: </b></span><br />
              <span><b>Endereço: </b></span><br />
              <span><b>Telefone: </b></span><br />
              <span><b>Cidade e UF: </b></span><br />

              <!--span style="letter-spacing: 0.2px; font-size:2.6px "
                >Cirurgia Plástica - CRM 6110 - RQE 2395</span
              ><br /-->
              <!--span style="letter-spacing: 0.2px; font-size:2.6px "
                >End.: Riomar Trade Center</span
              ><br />
              <span style="letter-spacing: 0.2px; font-size:2.6px "
                >Rua Desembargador Lauro Nogueira, 1500. Papicu</span
              >
              <br />
              <span style="letter-spacing: 0.2px; font-size:2.6px ">
                10º andar - sala 1017</span
              ><br />
              <span style="letter-spacing: 0.2px; font-size:2.6px "
                >Fone:(85) 99985.1522 / (85)3039.3890</span
              ><br /-->
            </div>
          </div>
          <div class=" col col-4 pt-6">
            <div
              class=""
              style="border: solid black 0.1px !important; padding-right: 1px;padding-left: 5px;"
            >
              <span class="ml-1 mt-2 pb-0 " style="padding-top: 1px;"></span>
              <br />
              <span><b>IDENTIFICAÇÃO DO FORNECEDOR</b></span>
              <br />
              <br />
              <br />
              ______________________________________ <br />
              <span class="assinatura">
                DATA
              </span>
              <br />
              ______________________________________ <br />
              <span class="assinatura">
                ASSINATURA DO FARMACEUTICO
              </span>
              <br />

              <!--span style="letter-spacing: 0.2px; font-size:2.6px "
                >Cirurgia Plástica - CRM 6110 - RQE 2395</span
              ><br /-->
              <!--span style="letter-spacing: 0.2px; font-size:2.6px "
                >End.: Riomar Trade Center</span
              ><br />
              <span style="letter-spacing: 0.2px; font-size:2.6px "
                >Rua Desembargador Lauro Nogueira, 1500. Papicu</span
              >
              <br />
              <span style="letter-spacing: 0.2px; font-size:2.6px ">
                10º andar - sala 1017</span
              ><br />
              <span style="letter-spacing: 0.2px; font-size:2.6px "
                >Fone:(85) 99985.1522 / (85)3039.3890</span
              ><br /-->
            </div>
          </div>
          <!--div class="col-1"></div>
          <div class=" col col-6 p-0 pt-2">
            <b>RECEITUÁRIO DE CONTROLE ESPECIAL</b><br /><br />
            <span class=" pb-0 mb-0"
              >DATA: {{ new Date().toLocaleDateString() }}</span
            ><br />
            1º via Farmácia <br />
            2º via Farmácia <br /><br />
            ______________________________________ <br />
            Assinatura <br />
          </div-->
        </div>
      </div>
      <!--div class="col">
        <b>RECEITUÁRIO DE CONTROLE ESPECIAL</b><br />
        <div class="row row-cols-2  receituario_controle">
          <div class=" col col-8 pt-6">
            <div
              class=""
              style="/*border: solid gray 0.1px !important;*/width: 4.5rem !important;  padding-right: 1px;padding-left: 1px;"
            >
              <span class="ml-1 mt-2 pb-0 " style="padding-top: 1px;"></span
              ><br />
              <span class="ml-1 mt-2 pb-0 " style="padding-top: 1px;"
                >IDENTIFICAÇÃO DO EMITENTE</span
              ><br />
              <span class="text-decoration-underline  p-0 mt-0"
                >__________________________________________</span
              ><br />
              <span
                class="ml-1 mt-2 pb-0 "
                style="letter-spacing: 0.2px; font-size:2.6px "
                ><b>Dr. Luiz Nestor Vasconcelos Rodrigues</b></span
              ><br />
              <span style="letter-spacing: 0.2px; font-size:2.6px "
                >Cirurgia Plástica - CRM 6110 - RQE 2395</span
              ><br />
              <span style="letter-spacing: 0.2px; font-size:2.6px "
                >End.: Riomar Trade Center</span
              ><br />
              <span style="letter-spacing: 0.2px; font-size:2.6px "
                >Rua Desembargador Lauro Nogueira, 1500. Papicu</span
              >
              <br />
              <span style="letter-spacing: 0.2px; font-size:2.6px ">
                10º andar - sala 1017</span
              ><br />
              <span style="letter-spacing: 0.2px; font-size:2.6px "
                >Fone:(85) 99985.1522 / (85)3039.3890</span
              ><br />
            </div>
          </div>
          <div class=" col col-4 p-0 pt-2">
            1º via Farmácia <br />
            2º via Farmácia <br /><br /><br /><br />
            ___________________________ <br />
            Carimbo e Assinatura <br />
          </div>
        </div>
      </div-->
    </div>
    <div class="row row-cols-2" style="width:18rem">
      <!--div class="col" style="border-right: solid gray 0.01px !important;"-->
      <div class="col">
        <!--b>RECEITUÁRIO DE CONTROLE ESPECIAL</b><br /-->
        <div class="row justify-content-evenly">
          <div class=" col col-4 pt-6">
            <div
              class=""
              style="border: solid black 0.1px !important; padding-right: 1px;padding-left: 5px;"
            >
              <span class="ml-1 mt-2 pb-0 " style="padding-top: 1px;"></span
              ><br />
              <span class="ml-1 mt-2 pb-0 " style="padding-top: 1px;"
                ><b>
                  IDENTIFICAÇÃO DO EMITENTE
                </b> </span
              ><br />
              <span
                class="mt-2 pb-0 "
                style="letter-spacing: 0.2px; font-size:2.6px "
                ><b><strong>Nome: </strong></b>Clinica de Cirurgia Plástica
                Nestor Vasconcelos Eireli My Smart Clinic</span
              >
              <br />
              <span
                class="mt-2 pb-0 "
                style="letter-spacing: 0.2px; font-size:2.6px "
                ><b><strong>CRM: </strong></b>6110 CE</span
              ><br />
              <span
                class="mt-2 pb-0 "
                style="letter-spacing: 0.2px; font-size:2.6px "
                ><b><strong>Endereço: </strong></b>R Desembembargador Lauro
                Nogueira, 1500 Sala 1017</span
              ><br />
              <span
                class="mt-2 pb-0 "
                style="letter-spacing: 0.2px; font-size:2.6px "
                ><b><strong>Telefone: </strong></b>(85) 99985-1522</span
              ><br />
              <span
                class="mt-2 pb-0 "
                style="letter-spacing: 0.2px; font-size:2.6px "
                ><b><strong>Cidade e UF: </strong></b>Fortaleza - CE</span
              ><br />
              <!--span style="letter-spacing: 0.2px; font-size:2.6px "
                >Cirurgia Plástica - CRM 6110 - RQE 2395</span
              ><br /-->
              <!--span style="letter-spacing: 0.2px; font-size:2.6px "
                >End.: Riomar Trade Center</span
              ><br />
              <span style="letter-spacing: 0.2px; font-size:2.6px "
                >Rua Desembargador Lauro Nogueira, 1500. Papicu</span
              >
              <br />
              <span style="letter-spacing: 0.2px; font-size:2.6px ">
                10º andar - sala 1017</span
              ><br />
              <span style="letter-spacing: 0.2px; font-size:2.6px "
                >Fone:(85) 99985.1522 / (85)3039.3890</span
              ><br /-->
            </div>
          </div>
          <div class="col-1"></div>
          <div class=" col col-6 p-0 pt-2">
            <b>RECEITUÁRIO DE CONTROLE ESPECIAL</b><br /><br />
            <span class=" pb-0 mb-0"
              >DATA: {{ new Date().toLocaleDateString() }}</span
            ><br />
            1º via Farmácia <br />
            2º via Farmácia <br /><br />
            ______________________________________ <br />
            Assinatura <br />
          </div>
        </div>
      </div>
      <!--div class="col">
        <b>RECEITUÁRIO DE CONTROLE ESPECIAL</b><br />
        <div class="row row-cols-2  receituario_controle">
          <div class=" col col-8 pt-6">
            <div
              class=""
              style="/*border: solid gray 0.1px !important;*/width: 4.5rem !important;  padding-right: 1px;padding-left: 1px;"
            >
              <span class="ml-1 mt-2 pb-0 " style="padding-top: 1px;"></span
              ><br />
              <span class="ml-1 mt-2 pb-0 " style="padding-top: 1px;"
                >IDENTIFICAÇÃO DO EMITENTE</span
              ><br />
              <span class="text-decoration-underline  p-0 mt-0"
                >__________________________________________</span
              ><br />
              <span
                class="ml-1 mt-2 pb-0 "
                style="letter-spacing: 0.2px; font-size:2.6px "
                ><b>Dr. Luiz Nestor Vasconcelos Rodrigues</b></span
              ><br />
              <span style="letter-spacing: 0.2px; font-size:2.6px "
                >Cirurgia Plástica - CRM 6110 - RQE 2395</span
              ><br />
              <span style="letter-spacing: 0.2px; font-size:2.6px "
                >End.: Riomar Trade Center</span
              ><br />
              <span style="letter-spacing: 0.2px; font-size:2.6px "
                >Rua Desembargador Lauro Nogueira, 1500. Papicu</span
              >
              <br />
              <span style="letter-spacing: 0.2px; font-size:2.6px ">
                10º andar - sala 1017</span
              ><br />
              <span style="letter-spacing: 0.2px; font-size:2.6px "
                >Fone:(85) 99985.1522 / (85)3039.3890</span
              ><br />
            </div>
          </div>
          <div class=" col col-4 p-0 pt-2">
            1º via Farmácia <br />
            2º via Farmácia <br /><br /><br /><br />
            ___________________________ <br />
            Carimbo e Assinatura <br />
          </div>
        </div>
      </div-->
    </div>
    <div
      class="row rows-cols-2 pt-0"
      style="width:18rem; height: 170px; padding-top: 0px !important; margin-top: 0px !important"
    >
      <div
        class="col"
        style="/*border-right: solid gray 0.01px !important;*/ padding-top: 0px"
      >
        <b> Paciente: </b>{{ nome }} <br />
        <b> CPF: </b>{{ cpf }} <br />
        <b> Endereço: </b>{{ logradouro }}, {{ numero }}. {{ complemento }}
        <br />
        <br />
        <br />
        <br />
        <u v-for="(item, i) in receita" :key="i">
          <li class="d-flex flex-column">
            <span class="medicamento">
              <b> {{ item.medicamento }} </b
              >................................................................................{{
                item.quantidade
              }}&nbsp;&nbsp;<br />{{ item.posologia }}
            </span>
          </li> </u
        ><br />
        <span class=" pb-0 mb-0"
          >DATA: {{ new Date().toLocaleDateString() }}</span
        >
      </div>
      <!--div class="col" style="padding-top: 0px">
        Paciente: <b>{{ nome }}</b
        ><br />
        Endereço: {{ logradouro }}, {{ numero }}. {{ complemento }} <br />
        <u v-for="(item, i) in receita" :key="i">
          <li class="d-flex flex-column">
            {{ i + 1 }} - {{ item.medicamento }}__{{ item.quantidade
            }}<br />&nbsp;&nbsp;&nbsp;&nbsp;{{ item.posologia }}
          </li> </u
        ><br /><br />
        <span class=" pb-0 mb-0"
          >DATA: {{ new Date().toLocaleDateString() }}</span
        >
      </div-->
    </div>
    <!--asdasdasdasdasdadasdasdasd-->
    <div class="row row-cols-2" style="width:18rem">
      <!--div class="col" style="border-right: solid gray 0.01px !important;"-->
      <div class="col">
        <!--b>RECEITUÁRIO DE CONTROLE ESPECIAL</b><br /-->
        <div class="row justify-content-evenly">
          <div class=" col col-4 pt-6">
            <div
              class=""
              style="border: solid black 0.1px !important; padding-right: 1px;padding-left: 5px;"
            >
              <span class="ml-1 mt-2 pb-0 " style="padding-top: 1px;"></span
              ><br />
              <span><b>IDENTIFICAÇÃO DO COMPRADOR</b></span>
              <br /><br />
              <span><b>Nome Completo: </b></span><br />
              <span><b>RG: </b></span><br />
              <span><b>Endereço: </b></span><br />
              <span><b>Telefone: </b></span><br />
              <span><b>Cidade e UF: </b></span><br />

              <!--span style="letter-spacing: 0.2px; font-size:2.6px "
                >Cirurgia Plástica - CRM 6110 - RQE 2395</span
              ><br /-->
              <!--span style="letter-spacing: 0.2px; font-size:2.6px "
                >End.: Riomar Trade Center</span
              ><br />
              <span style="letter-spacing: 0.2px; font-size:2.6px "
                >Rua Desembargador Lauro Nogueira, 1500. Papicu</span
              >
              <br />
              <span style="letter-spacing: 0.2px; font-size:2.6px ">
                10º andar - sala 1017</span
              ><br />
              <span style="letter-spacing: 0.2px; font-size:2.6px "
                >Fone:(85) 99985.1522 / (85)3039.3890</span
              ><br /-->
            </div>
          </div>
          <div class=" col col-4 pt-6">
            <div
              class=""
              style="border: solid black 0.1px !important; padding-right: 1px;padding-left: 5px;"
            >
              <span class="ml-1 mt-2 pb-0 " style="padding-top: 1px;"></span>
              <br />
              <span><b>IDENTIFICAÇÃO DO FORNECEDOR</b></span>
              <br />
              <br />
              <br />
              ______________________________________ <br />
              <span class="assinatura">
                DATA
              </span>
              <br />
              ______________________________________ <br />
              <span class="assinatura">
                ASSINATURA DO FARMACEUTICO
              </span>
              <br />

              <!--span style="letter-spacing: 0.2px; font-size:2.6px "
                >Cirurgia Plástica - CRM 6110 - RQE 2395</span
              ><br /-->
              <!--span style="letter-spacing: 0.2px; font-size:2.6px "
                >End.: Riomar Trade Center</span
              ><br />
              <span style="letter-spacing: 0.2px; font-size:2.6px "
                >Rua Desembargador Lauro Nogueira, 1500. Papicu</span
              >
              <br />
              <span style="letter-spacing: 0.2px; font-size:2.6px ">
                10º andar - sala 1017</span
              ><br />
              <span style="letter-spacing: 0.2px; font-size:2.6px "
                >Fone:(85) 99985.1522 / (85)3039.3890</span
              ><br /-->
            </div>
          </div>
          <!--div class="col-1"></div>
          <div class=" col col-6 p-0 pt-2">
            <b>RECEITUÁRIO DE CONTROLE ESPECIAL</b><br /><br />
            <span class=" pb-0 mb-0"
              >DATA: {{ new Date().toLocaleDateString() }}</span
            ><br />
            1º via Farmácia <br />
            2º via Farmácia <br /><br />
            ______________________________________ <br />
            Assinatura <br />
          </div-->
        </div>
      </div>
      <!--div class="col">
        <b>RECEITUÁRIO DE CONTROLE ESPECIAL</b><br />
        <div class="row row-cols-2  receituario_controle">
          <div class=" col col-8 pt-6">
            <div
              class=""
              style="/*border: solid gray 0.1px !important;*/width: 4.5rem !important;  padding-right: 1px;padding-left: 1px;"
            >
              <span class="ml-1 mt-2 pb-0 " style="padding-top: 1px;"></span
              ><br />
              <span class="ml-1 mt-2 pb-0 " style="padding-top: 1px;"
                >IDENTIFICAÇÃO DO EMITENTE</span
              ><br />
              <span class="text-decoration-underline  p-0 mt-0"
                >__________________________________________</span
              ><br />
              <span
                class="ml-1 mt-2 pb-0 "
                style="letter-spacing: 0.2px; font-size:2.6px "
                ><b>Dr. Luiz Nestor Vasconcelos Rodrigues</b></span
              ><br />
              <span style="letter-spacing: 0.2px; font-size:2.6px "
                >Cirurgia Plástica - CRM 6110 - RQE 2395</span
              ><br />
              <span style="letter-spacing: 0.2px; font-size:2.6px "
                >End.: Riomar Trade Center</span
              ><br />
              <span style="letter-spacing: 0.2px; font-size:2.6px "
                >Rua Desembargador Lauro Nogueira, 1500. Papicu</span
              >
              <br />
              <span style="letter-spacing: 0.2px; font-size:2.6px ">
                10º andar - sala 1017</span
              ><br />
              <span style="letter-spacing: 0.2px; font-size:2.6px "
                >Fone:(85) 99985.1522 / (85)3039.3890</span
              ><br />
            </div>
          </div>
          <div class=" col col-4 p-0 pt-2">
            1º via Farmácia <br />
            2º via Farmácia <br /><br /><br /><br />
            ___________________________ <br />
            Carimbo e Assinatura <br />
          </div>
        </div>
      </div-->
    </div>
  </div>
</template>

<script>
export default {
  name: "documento_receita_carbonada",
  props: {
    nome: { required: true },
    cpf: { required: true },
    logradouro: { required: true },
    numero: { required: true },
    complemento: { required: true },
    receita: { required: true },
  },
};
</script>

<style scoped>
.carbonada {
  font-family: sans-serif !important;
  color: black !important;
}
span {
  text-align: end !important;
}
.medicamento {
  text-align: start !important;
}
.assinatura {
  text-align: center !important;
  padding-left: auto !important;
  padding-right: auto !important;
}
</style>
