<template>
  <div
      class="d-flex flex-column w-100 resp"
      style="margin-bottom: 30px"
  >
    <div>
      <p>PARA:<span class="space">_</span><b>{{ nome }}</b></p>
      <p class="m-auto text-center"><b>Medicações</b></p>
    </div>
    <u v-for="(item, i) in receita" :key="i">
      <li class="d-flex flex-column resp">
        <p class="m-auto text-center" v-if="item.titulo"><strong><u>{{ item.titulo }}</u></strong></p>
        <p class="resp">
          {{ i + 1 }} - {{ item.medicamento }}_______{{ item.quantidade
          }}<br />&nbsp;&nbsp;&nbsp;&nbsp;{{ item.posologia }}
        </p>
      </li>
    </u>
    <div class="d-flex flex-column text-end pt-8">
      <p class="ml-auto">
        Fortaleza, {{ gerarData().dia
        }}<span class="space">_</span>de<span class="space">_</span
      >{{ gerarData().mes }}<span class="space">_</span
      >{{ gerarData().ano }}.
      </p>
    </div>
    <p class="d-flex flex-column ml-auto">
      <span> _________________________________________</span>
      <span class="ml-2">Luiz Nestor Vasconcelos Rodrigues </span>
      <span class="ml-1 resp">Cirurgião Plástico - CRM 6110 - RQE 2395</span>
    </p>
  </div>
</template>

<script>
export default {
  name: "documento_receita",
  props: {
    nome: {required: true},
    receita: {required: true},
  },
  methods: {
    gerarData() {
      const meses = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novovembro",
        "Dezembro",
      ];
      let data = new Date();
      return {
        dia: data.getDate(),
        mes: meses[data.getMonth()],
        ano: data.getFullYear(),
      };
    },
  }
}
</script>

<style scoped>

</style>
