<template>
  <div>
    <div v-if="pergunta.id_tipo_pergunta === 1" class="justify-center">
      <form-field-tipo-text
        @rchange="emitChanges"
        :pergunta="pergunta"
        :disabled="disabled"
      />
    </div>

    <div v-if="pergunta.id_tipo_pergunta === 2">
      <form-field-tipo-paragrafo
        @rchange="emitChanges"
        :pergunta="pergunta"
        :disabled="disabled"
      />
    </div>

    <div v-if="pergunta.id_tipo_pergunta === 3">
      <form-field-tipo-radio
        @rchange="emitChanges"
        :pergunta="pergunta"
        :disabled="disabled"
      />
    </div>

    <div v-if="pergunta.id_tipo_pergunta === 4">
      <form-field-tipo-check
        @rchange="emitChanges"
        :pergunta="pergunta"
        :disabled="disabled"
      />
    </div>

    <div v-if="pergunta.id_tipo_pergunta === 5">
      <h4 class="align-content-start">Cálculo do IMC</h4>
      <v-row>
        <v-col>
          <v-text-field :disabled="true" label="Peso(kg)" v-model="peso" />
        </v-col>
        <v-col>
          <v-text-field :disabled="true" label="Altura(m)" v-model="altura" />
        </v-col>
        <v-col>
          <v-text-field label="Imc" :disabled="true" readonly v-model="imc" />
        </v-col>
      </v-row>
      <!--      <label>Cálculo IMC</label>-->
      <!--      <form-field-tipo-text :pergunta="pergunta" :disabled="disabled"/>-->
    </div>

    <!--
    <div v-if="pergunta.id_tipo_pergunta===5">
      <form-field-tipo-text :pergunta="pergunta" :disabled="disabled"/>
    </div>

    <div v-if="pergunta.id_tipo_pergunta===6">
      <form-field-tipo-text :pergunta="pergunta" :disabled="disabled"/>
    </div>

    <div v-if="pergunta.id_tipo_pergunta===7">
      <form-field-tipo-text :pergunta="pergunta" :disabled="disabled"/>
    </div> -->
  </div>
</template>
<script>
import Mixin from "@/mixins/vuex_snackbar_mixin.js";
import FormFieldTipoText from "@/components/prontuarios/consulta_campo_text.vue";
import FormFieldTipoParagrafo from "@/components/prontuarios/consulta_campo_paragrafo.vue";
import FormFieldTipoRadio from "@/components/prontuarios/consulta_campo_radio.vue";
import FormFieldTipoCheck from "@/components/prontuarios/consulta_campo_check.vue";

export default {
  mixins: [Mixin],
  props: {
    pergunta: Object,
    disabled: Boolean,
  },
  components: {
    FormFieldTipoText,
    FormFieldTipoParagrafo,
    FormFieldTipoRadio,
    FormFieldTipoCheck,
  },
  mounted() {
    const { pergunta } = this;
    console.log({ pergunta: this.pergunta });
    if (pergunta.id_tipo_pergunta === 5) {
      [this.peso, this.altura] = pergunta.resposta_pergunta_textual.split(
        ","
      ) || [0, 0];
    }
  },
  data: () => ({
    peso: 0,
    altura: 0,
  }),
  watch: {},
  methods: {
    emitChanges(change) {
      this.$emit("rchange", { pergunta: this.pergunta, alteracao: change });
    },
  },
  computed: {
    imc() {
      const altura = +`${this.altura}`.replace(/,/g, ".");
      const peso = +`${this.peso}`.replace(/,/g, ".");
      const imc = (altura > 0 ? peso / altura ** 2 : 0)
        .toFixed(1)
        .replace(/\./g, ",");
      return imc || 0;
    },
    pesoAlturaJoined() {
      const altura = +`${this.altura}`.replace(/,/g, ".");
      const peso = +`${this.peso}`.replace(/,/g, ".");
      return `${peso},${altura}`;
    },
  },
};
</script>
<style scoped>
.v-progress-circular {
  margin: 1rem;
}
</style>
