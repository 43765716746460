import HTTPService from '@/services/http_service.js'

export default class TipoProcedimentoService extends HTTPService {
    resource = 'tipo-procedimento'
    cadastrar (responseFunctions, tipoProcedimento) {
        this.request(responseFunctions, 'POST', this.resource, tipoProcedimento)
    }

    getTipoProcedimentos (responseFunctions) {
        this.request(responseFunctions, 'GET', this.resource, null)
    }

    getAll (onSucess, onError, onEnd) {
      const responseFunctions = {
          onSucess,
          onError,
          onEnd
      }
      this.request(responseFunctions, 'GET', this.resource)
    }

    deleteTipoProcedimento ({ onSucess, onError, onEnd }, { id }) {
      // ESTÁ SENDO UTILIZADO POST PARA EXCLUSÃO PORQUE A UMBLER INFORMOU ERRO DE CORS AO USAR O MÉTODO DELETE. CORRIGIR. 
        const uri = `${this.resource}/${id}`
        this.request({ onSucess, onError, onEnd }, 'DELETE', uri)
    }

    updateDescricaoTipoProcedimento ({ onSucess, onError, onEnd }, { descricao, id }) {
        if (!descricao || typeof descricao !== 'string') {
            throw new Error('descricao is not a valid string.')
        }
        if (+id < 1) {
            throw new Error('id is not a number.')
        }
        const uri = `${this.resource}/${id}`
        this.request({ onSucess, onError, onEnd }, 'POST', uri, { descricao })
    }

    searchByDescricao (vm) {
        // Para usar esse método, o componente deve ter como atributos em data
        // "procedimentos" (array) e "loading"(boolean)
        return descricao => {
            if (!descricao) {
                vm.tipoProcedimentos = [];
                vm.tipoProcedimento='';
              }
              if (
                !descricao ||
                !(descricao = descricao.trim()) ||
                descricao.length < 3 ||
                vm.loading_tipoProcedimento
              ) {
                return;
              }
              vm.loading_tipoProcedimento = true;
              vm.tipoProcedimentos = [];
            //   const tipoProcedimentoService = new tipoProcedimentoService();
              const requestFunctions = {
                onSucess: (status) => (body) => {
                  if (status === 200) {
                    vm.tipoProcedimentos = body.data.tipoProcedimentos;
                  } else {
                    vm.tipoProcedimentos = [];
                  }
                },
                onError: (error) => {
                  console.error(error);
                  vm.tipoProcedimentos = [];
                },
                onEnd: () => {
                  vm.loading_tipoProcedimento = false;
                },
              };
              this.getTipoProcedimentos(requestFunctions, descricao);
        }
    }
} 
