<template>
  <div>  
    <div class="p-lg-3">
        <div class="row justify-content-between">
            <h3 class="mt-0 mb-2 font-weight-bold text-left">Dashboard</h3>
        </div>
    </div>
    <div v-if="$_GETTER_perfil.description === 'admin'">
      <dashboard-medico/>
    </div>
    <div v-else-if="$_GETTER_perfil.description === 'paciente'">
      <dashboard-paciente/>
    </div>
    <div v-else-if="$_GETTER_perfil.description === 'medico'">
      <dashboard-medico/>
    </div>
    <div v-else-if="$_GETTER_perfil.description === 'assistente'">
      <dashboard-assistente/>
    </div>
  </div>
</template>

<script>
import dashboardMedico from '@/components/dashboards/dashboard_medico/index.vue'
import dashboardAssistente from '@/components/dashboards/dashboard_assistente/index.vue'
import dashboardPaciente from '@/components/dashboards/dashboard_paciente/index.vue'
import VuexMixin from "@/mixins/vuex_mixin";

export default {
  mixins: [VuexMixin],
  components: {
    dashboardAssistente, 
    dashboardMedico,
    dashboardPaciente
  }
}
</script>
