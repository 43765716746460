<template>
  <div>
    <div v-if="pergunta.id_tipo_pergunta===1" class="justify-center">
      <form-field-tipo-text :pergunta="pergunta" :disabled="disabled"/>
    </div>

    <div v-if="pergunta.id_tipo_pergunta===2">
      <form-field-tipo-paragrafo :pergunta="pergunta" :disabled="disabled"/>
    </div>

    <div v-if="pergunta.id_tipo_pergunta===3">
      <form-field-tipo-radio :pergunta="pergunta" :disabled="disabled"/>
    </div>

    <div v-if="pergunta.id_tipo_pergunta===4">
      <form-field-tipo-check :pergunta="pergunta" :disabled="disabled"/>
    </div>

    <div v-if="pergunta.id_tipo_pergunta===5">
      <v-text-field label="Peso(kg)" :disabled="disabled" v-model="peso"/>
      <v-text-field label="Altura(m)" :disabled="disabled" v-model="altura"/>
      <v-text-field label="Imc" :disabled="true" readonly v-model="imc"/>
<!--      <label>Cálculo IMC</label>-->
<!--      <form-field-tipo-text :pergunta="pergunta" :disabled="disabled"/>-->
    </div>

    <div v-if="pergunta.id_tipo_pergunta===6">
      <form-field-tipo-text :pergunta="pergunta" :disabled="disabled"/>
    </div>

    <div v-if="pergunta.id_tipo_pergunta===7">
      <form-field-tipo-text :pergunta="pergunta" :disabled="disabled"/>
    </div>
  </div>
</template>
<script>
import Mixin from "@/mixins/vuex_snackbar_mixin.js";
import FormFieldTipoText from "@/components/formularios/formulario_campo_text.vue";
import FormFieldTipoParagrafo from "@/components/formularios/formulario_campo_paragrafo.vue";
import FormFieldTipoRadio from "@/components/formularios/formulario_campo_radio.vue";
import FormFieldTipoCheck from "@/components/formularios/formulario_campo_check.vue";

export default {
  mixins: [Mixin],
  props:{
    pergunta:Object,
    disabled:Boolean
  },
  components:{
    FormFieldTipoText,
    FormFieldTipoParagrafo,
    FormFieldTipoRadio,
    FormFieldTipoCheck
  },
  computed: {
    imc() {
      const altura = +`${this.altura}`.replace(/,/g, '.')
      const peso = +`${this.peso}`.replace(/,/g, '.')
      console.log(this.peso)
      console.log(this.altura)
      console.log({peso,altura})
      return altura > 0
        ? peso / altura ** 2
        : 0
      ;
    }
  },
  data: () => ({
    peso: 0,
    altura: 0,
  }),
  watch: {
  },
};
</script>
<style scoped>
.v-progress-circular {
  margin: 1rem;
}
</style>
