<template>
  <div>
    <div class="p-lg-3">
      <div class="row justify-content-between">
        <h3 class="mt-0 mb-2 font-weight-bold text-left">
          Assistentes
        </h3>
      </div>
    </div>

    <v-data-table
      v-on:update:sort-by="options.ordenacao = true"
      :footer-props="{'items-per-page-options': [5, 10, 15, 20]}"
      :search="search"
      :headers="headers"
      :items="assistentes"
      :options.sync="options"
      :server-items-length="totalList"
      class="elevation-1"
      :loading="loading"
      :loading-text="'Carregando...'"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <!-- DIALOG DE CADASTRO -->
          <v-dialog
              v-model="dialogCadastro"
              max-width="500px"
              persistent
              fullscreen
              transition="dialog-bottom-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="#3E682A" dark class="mb-2" v-bind="attrs" v-on="on">
                Novo Assistente
              </v-btn>
            </template>
            <v-card>
              <v-toolbar style="background-color: #3e682a; color: #fff">
                <v-btn icon dark @click="dialogCadastro = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>
                  <span class="headline">Cadastrar assistente</span>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                </v-toolbar-items>
              </v-toolbar>
              <cadastro-novo-assistente v-if="dialogCadastro"  @close="dialogCadastro = false" @response="atualizarListaAssistentes"/>
            </v-card>
          </v-dialog>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Pesquisar"
            single-line
            hide-details
          ></v-text-field>
          <!-- DIALOG DE EDIÇÃO -->
          <v-dialog
            v-model="dialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
          >
            <cadastro-edicao-assistente v-if="assistente_detalhe" :assistente="assistente_detalhe" :visualizar='visualizar' :key="render" @close="save($event)" />
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item, true)"> mdi mdi-eye </v-icon>
        <v-icon small class="mr-2" @click="editItem(item, false )"> fas fa-edit </v-icon>
      </template>
      <template v-slot:no-data>
        <p class="text center">Nenhum registro encontrado</p>
        <v-btn color="#3E682A" dark @click="atualizarListaAssistentes">
          Atualizar
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import CadastroEdicaoAssistente from "@/components/assistentes/cadastro_edicao_assistente.vue";
import CadastroNovoAssistente from "@/components/assistentes/cadastro_novo_assistente.vue";
import AssistenteService from "@/services/assistente_service.js";

export default {
  components: {
    CadastroEdicaoAssistente, CadastroNovoAssistente,
  },

  data: () => ({
    assistente_detalhe: {},
    render: 0,
    dialog: false,
    loading: false,
    search: "",
    totalList:10,
    options:{
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      ordenacao: false,
      desc:false,
    },
    dialogCadastro: false,
    assistente: {},
    visualizar: false,
    assistentes: [],
    headers: [
      {
        text: "Nome",
        align: "start",
        sortable: true,
        value: "nome",
      },
      { text: "CPF", value: "cpf",  sortable: false },
      { text: "Email", value: "email",  sortable: false},
      { text: "Ações", align: "end", value: "actions", sortable: false },
    ],
    editedIndex: -1,
    editedItem: {
      nome: "",
      cpf: "",
      email: "",
    },
    defaultItem: {
      nome: "",
      cpf: "",
      email: "",
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Novo Procedimento"
        : "Editar Procedimento";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    options:{
      handler (){
        this.options.desc = this.options.sortDesc[0] ?? false
        this.atualizarListaAssistentes();
      }, 
      deep:true
    },
    search(){
      this.atualizarListaAssistentes();
    }
  },

  mounted() {
    this.atualizarListaAssistentes();
  },

  methods: {
    forceRender(){
      this.render+=1;
    },
    atualizarListaAssistentes() {
      const assistenteService = new AssistenteService();
      this.loading = true;
      this.assistentes=[];
      const onSucess = (status) => (body) => {
        if (status === 200) {
         this.totalList = body.data.pagination.num_rows
         this.assistentes = body.data.assistentes.map((assistente) => {
            return assistente;
          });
        } else {
          console.log(body);
        }
      };
      const onError = (error) => {
        console.log(error);
      };
      const onEnd = () => {
        this.loading = false;
      };
      assistenteService.getAll(onSucess, onError, onEnd,this.options.page, this.options.itemsPerPage, this.search, this.options.ordenacao, this.options.desc);
    },

    loadingAssistente(id) {
      this.assistente_detalhe = {}
      const assistenteService = new AssistenteService();
      assistenteService.get(
        {
          onSucess: (status) => (body) => {
            if(status === 200){
              this.assistente_detalhe = body.data;
              this.dialog = true;
            }

          },
          onError: (error) => {
            this.messageSnackbar = error;
            this.typeMessageSnackbar = "error";
            console.log(error);
          },
          onEnd: () => {},
        },id);
    },

    editItem(item, bool) {
      this.editedIndex = this.assistentes.indexOf(item);
      this.assistente = item;
      this.visualizar = bool;
      this.editedItem = Object.assign({}, item);
      this.loadingAssistente(this.assistente.id)
      // this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.dialogCadastro = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      this.dialogCadastro = false;
      if (this.editedIndex > -1) {
        Object.assign(this.assistentes[this.editedIndex], this.editedItem);
      } else {
        this.assistentes.push(this.editedItem);
      }
      this.assistente_detalhe = false
      this.options.ordenacao = false
      this.options.desc = false
      this.forceRender();
      this.atualizarListaAssistentes();
      this.close();
    },
  },
};
</script>