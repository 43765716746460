import { global } from "@/config/constants.js";

const profile = (description) => {
  const profileObj = global.profiles.find(
    (profile) => profile.description === description
  );
  const id = (profileObj || {}).id;
  return id || 0;
};

const authorizations = {
  [profile("admin")]: {
    dashboard: {
      c: 0,
      r: 1,
      u: 0,
      d: 0,
    },
    // 'cadastro-paciente': {
    //     c: 1,
    //     r: 1,
    //     u: 1,
    //     d: 1
    // },
    pacientes: {
      c: 1,
      r: 1,
      u: 1,
      d: 1,
    },
    assistentes: {
      c: 1,
      r: 1,
      u: 1,
      d: 1,
    },
    procedimentos: {
      c: 1,
      r: 1,
      u: 1,
      d: 1,
    },
    "tipos-procedimentos": {
      c: 1,
      r: 1,
      u: 1,
      d: 1,
    },
    exames: {
      c: 1,
      r: 1,
      u: 1,
      d: 1,
    },
    medicamentos: {
      c: 1,
      r: 1,
      u: 1,
      d: 1,
    },
    anamnese: {
      c: 1,
      r: 1,
      u: 1,
      d: 1,
    },
    "Pre-agendamentos": {
      c: 1,
      r: 1,
      u: 1,
      d: 1,
    },
  },
  [profile("medico")]: {
    "Pre-agendamentos": {
      c: 1,
      r: 1,
      u: 1,
      d: 1,
    },
    dashboard: {
      c: 0,
      r: 1,
      u: 0,
      d: 0,
    },
    prontuarios: {
      c: 0,
      r: 1,
      u: 0,
      d: 0,
    },
    pacientes: {
      c: 1,
      r: 1,
      u: 1,
      d: 1,
    },
    assistentes: {
      c: 1,
      r: 1,
      u: 1,
      d: 1,
    },
    formularios: {
      c: 1,
      r: 1,
      u: 1,
      d: 1,
    },
    procedimentos: {
      c: 0,
      r: 1,
      u: 0,
      d: 0,
    },
    exames: {
      c: 1,
      r: 1,
      u: 1,
      d: 1,
    },
    medicamentos: {
      c: 1,
      r: 1,
      u: 1,
      d: 1,
    },
    horarios: {
      c: 1,
      r: 1,
      u: 1,
      d: 1,
    },
    agendamentos: {
      c: 0,
      r: 1,
      u: 0,
      d: 0,
    },
    "agenda-clientes": {
      c: 1,
      r: 1,
      u: 1,
      d: 1,
    },
    importacao: {
      c: 1,
      r: 1,
      u: 1,
      d: 1,
    },
    mapa: {
      c: 1,
      r: 1,
      u: 1,
      d: 1,
    },
  },
  [profile("assistente")]: {
    "Pre-agendamentos": {
      c: 1,
      r: 1,
      u: 1,
      d: 1,
    },
    dashboard: {
      c: 0,
      r: 1,
      u: 0,
      d: 0,
    },
    // 'cadastro-paciente': {
    //     c: 1,
    //     r: 1,
    //     u: 1,
    //     d: 1
    // },
    pacientes: {
      c: 1,
      r: 1,
      u: 1,
      d: 1,
    },
    prontuarios: {
      c: 0,
      r: 1,
      u: 0,
      d: 0,
    },
    assistentes: {
      c: 1,
      r: 1,
      u: 1,
      d: 1,
    },
    agendamentos: {
      c: 1,
      r: 1,
      u: 1,
      d: 1,
    },
    "agenda-clientes": {
      c: 1,
      r: 1,
      u: 1,
      d: 1,
    },
    horarios: {
      c: 1,
      r: 1,
      u: 1,
      d: 1,
    },
    procedimentos: {
      c: 0,
      r: 1,
      u: 0,
      d: 0,
    },
    "tipos-procedimentos": {
      c: 1,
      r: 1,
      u: 1,
      d: 1,
    },
    exames: {
      c: 1,
      r: 1,
      u: 1,
      d: 1,
    },
    medicamentos: {
      c: 1,
      r: 1,
      u: 1,
      d: 1,
    },
    formularios: {
      c: 1,
      r: 1,
      u: 1,
      d: 1,
    },
    importacao: {
      c: 1,
      r: 1,
      u: 1,
      d: 1,
    },
    mapa: {
      c: 1,
      r: 1,
      u: 1,
      d: 1,
    },
  },
  [profile("paciente")]: {
    dashboard: {
      c: 0,
      r: 1,
      u: 0,
      d: 0,
    },
    agendamentos: {
      c: 1,
      r: 1,
      u: 1,
      d: 1,
    },
    "meu-prontuario": {
      c: 1,
      r: 1,
      u: 1,
      d: 1,
    },
  },
  [profile("unauthenticated")]: {
    home: {
      c: 0,
      r: 1,
      u: 0,
      d: 0,
    },
    login: {
      c: 1,
      r: 0,
      u: 0,
      d: 0,
    },
    "esqueci-minha-senha": {
      c: 1,
      r: 0,
      u: 0,
      d: 0,
    },
  },
};

export default authorizations;
