<template>
  <div>
    <v-card min-width="400px" style="width: 100%">
      <v-card-title>
        <span class="headline">Marcar agendamento</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <div class="row">
            <div class="col-lg-12">
                <v-autocomplete
                    v-model="pacienteID"
                    :items="pacientes"
                    :loading="loading"
                    color="#3E682A"
                    hide-no-data
                    hide-details
                    :search-input.sync="search"
                    label="Buscar por nome"
                    item-text="nome"
                    item-value="id"
                >

                  <template v-slot:item="data">
                   
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="data.item.nome"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-html="data.item.cpf"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                  </template>
                </v-autocomplete>
            </div>
            <div class="col-lg-12">
                    <v-autocomplete
                        v-model="procedimento"
                        :items="procedimentos"
                        :loading="loading_procedimento"
                        hide-no-data
                        placeholder=""
                        background-color="white"
                        :search-input.sync="search_procedimentos"
                        label="Procedimento"
                        item-text="descricao"
                        return-object
                        color="black"
                        solo
                        >
                    </v-autocomplete>
                </div>
          </div>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <template>
          <v-row class="pl-2">
            <!-- MODAL CADASTRO PACIENTE -->
            <v-dialog
              v-model="dialogCadastroPaciente"
              fullscreen
              hide-overlay
              transition="dialog-bottom-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  style="background-color: #3e682a; color: #fff"
                  v-bind="attrs"
                  v-on="on"
                >
                  + Novo Paciente
                </v-btn>
              </template>
              <v-card>
                <v-toolbar style="background-color: #3e682a; color: #fff">
                  <v-btn icon dark @click="dialogCadastroPaciente = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <v-toolbar-title>Cadastrar novo paciente</v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>
                <v-list three-line subheader>
                  <cadastro-novo-paciente @close="dialogCadastroPaciente = false"/>
                </v-list>
              </v-card>
            </v-dialog>
          </v-row>
        </template>
        <v-spacer></v-spacer>
        <v-btn
          text
          @click="$emit('close')"
        >
          Fechar
        </v-btn>
        <v-btn
          style="background-color: #3e682a; color: #fff !important"
          text
          :disabled="!pacienteID"
          @click="agendar"
        >                                            
          <span v-if="!loadingAgendamento">
              Agendar
          </span>    
          <v-progress-circular
              v-else
              indeterminate
              color="white"
          ></v-progress-circular>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import CadastroNovoPaciente from "@/components/pacientes/cadastro_novo_paciente.vue";
import PacienteService from "@/services/paciente_service.js";
import ProcedimentoService from "@/services/procedimento_service.js";
import AgendaService from "@/services/agenda_service.js";
import Mixin from "@/mixins/vuex_mixin.js";

export default {
  mixins:[Mixin],
  components: { CadastroNovoPaciente },
  props: {
    detalhesAgenda: {
      type: Object,
      validator: obj => {
        return obj ? !!obj.idAgenda : false
      }
    }
  },
  beforeMount() {
    this.tipo_procedimento = this.detalhesAgenda.idTipoProcedimento
  },
  data: () => ({
    colorSnackbar: '',
    dialogCadastroPaciente: false,
    loading: false,
    loadingAgendamento: false,
    pacientes: [],
    pacienteID: null,
    procedimento:'',
    procedimentos:[],
    tipo_procedimento: '',
    search_procedimentos:null,
    loading_procedimento:false,
    search: null,
    defaultFotos: {
        F: 'https://thumbs.dreamstime.com/z/female-default-avatar-profile-icon-woman-face-silhouette-person-placeholder-vector-illustration-female-default-avatar-profile-icon-189495134.jpg',
        M: 'https://us.123rf.com/450wm/thesomeday123/thesomeday1231709/thesomeday123170900021/85622928-default-avatar-profile-icon-grey-photo-placeholder-illustrations-vectors.jpg?ver=6'
    }
  }),
  methods: {
    agendar() {
      if (this.pacienteID) {
        const idAgenda = this.detalhesAgenda.idAgenda
        const paciente = this.pacientes.find(paciente => {
          return paciente.id === this.pacienteID
        })
        const request = {
          'id_paciente': this.pacienteID,
          'id_procedimento': this.procedimento.id,
          'id_cliente': paciente.id_cliente,
          'id_agenda': idAgenda
        }
        const agendaService = new AgendaService()
        this.loadingAgendamento = true
        agendaService.agendar({
          onSucess: status => body => {
            request.id_agendamento = body.data.id_agendamento
            if (status === 200) {
              this.$emit('submit', {
                status,
                message: body.message,
                request,
                procedimento: this.procedimento.descricao
              })
              this.pacienteID = null
              this.procedimento = ""
            } else {
              this.$_ACTIONS_showSnackbarMessage({
                message: this.$global.messages.internalServerError,
                color: 'error'
              })
            }
          },
          onError: error => {
            this.$emit('submit', { error })
          },
          onEnd: () => {
            this.loadingAgendamento = false
          }
        }, request )
      }
    },
  },
  watch: {
    search_procedimentos(descricao) {
        const procedimentoService = new ProcedimentoService();
        procedimentoService.searchByDescricao(this)(descricao)
    },
    search(nome) {
      if (!nome) {
        this.pacientes = [];
      }
      if (!nome || !(nome = nome.trim()) || nome.length < 3 || this.loading) {
        return;
      }
      this.loading = true;
      this.pacientes = [];
      const pacienteService = new PacienteService();
      pacienteService.getPacienteByName(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.pacientes = body.data.pacientes;
            } else {
              this.pacientes = [];
            }
          },
          onError: (error) => {
            console.error(error);
            this.pacientes = [];
          },
          onEnd: () => {
            this.loading = false;
          },
        },
        nome
      );
    },
  },
};
</script>