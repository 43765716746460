import Home from '@/views/home.vue'
import Login from '@/views/login.vue'
import EsqueceuSenha from '@/views/esqueceu-senha.vue'
import CadastroPaciente from '@/views/cadastro_paciente.vue'
import Pacientes from '@/views/pacientes.vue'
import Assistentes from '@/views/assistentes.vue'
import Agendamento from '@/views/agendamentos.vue'
import AgendaClientes from '@/views/agenda_clientes.vue'
import Horarios from '@/views/horarios.vue'
import Procedimentos from '@/views/procedimentos'
import PreAgendamentos from '@/views/pre_agendamentos'
// import TipoProcedimento from '@/views/tipo_procedimentos'
import Dashboard from '@/views/dashboard.vue'
import DadosCadastrais from '@/views/dados_cadastrais.vue'
import TrocarSenha from '@/views/trocar_senha.vue'
import Prontuarios from '@/views/prontuarios.vue'
import MeuProntuario from '@/views/prontuario_paciente.vue'
import Formulario from '@/views/formularios.vue'
import Exames from '@/views/exames.vue'
import Medicamentos from '@/views/medicamentos.vue'
import Importacao from '@/views/importacao.vue'
import MapaCirurgico from '@/views/rel_mapa_cirurgico.vue'
// AS

const routes = [
    {
      path: '/home',
      name: 'home',
      component: Home,
      icon: 'dripicons-calendar',
      label: '',
    },
    {
      path: '/principal',
      name: 'dashboard',
      component: Dashboard,
      icon: 'dripicons-checklist',
      label: 'Início'
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      icon: 'dripicons-calendar',
      label: ''
    },
    {
      path: '/esqueceu-senha',
      name: 'esqueci-minha-senha',
      component: EsqueceuSenha,
      icon: '',
      label: ''
    },
    {
      path: '/novo-cadastro',
      name: 'cadastro-paciente',
      component: CadastroPaciente,
      icon: 'dripicons-user',
      label: 'Cadastro de pacientes'
    },
    {
      path: '/pacientes',
      name: 'pacientes',
      component: Pacientes,
      icon: 'dripicons-user',
      label: 'Pacientes'
    },
    {
      path: '/assistentes',
      name: 'assistentes',
      component: Assistentes,
      icon: 'dripicons-user-group',
      label: 'Assistentes'
    },
    {
        path:'/prontuarios',
        name:'prontuarios',
        component: Prontuarios,
        icon: 'fas fa-notes-medical',
        label: 'Prontuários',
        props: { search: false }
    },
    {
        path: '/agendamentos',
        name: 'agendamentos',
        component: Agendamento,
        icon: 'dripicons-calendar',
        label: 'Agendamentos'
    },
    {
      path: '/agenda_clientes',
      name: 'agenda-clientes',
      component: AgendaClientes,
      icon: 'dripicons-calendar',
      label: 'Agenda clientes'
  },
    {
        path:'/horarios',
        name:'horarios',
        component: Horarios,
        icon: 'dripicons-stopwatch',
        label: 'Horários'
    },
    {
        path:'/formularios',
        name:'formularios',
        component: Formulario,
        icon: 'fas fa-file-medical-alt',
        label: 'Formulários'
    },
    {
      path: '/procedimentos',
      name: 'procedimentos',
      component: Procedimentos,
      icon: 'dripicons-medical',
      label: 'Procedimentos'
    },
    {
        path: '/pre-agendamentos',
        name: 'Pre-agendamentos',
        component: PreAgendamentos,
        icon: 'dripicons-stopwatch',
        label: 'Pré-agendamentos'
    },
    // {
    //   path: '/tipos-procedimentos',
    //   name: 'tipos-procedimentos',
    //   component: TipoProcedimento,
    //   icon: 'dripicons-medical',
    //   label: 'Tipos\nde\nProcedimentos'
    // },
    {
      path: '/exames',
      name: 'exames',
      component: Exames,
      icon: 'fas fa-vial',
      label: 'Exames'
    },
    {
        path: '/medicamentos',
        name: 'medicamentos',
        component: Medicamentos,
        icon: 'fas fa-medkit',
        label: 'Medicamentos'
    },
    {
      path:'/dadoscadastrais',
      name:'dadoscadastrais',
      component: DadosCadastrais,
      icon: '',
      label: 'Dados Cadastrais'
    },
    {
      path:'/trocarsenha',
      name:'trocarsenha',
      component: TrocarSenha,
      icon: '',
      label: 'Trocar senha'
    },
    {
      path:'/meu-prontuario',
      name:'meu-prontuario',
      component: MeuProntuario,
      icon: 'fas fa-file-medical-alt',
      label: 'Meu prontuário'
    },
    {
      path:'/importacao',
      name:'importacao',
      component: Importacao,
      icon: 'fas fa-file',
      label: 'Importaçao'
    },
    {
      path:'/mapa',
      name:'mapa',
      component: MapaCirurgico,
      icon: 'fas fa-file',
      label: 'Mapa Cirúrgico'
    },

    
]

export default routes
