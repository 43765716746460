<template>
  <div class="d-flex flex-column w-100 resp">
    <p class="resp text-center" v-if="tipoTermo == 'termo_um'">
      <b>TERMO DE RESPONSABILIDADE EXPRESSO</b>
    </p>
    <p class="resp indent" v-if="tipoTermo == 'termo_um'">
      O (a) paciente ou responsável pelo paciente, Sr. (a) <b>{{ nome }}</b
      >, sob o CPF:{{ cpf }}, aqui declarado, dá plena autorização ao seu médico
      assistente, Dr. LUIZ NESTOR VASCONCELOS RODRIGUES C.R.M. Nº. 6110, para
      fazer as investigações necessárias ao diagnóstico e executar tratamentos,
      operações, anestesias, transfusões de sangue, ou outras condutas médicas
      que venham de encontro às necessidades clínico-cirúrgicas do caso, bem
      como dá ciência de informações sobre sua capacidade técnico profissional e
      moral, comprometendo-se a respeitar as instruções que lhe forem
      fornecidas, inclusive quanto aos imprevistos oriundos da eventualidade
      como infecções, necroses de retalhos ou regiões operadas, assimetrias,
      deiscências, seromas, rejeições, no caso de próteses, embolia pulmonar,
      trombose venosa profunda, entre outras complicações inerentes aos
      procedimentos. Outrossim, sou consciente dos riscos informados, incluindo
      o de morte, ou da não observância das orientações médicas recomendadas.<br />
      CIRURGIA (S) PROPOSTAS (S): (indicação):<br />
      {{ procedimento }}
    </p>
    <div class="d-flex flex-column" v-if="tipoTermo == 'termo_um'">
      <p class="ml-auto">
        Fortaleza, {{ gerarData().dia }}<span class="space">_</span>de<span
          class="space"
          >_</span
        >{{ gerarData().mes }}<span class="space">_</span>{{ gerarData().ano }}.
      </p>
      <p class="d-flex flex-column mr-auto">
        <span> _________________________________________</span>
        <span>(Assinatura paciente ou responsável legal) </span>
      </p>
      <p class="d-flex flex-column mr-auto resp">
        NOME POR EXTENSO: {{ nome }}<br />
        RG. Ou CPF.: {{ cpf }}
      </p>
    </div>
    <p class="resp text-center" v-if="tipoTermo == 'termo_dois'">
      <b>TERMO DE CONSENTIMENTO INFORMADO EXPRESSO</b>
    </p>
    <p class="resp indent" v-if="tipoTermo == 'termo_dois'">
      Declaro que recebi o "TERMO DE INFORMAÇÕES PRÉ-CIRÚRGICAS" como também
      todos os esclarecimentos necessários sobre as informações específicas
      contidas neste termo sobre a cirurgia a ser realizada, onde foram
      explicados os detalhes sobre as cicatrizes resultantes, outros estados
      orgânicos, eventuais intercorrências, evoluções favoráveis e desfavoráveis
      pós-cirúrgicas, infecções, deiscências, necroses, assimetrias, perda da
      sensibilidade, rejeições, no caso de próteses, embolia pulmonar, trombose
      venosa profunda, inclusive risco de morte, entre outras complicações do
      procedimento bem como os cuidados para minimizar esses problemas. Assim,
      livremente e de espontânea vontade, assino (amos) o presente TERMO DE
      CONSENTIMENTO PÓS-INFORMADO para que o Dr. LUIZ NESTOR VASCONCELOS
      RODRIGUES, C.R.M. Nº. 6110 possa realizar o procedimento clínico-cirúrgico
      de e para que produza os devidos efeitos legais.
    </p>
    <div class="d-flex flex-column" v-if="tipoTermo == 'termo_dois'">
      <p class="ml-auto">
        Fortaleza, {{ gerarData().dia }}<span class="space">_</span>de<span
          class="space"
          >_</span
        >{{ gerarData().mes }}<span class="space">_</span>{{ gerarData().ano }}.
      </p>
      <p class="d-flex flex-column mr-auto">
        <span> _________________________________________</span>
        <span>Paciente</span>
      </p>
      <p class="d-flex flex-column mr-auto">
        <span> _________________________________________</span>
        <span>(Responsável legal, qdo.menor)</span>
      </p>
    </div>
    <p class="resp text-center" v-if="tipoTermo == 'termo_tres'">
      <b>TERMO DE CONSENTIMENTO LIVRE E ESCLARECIDO</b>
    </p>
    <p class="resp indent" v-if="tipoTermo == 'termo_tres'">
      Eu <b>{{ nome }}</b
      >, Declaro para devidos fins que estou ciente de que realizarei
      procedimento cirúrgico em vigência da pandemia de infecção pelo COVID-19,
      com potencial risco de infecção, uma vez que a infecção é comunitária.
      Também estou ciente que o procedimento que serei submetida
      <b>{{ procedimento }}</b
      >, em riscos anestésico cirúrgicas, tendo potencial necessidade de
      internação em unidade de terapia intensiva (U.T.I.), que, no momento estão
      sendo utilizadas para pacientes portadores de COVID-19 (CORONA VÍRUS).
      Portando, estou ciente de que, em caso de internação em U.T.I., estarei em
      um ambiente sem isolamento e em contato próximo com pacientes com COVID
      -19. Tendo alto risco de contaminação por este vírus.
    </p>
    <div class="d-flex flex-column" v-if="tipoTermo == 'termo_tres'">
      <p class="ml-auto">
        Fortaleza, {{ gerarData().dia }}<span class="space">_</span>de<span
          class="space"
          >_</span
        >{{ gerarData().mes }}<span class="space">_</span>{{ gerarData().ano }}.
      </p>
      <div class="d-flex flex-row justify-space-between">
        <p class="d-flex flex-column mr-auto">
          <span> _____________________________________</span>
          <span class="ml-1">Assinatura Paciente/ Responsável</span><br />
          <span class="ml-1"> CPF: {{ cpf }}</span>
        </p>
        <p class="d-flex flex-column mr-auto">
          <span> _____________________________________</span>
          <span class="ml-3">Testemunha</span><br />
          CPF:______________________________
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "documento_termos",
  props: {
    tipoTermo: { required: true },
    cpf: { required: true },
    procedimento: { required: true },
    nome: { required: true },
  },
  methods: {
    gerarData() {
      const meses = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novovembro",
        "Dezembro",
      ];
      let data = new Date();
      return {
        dia: data.getDate(),
        mes: meses[data.getMonth()],
        ano: data.getFullYear(),
      };
    },
  },
};
</script>

<style scoped></style>
