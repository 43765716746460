import HTTPService from '@/services/http_service.js'

export default class PreAgendamentoService extends HTTPService {
    resource = 'preagendamento'


    static build() {
        return new PreAgendamentoService();
    }
    // getAgendaMedico (responseFunctions, idMedico, paginationComponent, filtro={}) {
    //     const pagination = paginationComponent ? {
    //         page: paginationComponent.page,
    //         per_page:paginationComponent.itemsPerPage
    //     } : {}
    //     this.request(
    //         responseFunctions,
    //         'GET',
    //         this.resource + '/medico/' + idMedico,
    //         null,
    //         { ...pagination, ...filtro }
    //     )
    // }
    getPaginatedList(responseFunctions, {page,per_page,sort, search}) {
        this.request(responseFunctions,'GET','preagendamento', null, {page,per_page,sort,cliente_nome:search});
    }


    async register({
         clienteNome,
         clienteTelefone,
         agendaId,
         procedimentoId
     }) {
        try {
            const {status} = await this.post('preagendamento', {
                id_agenda: agendaId,
                id_procedimento: procedimentoId,
                cliente_nome: clienteNome,
                cliente_telefone: clienteTelefone
            })
            return status === 200;
        } catch (e) {
            return false;
        }
    }

    // agendar(responseFunctions, request) {
    //     const uri = 'agendamento'
    //     this.request(responseFunctions, 'POST', uri, request)
    // }
    //
    // agendar_paciente(responseFunctions, request) {
    //     const uri = 'agendamento/cliente'
    //     this.request(responseFunctions, 'POST', uri, request)
    // }
    //
    // criarHorario (responseFunctions, req) {
    //     this.request(responseFunctions, 'POST', this.resource, req)
    // }
    //
    // criarAgenda (responseFunctions, req) {
    //     this.request(responseFunctions, 'POST', this.resource, req)
    // }
    //
    // criarAgendaMultiplo (responseFunctions, params) {
    //     this.request(responseFunctions, 'POST', this.resource + '/multiplos', params)
    // }
    //
    // atualizarTipoProcedimentoDeAgenda (responseFunctions, idTipoProcedimento, agendaID) {
    //     const url = `${this.resource}/${agendaID}`
    //     this.request(responseFunctions, 'POST', url, {
    //         id_tipo_procedimento: idTipoProcedimento,
    //         alterado_por: 1
    //     })
    // }
    //
    // excluirAgenda (responseFunctions, agendaID) {
    //     // ESTÁ SENDO UTILIZADO POST PARA EXCLUSÃO PORQUE A UMBLER INFORMOU ERRO DE CORS AO USAR O MÉTODO DELETE. CORRIGIR.
    //     const url = `${this.resource}/${agendaID}/deletar`
    //     this.request(responseFunctions, 'POST', url)
    // }
}
